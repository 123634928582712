import * as React from 'react';
import type { SVGProps } from 'react';
const BICIconSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    {...props}>
    <path
      d="M11.6668 9.16797H6.66683M8.3335 12.5013H6.66683M13.3335 5.83464H6.66683M16.6668 5.66797V14.3346C16.6668 15.7348 16.6668 16.4348 16.3943 16.9696C16.1547 17.44 15.7722 17.8225 15.3018 18.0622C14.767 18.3346 14.067 18.3346 12.6668 18.3346H7.3335C5.93336 18.3346 5.2333 18.3346 4.69852 18.0622C4.22811 17.8225 3.84566 17.44 3.60598 16.9696C3.3335 16.4348 3.3335 15.7348 3.3335 14.3346V5.66797C3.3335 4.26784 3.3335 3.56777 3.60598 3.03299C3.84566 2.56259 4.22811 2.18014 4.69852 1.94045C5.2333 1.66797 5.93336 1.66797 7.3335 1.66797H12.6668C14.067 1.66797 14.767 1.66797 15.3018 1.94045C15.7722 2.18014 16.1547 2.56259 16.3943 3.03299C16.6668 3.56777 16.6668 4.26784 16.6668 5.66797Z"
      stroke="#D0D5DD"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const BICIcon = React.memo(BICIconSvg);
export default BICIcon;

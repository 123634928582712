import { XIcon } from 'assets';
import { Table, TablePagination } from 'components';
import { useState, type FC } from 'react';
import { useGetCryptoPurchases } from 'services';
import { getEncryptedOnrampTokenFromStorage } from 'services/storage';
import { cn } from 'utils';
import OrderDetailsDrawer from './OrderDetails';

const tableHead = [
  {
    id: 3,
    label: 'Business',
  },
  {
    id: 4,
    label: 'Fiat amount',
  },
  {
    id: 5,
    label: 'Crypto amount',
  },
  // {
  //   id: 6,
  //   label: 'Status',
  // },
];
const pageSize = 7;

type CryptoPurchase = {
  setShowOrdersHistory: React.Dispatch<React.SetStateAction<boolean>>;
  showOrdersHistory: boolean;
};

const OrderItems: FC<CryptoPurchase> = ({
  setShowOrdersHistory,
  showOrdersHistory,
}) => {
  const [orderId, setOrderId] = useState<string>();
  const [currentPage, setCurrentPage] = useState(1);

  const sessionToken = getEncryptedOnrampTokenFromStorage();

  const { data: cryptoPurchases, isLoading } = useGetCryptoPurchases({
    enabled: !!sessionToken,
    page: currentPage,
    pageSize: pageSize,
  });

  return (
    <div
      className={cn(
        'absolute top-0 left-0 h-full w-full bg-white dark:bg-bg-dark rounded-3xl ',
        {
          hidden: !showOrdersHistory,
        },
      )}
      onClick={(e) => e.stopPropagation()}>
      <div className="mt-10 px-2 md:px-5">
        <div className="mt-5 text-2xl text-center">My orders</div>
        {!cryptoPurchases?.data.length ? (
          <div className="mt-5 text-center">No orders available</div>
        ) : (
          <div>
            <Table
              tableHead={tableHead}
              isLoading={isLoading}
              noDataText="No orders available"
              containerClassName="mt-4"
              tableBody={
                cryptoPurchases?.data.length
                  ? cryptoPurchases?.data?.map((item, index) => (
                      <tr
                        onClick={() => setOrderId(item.id)}
                        key={item.id}
                        className={`${index % 2 === 0 ? 'bg-[#F9FAFB]  dark:bg-slate-800' : 'bg-gray-25 dark:bg-[#233045]'} border-t dark:border-bg-dark h-[72px] cursor-pointer hover:bg-white hover:dark:bg-bg-dark`}>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {item.business}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {item.fiat_amount} {item.fiat_currency}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {parseFloat(item.crypto_amount ?? '0').toFixed(8)}{' '}
                          {item.crypto_currency}
                        </td>
                        {/* <td className="px-6 py-4 whitespace-nowrap">
                      <Tag variant="default" text={item.status} />
                    </td> */}
                      </tr>
                    ))
                  : undefined
              }
            />

            {!isLoading && (
              <TablePagination
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalPages={cryptoPurchases?.meta?.last_page || 1}
              />
            )}
          </div>
        )}
      </div>

      <OrderDetailsDrawer orderId={orderId} setOrderId={setOrderId} />

      <div className="absolute px-5 py-2 top-5 left-2 flex justify-between w-full items-center">
        <div onClick={() => setShowOrdersHistory(false)}>
          <XIcon className="stroke-black dark:stroke-white  cursor-pointer" />
        </div>
      </div>
    </div>
  );
};

export default OrderItems;

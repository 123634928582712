import React from 'react';
import { Skeleton } from '../Skeleton';

const TableLoading = () => {
  return (
    <div>
      <Skeleton className="w-full h-16 rounded-none border-t dark:border-bg-dark mb-0 bg-[#F9FAFB]  dark:bg-slate-800" />
      <Skeleton className="w-full h-16 rounded-none border-t dark:border-bg-dark mb-0 bg-gray-25 dark:bg-[#233045]" />
    </div>
  );
};

export default TableLoading;

import * as React from 'react';
import type { SVGProps } from 'react';
const ChevronLeftIconSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={8}
    height={14}
    viewBox="0 0 8 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M7.125 13.25L0.875 7L7.125 0.75"
      stroke="fill"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const ChevronLeftIcon = React.memo(ChevronLeftIconSvg);

export default ChevronLeftIcon;

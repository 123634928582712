import type { FC } from 'react';
import React from 'react';
import useDarkSide from 'hooks/useDarkSide';
import { InvoiceProcessingHeader } from '../Header';

interface InvoiceProcessingLayoutProps {
  children: React.ReactNode;
}

const InvoiceProcessingLayout: FC<InvoiceProcessingLayoutProps> = ({
  children,
}) => {
  useDarkSide();
  return (
    <div
      lang="en"
      className="dark:bg-bg-secondaryDark bg-bg-secondaryLight dark:text-white pt-5 md:px-6 md:pt-8  lg:px-14 lg:pt-16  flex flex-col w-full overflow-hidden ease-linear transition-all duration-300">
      <InvoiceProcessingHeader />
      <main className="w-full p-4 md:p-8 px-5 lg:py-14 rounded-2xl mb-10 min-h-[100vh]">
        {children}
      </main>
    </div>
  );
};

export default InvoiceProcessingLayout;

import * as React from 'react';
import type { SVGProps } from 'react';
const DownloadPaymentIconSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={14}
    height={19}
    viewBox="0 0 14 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M13.25 11.375V9.1875C13.25 8.44158 12.9537 7.72621 12.4262 7.19876C11.8988 6.67132 11.1834 6.375 10.4375 6.375H9.1875C8.93886 6.375 8.7004 6.27623 8.52459 6.10041C8.34877 5.9246 8.25 5.68614 8.25 5.4375V4.1875C8.25 3.44158 7.95368 2.72621 7.42624 2.19876C6.89879 1.67132 6.18342 1.375 5.4375 1.375H3.875M4.5 11.375L7 13.875M7 13.875L9.5 11.375M7 13.875V8.875M5.75 1.375H1.6875C1.17 1.375 0.75 1.795 0.75 2.3125V16.6875C0.75 17.205 1.17 17.625 1.6875 17.625H12.3125C12.83 17.625 13.25 17.205 13.25 16.6875V8.875C13.25 6.88588 12.4598 4.97822 11.0533 3.5717C9.64678 2.16518 7.73912 1.375 5.75 1.375Z"
      stroke="#4B6AF1"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const DownloadPaymentIcon = React.memo(DownloadPaymentIconSvg);
export default DownloadPaymentIcon;

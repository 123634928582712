import { CheckMark, DraftIcon, ReverseLeft, XTagIcon } from 'assets';
import { PageHeader, Table, TablePagination, Tag } from 'components';
import { useState } from 'react';
import { useGetPaginatedOrders } from 'services';
import type { Order } from 'services/types';
import OrderDetailsModal from './components/OrderDetailsModal';

const tableHead = [
  // {
  //   id: 1,
  //   label: 'Id',
  // },

  {
    id: 3,
    label: 'Customer name',
  },
  {
    id: 4,
    label: 'Fiat amount',
  },
  {
    id: 5,
    label: 'Crypto amount',
  },
  {
    id: 6,
    label: 'Status',
  },
];

const renderStatusTag: { [key: string]: JSX.Element } = {
  completed: <Tag variant="success" text="Paid" iconBefore={<CheckMark />} />,
  refunded: (
    <Tag variant="default" text="Refunded" iconBefore={<ReverseLeft />} />
  ),
  draft: <Tag variant="default" text="Draft" iconBefore={<DraftIcon />} />,
  pending: (
    <Tag variant="warning" text="Pending" iconBefore={<ReverseLeft />} />
  ),
  cancelled: <Tag variant="error" text="Cancelled" iconBefore={<XTagIcon />} />,
};

const pageSize = 10;

const Orders = () => {
  const [showModal, setShowModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [initialValues, setInitialValues] = useState<Order>();
  const { data: ordersData, isLoading } = useGetPaginatedOrders({
    page: currentPage,
    pageSize: pageSize,
  });

  const handleRowClick = (item: Order) => {
    setInitialValues(item);
    setShowModal(true);
  };

  return (
    <div className="w-full">
      <PageHeader
        title="Orders"
        // description=""
      />

      <Table
        tableHead={tableHead}
        isLoading={isLoading}
        noDataText="No orders available"
        tableBody={
          ordersData?.data.length
            ? ordersData?.data?.map((item, index) => (
                <tr
                  onClick={() => handleRowClick(item)}
                  key={item.id}
                  // onClick={() => handleRowClick(item)}
                  className={`${index % 2 === 0 ? 'bg-[#F9FAFB]  dark:bg-slate-800' : 'bg-gray-25 dark:bg-[#233045]'} border-t dark:border-bg-dark h-[72px] cursor-pointer hover:bg-white hover:dark:bg-bg-dark`}>
                  {/* <td className="px-6 py-4 ">
                    {(currentPage - 1) * pageSize + 1 + index}
                  </td> */}
                  <td className="px-6 py-4 whitespace-nowrap">
                    {item.customer}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {item.fiat_amount} {item.fiat_currency}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {item.crypto_amount} {item.crypto_currency}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {renderStatusTag[item?.status]}
                  </td>
                </tr>
              ))
            : undefined
        }
      />

      {!isLoading && (
        <TablePagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={ordersData?.meta?.last_page || 1}
        />
      )}

      {showModal && (
        <OrderDetailsModal
          setShowModal={setShowModal}
          showModal={showModal}
          initialValues={initialValues}
        />
      )}
    </div>
  );
};

export default Orders;

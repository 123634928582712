import type {
  AuthorizeUser,
  AuthorizeUserResponse,
  BusinessBrandDetailsResponse,
  ChangeBrandDetailsData,
  ChangeBrandDetailsResponse,
  CreateInvoiceData,
  CreateInvoiceResponse,
  CreateWebhookConnectionResponse,
  CustomerDetailsResponse,
  GetCustomersListResponse,
  GetDeveloperConfigurationsResponse,
  GetProductsResponse,
  InvoiceData,
  PaginationParams,
  InvoicesListResponse,
  LoginResponse,
  PaginatedInvoicesResponse,
  StoreCustomerData,
  SuccessResponse,
  SwitchEnvironmentResponse,
  WebhookConnectionData,
  PaginatedCustomersResponse,
  VerifyOnrampCustomerData,
  AuthorizeOnrampCustomerData,
  VerifyOnrampCustomerResponse,
  AuthorizeOnrampCustomerResponse,
  OnrampUserDetailsData,
  UpdateOnrampUserDetailsData,
  AddOnrampNewWalletAddressData,
  AddOnrampWalletSuccess,
  OnrampCustomerWallets,
  ConvertAssetParams,
  FindAssetParams,
  InitiatePayByBankData,
  PaymentMethodsParams,
  YapilyCallbackParams,
  GetInitialOnrampData,
  GetOnrampInitialDataResponse,
  GetKycTokenResponse,
  OrderList,
  OrderDetailsResponse,
  PaginatedOrderList,
  CreateDraftOrderData,
  InitiateOnrampPayment,
  OnrampPayByCard,
  PaymentMethodsResponse,
  OnrampPaymentInstitutionsResponse,
  ConvertAssetResponse,
  DraftOrderResponse,
  FinishManualTransferData,
  PayByCardResponse,
  PayByBnkTransferResponse,
  InitiateOnrampPaymentResponse,
  ResendOtpCode,
  AllCountriesResponse,
  Country,
  YapilyStatusQueryData,
  YapilyStatusResponse,
  BankCallbackParams,
  BankCallbackResponse,
  SimulateOnrampSandboxPaymentData,
  SimulateOnrampSandboxPaymentResponse,
  SumsubApplicantStatus,
  CryptoPurchaseResponse,
  CryptoPurchaseDetailsResponse,
  GetCryptoPurchasesProps,
  GenerateOnrampLinkData,
  GetAssetsListResponse,
  GenerateOnrampLinkResponse,
  UpdateOnrampProgressData,
  GetOnrampProgress,
  GetOnrampProgressData,
  UpdateOnrampDataResponse,
  CoinNetworkResponse,
} from '../types';
import { API_ENDPOINTS } from './api-endpoints';
import { HttpClient } from './http-client';

class Client {
  auth = {
    loginWithNiza: () =>
      HttpClient.get<LoginResponse>(API_ENDPOINTS.LOGIN_WITH_NIZA),

    authorizeUser: (data: AuthorizeUser) =>
      HttpClient.post<AuthorizeUserResponse>(
        API_ENDPOINTS.AUTHORIZE_USER,
        data,
      ),

    logout: () => HttpClient.post<SuccessResponse>(API_ENDPOINTS.LOGOUT, {}),
  };

  country = {
    getAllCountries: () =>
      HttpClient.get<AllCountriesResponse>(API_ENDPOINTS.GET_ALL_COUNTRIES),
    getCountry: (countryCode: string) =>
      HttpClient.get<Country>(API_ENDPOINTS.GET_COUNTRY(countryCode)),
  };

  customer = {
    getCustomersList: () =>
      HttpClient.get<GetCustomersListResponse>(
        API_ENDPOINTS.GET_CUSTOMERS_LIST,
      ),

    getPaginatedCustomers: (data: PaginationParams) =>
      HttpClient.get<PaginatedCustomersResponse>(
        API_ENDPOINTS.GET_PAGINATED_CUSTOMERS(data),
      ),

    storeNewCustomer: (data: StoreCustomerData) =>
      HttpClient.post<SuccessResponse>(API_ENDPOINTS.STORE_NEW_CUSTOMER, data),

    getCustomerDetails: (id: string) =>
      HttpClient.get<CustomerDetailsResponse>(
        API_ENDPOINTS.GET_CUSTOMER_DETAILS(id),
      ),

    changeCustomerDetails: (id: string, data: StoreCustomerData) =>
      HttpClient.patch<SuccessResponse>(
        API_ENDPOINTS.CHANGE_CUSTOMER_DETAILS(id),
        data,
      ),
  };

  developer = {
    getDevelopersConfigurations: () =>
      HttpClient.get<GetDeveloperConfigurationsResponse>(
        API_ENDPOINTS.GET_DEVELOPER_CONFIGURATIONS,
      ),

    createWebhookConnection: (data: WebhookConnectionData) =>
      HttpClient.post<CreateWebhookConnectionResponse>(
        API_ENDPOINTS.CREATE_WEBHOOK_CONNECTION,
        data,
      ),
  };

  invoice = {
    getInvoicesList: () =>
      HttpClient.get<InvoicesListResponse>(API_ENDPOINTS.GET_INVOICES_LIST),

    getPaginatedInvoices: (data: PaginationParams) =>
      HttpClient.get<PaginatedInvoicesResponse>(
        API_ENDPOINTS.GET_PAGINATED_INVOICES(data),
      ),

    createNewInvoice: (data: CreateInvoiceData) =>
      HttpClient.post<CreateInvoiceResponse>(
        API_ENDPOINTS.CREATE_NEW_INVOICE,
        data,
      ),

    getInvoiceDetails: (id: string) =>
      HttpClient.get<InvoiceData>(API_ENDPOINTS.GET_INVOICE_DETAILS(id)),

    // updateInvoice: (id: string, data: any) =>
    //   HttpClient.patch(API_ENDPOINTS.UPDATE_INVOICE(id), data),
  };

  product = {
    getProductsList: () =>
      HttpClient.get<GetProductsResponse>(API_ENDPOINTS.GET_PRODUCTS_LIST),
    generateOnrampLink: (data: GenerateOnrampLinkData) =>
      HttpClient.post<GenerateOnrampLinkResponse>(
        API_ENDPOINTS.GENERATE_ONRAMP_LINK,
        data,
      ),
  };

  user = {
    getBusinessBrandDetails: () =>
      HttpClient.get<BusinessBrandDetailsResponse>(
        API_ENDPOINTS.GET_BUSINESS_BRAND_DETAILS,
      ),

    changeBrandDetails: (data: ChangeBrandDetailsData) =>
      HttpClient.post<ChangeBrandDetailsResponse>(
        API_ENDPOINTS.CHANGE_BRAND_DETAILS,
        data,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      ),

    removeBrandLogo: () =>
      HttpClient.delete<SuccessResponse>(API_ENDPOINTS.REMOVE_BRAND_LOGO),

    switchUserEnvironment: (data: unknown) =>
      HttpClient.post<SwitchEnvironmentResponse>(
        API_ENDPOINTS.SWITCH_USER_ENVIRONMENT,
        data,
      ),
  };

  onramp = {
    getInitialOnrampData: (data: GetInitialOnrampData) =>
      HttpClient.post<GetOnrampInitialDataResponse>(
        API_ENDPOINTS.GET_INITIAL_ONRAMP_DATA,
        data,
      ),

    verifyOnrampCustomer: (data: VerifyOnrampCustomerData) =>
      HttpClient.post<VerifyOnrampCustomerResponse>(
        API_ENDPOINTS.VERIFY_ONRAMP_CUSTOMER,
        data,
      ),

    verifyOnrampCustomerWithNiza: (data: VerifyOnrampCustomerData) =>
      HttpClient.post<VerifyOnrampCustomerResponse>(
        API_ENDPOINTS.VERIFY_ONRAMP_CUSTOMER_WITH_NIZA,
        data,
      ),

    resendOtpCode: (data: ResendOtpCode) =>
      HttpClient.post<SuccessResponse>(API_ENDPOINTS.RESEND_OTP_CODE, data),

    authorizeOnrampCustomer: (data: AuthorizeOnrampCustomerData) =>
      HttpClient.post<AuthorizeOnrampCustomerResponse>(
        API_ENDPOINTS.AUTHORIZE_ONRAMP_CUSTOMER,
        data,
      ),

    logoutOnrampCustomer: () =>
      HttpClient.post<SuccessResponse>(API_ENDPOINTS.ONRAMP_LOGOUT, {}),

    getOnrampCustomerDetails: () =>
      HttpClient.get<OnrampUserDetailsData>(
        API_ENDPOINTS.GET_ONRAMP_CUSTOMER_DETAILS,
      ),

    updateOnrampCustomerDetails: (data: UpdateOnrampUserDetailsData) =>
      HttpClient.patch<SuccessResponse>(
        API_ENDPOINTS.UPDATE_ONRAMP_CUSTOMER_DETAILS,
        data,
      ),

    getOnrampCustomerWalletAddresses: () =>
      HttpClient.get<OnrampCustomerWallets>(
        API_ENDPOINTS.GET_ONRAMP_CUSTOMER_WALLET_ADDRESS,
      ),

    addOnrampNewWalletAddress: (data: AddOnrampNewWalletAddressData) =>
      HttpClient.post<AddOnrampWalletSuccess>(
        API_ENDPOINTS.ADD_ONRAMP_NEW_WALLET_ADDRESS,
        data,
      ),

    createDraftOrder: (data: CreateDraftOrderData) =>
      HttpClient.post<DraftOrderResponse>(
        API_ENDPOINTS.CREATE_DRAFT_ORDER,
        data,
      ),

    getCryptoPurchases: (data: GetCryptoPurchasesProps) =>
      HttpClient.get<CryptoPurchaseResponse>(
        API_ENDPOINTS.GET_CRYPTO_PURCHASES({
          page: data.page,
          pageSize: data.pageSize,
        }),
      ),
    getCryptoPurchaseDetails: (orderId: string | undefined) =>
      HttpClient.get<CryptoPurchaseDetailsResponse>(
        API_ENDPOINTS.GET_CRYPTO_PURCHASE_DETAILS(orderId),
      ),

    //sumsub//
    createSumsubApplicant: () =>
      HttpClient.post<SuccessResponse>(
        API_ENDPOINTS.CREATE_SUMSUB_APPLICANT,
        {},
      ),

    getSumsubApplicantAccessToken: () =>
      HttpClient.post<GetKycTokenResponse>(
        API_ENDPOINTS.GET_SUMSUB_APPLICANT_ACCESS_TOKEN,
        {},
      ),

    getSumsubApplicantStatus: () =>
      HttpClient.get<SumsubApplicantStatus>(
        API_ENDPOINTS.GET_SUMSUB_APPLICANT_STATUS,
      ),

    //onramp progress//
    updateOnrampProgress: (data: UpdateOnrampProgressData) =>
      HttpClient.post<UpdateOnrampDataResponse>(
        API_ENDPOINTS.UPDATE_ONRAMP_PROGRESS,
        data,
      ),

    getOnrampProgress: (data: GetOnrampProgressData) =>
      HttpClient.post<GetOnrampProgress>(
        API_ENDPOINTS.GET_ONRAMP_PROGRESS,
        data,
      ),

    //onramp payment//
    initiateOnrampPayment: (data: InitiateOnrampPayment) =>
      HttpClient.post<InitiateOnrampPaymentResponse>(
        API_ENDPOINTS.INITIATE_ONRAMP_PAY_BY_BANK_AUTHORIZATION,
        data,
      ),

    getOnrampPaymentMethods: () =>
      HttpClient.get<PaymentMethodsResponse>(
        API_ENDPOINTS.GET_ONRAMP_PAYMENT_METHODS,
      ),

    getOnrampBankDetails: (bank_id: string) =>
      HttpClient.get<PayByBnkTransferResponse>(
        API_ENDPOINTS.GET_ONRAMP_BANK_DETAILS,
        {
          bank_id: bank_id,
        },
      ),

    finishPaymentForManualTransfer: (data: FinishManualTransferData) =>
      HttpClient.post(API_ENDPOINTS.FINISH_PAYMENT_FOR_MANUAL_TRANSFER, data),

    onrampPayByCard: (data: OnrampPayByCard) =>
      HttpClient.post<PayByCardResponse>(
        API_ENDPOINTS.ONRAMP_PAY_BY_CARD,
        data,
      ),

    getOnrampPaymentInstitutions: () =>
      HttpClient.get<OnrampPaymentInstitutionsResponse>(
        API_ENDPOINTS.GET_ONRAMP_PAYMENT_INSTITUTIONS,
      ),

    getOnrampYapilyDbStatus: (data: YapilyStatusQueryData) =>
      HttpClient.get<YapilyStatusResponse>(
        API_ENDPOINTS.GET_ONRAMP_YAPILY_DB_STATUS,
        data,
      ),
    getOnrampYapilyApiStatus: (data: YapilyStatusQueryData) =>
      HttpClient.get<YapilyStatusResponse>(
        API_ENDPOINTS.GET_ONRAMP_YAPILY_API_STATUS,
        data,
      ),

    getOnrampBankCallback: (data: BankCallbackParams, queryParams: string) =>
      HttpClient.get<BankCallbackResponse>(
        API_ENDPOINTS.GET_ONRAMP_BANK_CALLBACK(data, queryParams),
      ),

    //onramp sandbox //

    simulateOnrampSandboxPayment: (data: SimulateOnrampSandboxPaymentData) =>
      HttpClient.post<SimulateOnrampSandboxPaymentResponse>(
        API_ENDPOINTS.ONRAMP_SANDBOX_PAYMENT,
        data,
      ),

    //onramp assets//
    getOnrampAssetsOfType: (data: { type: 'crypto' | 'fiat' }) =>
      HttpClient.get(API_ENDPOINTS.GET_ONRAMP_ALL_ASSETS_OF_TYPE, data),

    convertOnrampAsset: (data: ConvertAssetParams) =>
      HttpClient.get<ConvertAssetResponse>(
        API_ENDPOINTS.CONVERT_ONRAMP_ASSET,
        data,
      ),

    getOnrampSingleAsset: (data: FindAssetParams) =>
      HttpClient.get(API_ENDPOINTS.GET_ONRAMP_SINGLE_ASSET(data.asset_id)),
  };

  assets = {
    getCoinNetworks: (data: { coin: string }) =>
      HttpClient.get<CoinNetworkResponse>(
        API_ENDPOINTS.GET_COIN_NETWORKS(data.coin),
      ),

    getAssets: (data: { type: 'crypto' | 'fiat' }) =>
      HttpClient.get<GetAssetsListResponse>(API_ENDPOINTS.GET_ASSETS, data),

    convertAsset: (data: ConvertAssetParams) =>
      HttpClient.get(API_ENDPOINTS.CONVERT_ASSET, data),

    findAsset: (data: FindAssetParams) =>
      HttpClient.get(API_ENDPOINTS.FIND_ASSET(data.asset_id)),
  };

  payments = {
    initiatePayByBank: (data: InitiatePayByBankData) =>
      HttpClient.post(API_ENDPOINTS.INITIATE_PAY_BY_BANK, data),

    getPaymentMethods: (data: PaymentMethodsParams) =>
      HttpClient.get(API_ENDPOINTS.GET_PAYMENT_METHODS, data),

    yapilyCallback: (data: YapilyCallbackParams) =>
      HttpClient.get(API_ENDPOINTS.YAPILY_CALLBACK(data)),
  };

  order = {
    getOrderDetails: (order: string) =>
      HttpClient.get<OrderDetailsResponse>(
        API_ENDPOINTS.GET_ORDER_DETAILS(order),
      ),

    getOrdersList: () =>
      HttpClient.get<OrderList>(API_ENDPOINTS.GET_ORDERS_LIST),

    getPaginatedOrders: (data: PaginationParams) =>
      HttpClient.get<PaginatedOrderList>(
        API_ENDPOINTS.GET_PAGINATED_ORDERS(data),
      ),

    confirmOrderByCustomer: (token?: string | null) =>
      HttpClient.get(API_ENDPOINTS.CONFIRM_ORDER_BY_CUSTOMER(token)),

    rejectOrderByCustomer: (token?: string | null) =>
      HttpClient.get(API_ENDPOINTS.REJECT_ORDER_BY_CUSTOMER(token)),
  };
}

export default new Client();

import type { FC } from 'react';
import React, { useState } from 'react';
import {
  BillingInformationItem,
  Button,
  Modal,
  PaymentMethodItem,
} from 'components';
import {
  BankTransferSEPA,
  BankTransferSWIFT,
  HourGlassIcon,
  PayByBank,
  PayByCard,
} from 'assets';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);
type BillingInformationProps = {
  setSelectedPayment: React.Dispatch<
    React.SetStateAction<
      | 'pay_by_card'
      | 'pay_by_bank'
      | 'bank_transfer_sepa'
      | 'bank_transfer_swift'
      | undefined
    >
  >;
  selectedPayment: string | undefined;
};

const BillingInformation: FC<BillingInformationProps> = ({
  selectedPayment,
  setSelectedPayment,
}) => {
  const dummyDate = '2024-03-02T16:20:59.000000Z';
  const createdAt = dayjs(dummyDate).locale('en').fromNow();

  const [modalState, setModalState] = useState<{
    value?:
      | 'pay_by_card'
      | 'pay_by_bank'
      | 'bank_transfer_sepa'
      | 'bank_transfer_swift';
    name?: string;
    open: boolean;
  }>({
    name: '',
    value: undefined,
    open: false,
  });

  return (
    <div
      className={`max-w-[504px] z-40 w-full h-fit shadow-lg bg-white dark:bg-bg-dark rounded-[20px] p-[20px] self-center ${selectedPayment ? 'relative' : 'absolute'}`}>
      <div className="text-center mb-5 flex justify-center items-center">
        <HourGlassIcon className="fill-black dark:fill-white" />
        <p>{createdAt}</p>
      </div>

      <div className="bg-gray-100 dark:bg-gray-800 p-4 rounded-xl">
        <h1 className="text-lg font-semibold">Billing Information</h1>
        <BillingInformationItem title="Invoice ID:" details="4JISD4490" />
        <BillingInformationItem title="Amount:" details="350 ETKT" />
        <BillingInformationItem title="Price:" details="350 ETKT" thick />
      </div>

      <div className="border mt-5 border-gray-300  dark:border-gray-600 mb-3" />

      <h1 className="font-medium text-xl">Choose payment method</h1>

      <PaymentMethodItem
        methodLogo={<PayByCard />}
        name="Pay By Card"
        value="pay_by_card"
        onClick={() =>
          setModalState({
            open: true,
            name: 'Pay By Card',
            value: 'pay_by_card',
          })
        }
        selectedPayment={selectedPayment}
      />

      <PaymentMethodItem
        methodLogo={<PayByBank />}
        name="Pay By Bank"
        value="pay_by_bank"
        onClick={() =>
          setModalState({
            open: true,
            name: 'Pay By Bank',
            value: 'pay_by_bank',
          })
        }
        selectedPayment={selectedPayment}
      />

      <PaymentMethodItem
        methodLogo={<BankTransferSEPA />}
        name="Bank Transfer (SEPA)"
        value="bank_transfer_sepa"
        onClick={() =>
          setModalState({
            open: true,
            name: 'Bank Transfer (SEPA)',
            value: 'bank_transfer_sepa',
          })
        }
        selectedPayment={selectedPayment}
      />

      <PaymentMethodItem
        methodLogo={<BankTransferSWIFT />}
        name="Bank Transfer (SWIFT)"
        value="bank_transfer_swift"
        onClick={() =>
          setModalState({
            open: true,
            name: 'Bank Transfer (SWIFT)',
            value: 'bank_transfer_swift',
          })
        }
        selectedPayment={selectedPayment}
      />

      <Button
        name="Cancel payment"
        variant="secondary"
        className="w-full"
        onClick={() => setSelectedPayment(undefined)}
      />

      <Modal
        showModal={modalState.open}
        onClose={() => setModalState({ open: false })}>
        <div className="flex flex-col gap-5">
          <p>Do you want to continue with {modalState.name}</p>

          <div className="flex justify-center gap-2">
            <Button
              name="Cancel"
              variant="secondary"
              onClick={() =>
                setModalState({
                  open: false,
                  name: '',
                  value: undefined,
                })
              }
            />
            <Button
              name="Continue"
              variant="primary"
              onClick={() => {
                setSelectedPayment(modalState.value);
                setModalState({ open: false });
              }}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default BillingInformation;

import type { FC } from 'react';
import React from 'react';

type InputErrorProps = {
  className?: string;
  errorMessage?: string;
};

const InputError: FC<InputErrorProps> = ({ className, errorMessage }) => {
  return (
    <div className={`ml-2 pt-2 text-red-700 ${className}`}>{errorMessage}</div>
  );
};

export default InputError;

import dayjs from 'dayjs';

const useDateFormatter = () => {
  const dateFormatter = ({ date }: { date?: string }) => {
    return dayjs(date).format('MMM D, YYYY');
  };

  return {
    dateFormatter,
  };
};

export default useDateFormatter;

import { useState, useEffect } from 'react';

export default function useDisableButton(initialTime = 30) {
  const [isDisabled, setIsDisabled] = useState(false);
  const [remainingSeconds, setRemainingSeconds] = useState(0);

  useEffect(() => {
    let timer: NodeJS.Timer;
    if (isDisabled) {
      setRemainingSeconds(initialTime); // Reset the countdown
      timer = setInterval(() => {
        setRemainingSeconds((prevSeconds) => {
          if (prevSeconds === 1) {
            clearInterval(timer);
            setIsDisabled(false);
          }
          return prevSeconds - 1;
        });
      }, 1000); // Update every second
    }
    return () => clearInterval(timer);
  }, [isDisabled, initialTime]);

  return {
    isDisabled,
    remainingSeconds,
    setIsDisabled,
  };
}

import type { FC } from 'react';
import { LearnMoreLines, MerchantLogoDark, MerchantLogoLight } from 'assets';
import { Switcher } from '../DarkModeSwitch';

const OnrampHeader: FC = () => {
  return (
    <div className="flex justify-between px-5 md:px-24 lg:px-52">
      <div className="flex gap-2 items-center">
        <div>
          <div>
            <MerchantLogoDark className="hidden dark:inline" />
            <MerchantLogoLight className="inline dark:hidden" />
          </div>
        </div>

        <div className="font-poppins text-xl md:text-2xl font-semibold">
          <h1>NIZA</h1>
          <h2 className=" text-[#25F49D]">On Ramp</h2>
        </div>
      </div>

      <div className="flex flex-col md:flex-row">
        <div className="relative">
          <p className="underline text-primary whitespace-nowrap">Learn more</p>
          <LearnMoreLines className="absolute top-[-5px] right-[-40px] stroke-black dark:stroke-white" />
        </div>
        <Switcher className="ml-14 pt-4 md:pt-0" height={30} width={30} />
      </div>
    </div>
  );
};

export default OnrampHeader;

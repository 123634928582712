import { useEffect, useMemo, useState, type FC } from 'react';
import SumsubWebSdk from '@sumsub/websdk-react';
import {
  useCreateSumsubApplicant,
  useGetSumsubApplicantAccessToken,
  useGetSumsubApplicantStatus,
} from 'services';
import { LoadingIcon } from 'assets';
import type { OnrampSessionType } from 'services/types';
import OnRampCardLayout from '../layout/OnRampCardLayout';
import { Button } from '../../Button';

type KycVerificationProps = {
  setUrlStep: (
    s: React.SetStateAction<
      Partial<{
        step?: any;
      }>
    >,
  ) => void;
  onrampSession: OnrampSessionType;
  setOnrampSession: React.Dispatch<React.SetStateAction<OnrampSessionType>>;
};

const KycVerification: FC<KycVerificationProps> = ({
  onrampSession,
  setUrlStep,
  setOnrampSession,
}) => {
  const [accessToken, setAccessToken] = useState<string | undefined>();

  const {
    data: userVerificationStatus,
    isLoading: isLoadingUserVerificationStatus,
    refetch,
  } = useGetSumsubApplicantStatus();

  const { createSumsubApplicant, isCreatingSumsubApplicant } =
    useCreateSumsubApplicant();
  const { getSumsubApplicantAccessToken, isGettingApplicantAccessToken } =
    useGetSumsubApplicantAccessToken();

  const handleVerifyClick = () => {
    if (
      onrampSession?.kycStatus === 'init' ||
      onrampSession?.kycStatus === 'completed' ||
      onrampSession?.kycStatus === 'pending'
    ) {
      getSumsubApplicantAccessToken(undefined, {
        onSuccess: (response) => {
          setAccessToken(response.kycToken);
        },
      });
    } else {
      createSumsubApplicant(undefined, {
        onSuccess: () => {
          setOnrampSession({
            ...onrampSession,
            kycStatus: 'init',
          });
          getSumsubApplicantAccessToken(undefined, {
            onSuccess: (response) => {
              setAccessToken(response.kycToken);
            },
          });
        },
        onError: () => {
          getSumsubApplicantAccessToken(undefined, {
            onSuccess: (response) => {
              setAccessToken(response.kycToken);
            },
          });
        },
      });
    }
  };

  const isBanned = useMemo(() => {
    if (
      !userVerificationStatus?.isVerified &&
      userVerificationStatus?.kycData.review_answer === 'RED' &&
      userVerificationStatus?.kycData?.reject_type === 'FINAL'
    ) {
      return true;
    }

    return false;
  }, [userVerificationStatus]);

  useEffect(() => {
    if (
      !accessToken &&
      !isLoadingUserVerificationStatus &&
      (isBanned ||
        (userVerificationStatus?.kycStatus !== 'init' &&
          userVerificationStatus?.kycStatus !== null))
    ) {
      if (
        onrampSession?.kycStatus === 'completed' ||
        onrampSession?.kycStatus === 'pending'
      ) {
        getSumsubApplicantAccessToken(undefined, {
          onSuccess: (response) => {
            setAccessToken(response.kycToken);
          },
        });
      } else {
        createSumsubApplicant(undefined, {
          onSuccess: () => {
            setOnrampSession({
              ...onrampSession,
              kycStatus: 'pending',
            });
            getSumsubApplicantAccessToken(undefined, {
              onSuccess: (response) => {
                setAccessToken(response.kycToken);
              },
            });
          },
          onError: () => {
            getSumsubApplicantAccessToken(undefined, {
              onSuccess: (response) => {
                setAccessToken(response.kycToken);
              },
            });
          },
        });
      }
    }
  }, [
    accessToken,
    createSumsubApplicant,
    getSumsubApplicantAccessToken,
    isBanned,
    onrampSession,
    setOnrampSession,
    userVerificationStatus?.kycStatus,
    isLoadingUserVerificationStatus,
  ]);

  const handleTokenExpiration = () => {
    // console.log('Token expired');
  };

  const moderationComment = userVerificationStatus?.kycData.moderation_comment;
  const clientComment = userVerificationStatus?.kycData?.client_comment;

  return (
    <OnRampCardLayout
      setStep={() =>
        setUrlStep({
          step: 'user-details',
        })
      }>
      <div className="flex justify-center items-center w-full  min-h-[500px] mt-10">
        {isCreatingSumsubApplicant || isGettingApplicantAccessToken ? (
          <LoadingIcon className="min-h-32 min-w-32" />
        ) : (
          <div className="w-full rounded-[20px] overflow-hidden">
            {accessToken ? (
              <SumsubWebSdk
                accessToken={accessToken}
                expirationHandler={handleTokenExpiration}
                config={{
                  lang: 'en',
                }}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onMessage={(type: any, payload: any) => {
                  if (payload?.reviewStatus == 'pending') {
                    setOnrampSession({
                      ...onrampSession,
                      kycStatus: 'pending',
                    });
                  }
                  if (payload?.reviewStatus == 'completed') {
                    setOnrampSession({
                      ...onrampSession,
                      kycStatus: 'completed',
                    });

                    if (
                      payload?.confirmed &&
                      payload.reviewResult.reviewAnswer === 'GREEN'
                    ) {
                      setTimeout(() => {
                        refetch();
                        setUrlStep({
                          step: 'pay',
                        });
                        setOnrampSession({
                          ...onrampSession,
                          isVerified: true,
                        });
                      }, 2000);
                    }
                  }
                }}
                onError={() => {
                  console.error('Sumsub verification error');
                }}
                // onNavigationUiControlsStateChanged={(state) => console.log(state)}
              />
            ) : (
              <div className="flex flex-col gap-4 items-center">
                <div className="text-xl text-center px-2 md:px-5">
                  {moderationComment
                    ? moderationComment
                    : 'Verify your identity'}{' '}
                  {}
                  <br />
                  <br />
                  {clientComment ? clientComment : ''} {}
                </div>
                {/* {userVerificationStatus?.kycStatus !== 'completed' ? ( */}
                <Button
                  name={moderationComment ? 'Verify again' : 'Verify'}
                  variant="secondary"
                  onClick={handleVerifyClick}
                  disabled={isLoadingUserVerificationStatus}
                  isLoading={isLoadingUserVerificationStatus}
                />
                {/* ) : null} */}
              </div>
            )}
          </div>
        )}
      </div>
    </OnRampCardLayout>
  );
};

export default KycVerification;

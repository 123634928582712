import * as React from 'react';
import type { SVGProps } from 'react';
const SettingsIconSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <g clipPath="url(#clip0_81_68)">
      <circle cx={12} cy={12} r={2} stroke="#1DA069" strokeWidth={2} />
      <path
        d="M5.39783 5.87922C4.46809 6.88131 3.79297 8.07052 3.39988 9.3446L4.99919 10.268C6.33252 11.0378 6.33252 12.9623 4.99919 13.7321L3.39817 14.6564C3.59338 15.2865 3.86134 15.9047 4.20504 16.5C4.54873 17.0953 4.95015 17.6365 5.39816 18.1205L6.99921 17.1962C8.33255 16.4264 9.99921 17.3886 9.99921 18.9282L9.99921 20.775C11.2991 21.0716 12.6666 21.0816 13.9993 20.7774L13.9993 18.9283C13.9993 17.3887 15.666 16.4264 16.9993 17.1962L18.6007 18.1208C19.5304 17.1187 20.2056 15.9295 20.5987 14.6554L18.9993 13.732C17.666 12.9622 17.666 11.0377 18.9993 10.2679L20.6004 9.34354C20.4051 8.71353 20.1372 8.0953 19.7935 7.50001C19.4498 6.9047 19.0484 6.36352 18.6003 5.87944L16.9993 6.8038C15.666 7.5736 13.9993 6.61135 13.9993 5.07175L13.9993 3.22501C12.6994 2.92841 11.3319 2.91847 9.99921 3.2226L9.99921 5.07172C9.99921 6.61132 8.33255 7.57357 6.99921 6.80377L5.39783 5.87922Z"
        stroke="#fill"
        strokeWidth={2}
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_81_68">
        <rect width={24} height={24} fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const SettingsIcon = React.memo(SettingsIconSvg);

export default SettingsIcon;

import type { FC } from 'react';
import React from 'react';

type TabProps = {
  setActiveSection: (value: React.SetStateAction<string>) => void;
  activeSection: string;
  tabItems: {
    id: number;
    label: string;
    value: string;
  }[];
};

const Tab: FC<TabProps> = ({ activeSection, setActiveSection, tabItems }) => {
  return (
    <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-white dark:border-gray-700">
      <ul className="flex flex-wrap -mb-px">
        {tabItems.map((item) => (
          <li className="me-2" key={item.id}>
            <button
              onClick={() => setActiveSection(item.value)}
              className={`${activeSection === item.value ? 'text-primary border-primary' : ''} inline-block p-4 border-b-2 rounded-t-lg hover:text-gray-600  dark:hover:text-gray-300`}>
              {item.label}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Tab;

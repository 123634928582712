import type { FC } from 'react';
import React from 'react';

type LabelProps = {
  text?: string;
  className?: string;
};

const Label: FC<LabelProps> = ({ text, className }) => {
  return (
    <div className={`font-medium text-sm whitespace-nowrap ${className}`}>
      {text}
    </div>
  );
};

export default Label;

import type { FC, ReactElement } from 'react';
import React from 'react';
import { Button, Skeleton } from 'components';
import { CopyIcon } from 'assets';
import type { ToastStateProps } from 'services/types';

type OnrampBankInfoItem = {
  icon: ReactElement;
  label: string;
  value?: string | number | null;
  isLoading?: boolean;
  setToastState?: React.Dispatch<React.SetStateAction<ToastStateProps>>;
};

const OnrampBankInfoItem: FC<OnrampBankInfoItem> = ({
  icon,
  label,
  value,
  isLoading,
  setToastState,
}) => {
  return (
    <div className="flex justify-between mt-2 items-center">
      <div className="flex gap-2 items-centers">
        <div>{icon}</div>
        <div className="flex flex-col gap-2">
          <div className="text-gray-500 dark:text-gray-300">{label}</div>
          {isLoading ? (
            <Skeleton className="h-6 w-52" />
          ) : (
            <div className="text-sm">{value ?? ''}</div>
          )}
        </div>
      </div>

      <div>
        <Button
          name="Copy"
          variant="secondary"
          className="border-none"
          onClick={() => {
            navigator.clipboard.writeText(value?.toString() ?? '');
            if (setToastState) {
              setToastState({
                active: true,
                toastMessage: `${label} copied successfully`,
                variant: 'success',
              });
            }
          }}
          iconBefore={
            <CopyIcon className="dark:stroke-white w-6 h-6" stroke="#292929" />
          }
        />
      </div>
    </div>
  );
};

export default OnrampBankInfoItem;

import * as React from 'react';
import type { SVGProps } from 'react';

const UserSvgLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <g clipPath="url(#clip0_81_52)">
      <path
        d="M4 18C4 15.7908 5.79086 14 8 14H16C18.2091 14 20 15.7908 20 18V18C20 19.1045 19.1046 20 18 20H6C4.89543 20 4 19.1045 4 18V18Z"
        stroke="fill"
        strokeWidth={2}
        strokeLinejoin="round"
      />
      <circle cx={12} cy={6.99997} r={3} stroke="#1DA069" strokeWidth={2} />
    </g>
    <defs>
      <clipPath id="clip0_81_52">
        <rect width={24} height={24} fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const UserLogo = React.memo(UserSvgLogo);

export default UserLogo;

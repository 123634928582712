import client from '../client';
import type { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { API_ENDPOINTS } from '../client/api-endpoints';
import type {
  GeneralError,
  OrderDetailsResponse,
  OrderList,
  PaginatedOrderList,
  PaginationParams,
} from '../types';

export function useGetOrderDetails(orderId: string) {
  const query = useQuery<OrderDetailsResponse, AxiosError<GeneralError>>({
    queryKey: [API_ENDPOINTS.GET_ORDER_DETAILS(orderId)],
    queryFn: () => client.order.getOrderDetails(orderId),
    enabled: !!orderId,
  });

  return query;
}

export function useGetOrdersList() {
  const query = useQuery<OrderList, AxiosError<GeneralError>>({
    queryKey: [API_ENDPOINTS.GET_ORDERS_LIST],
    queryFn: client.order.getOrdersList,
  });

  return query;
}

export function useGetPaginatedOrders(data: PaginationParams) {
  const query = useQuery<PaginatedOrderList, AxiosError<GeneralError>>({
    queryKey: [API_ENDPOINTS.GET_PAGINATED_ORDERS(data)],
    queryFn: () => client.order.getPaginatedOrders(data),
  });

  return query;
}

export function useConfirmOrderByCustomer(token?: string | null) {
  const query = useQuery({
    queryKey: [API_ENDPOINTS.CONFIRM_ORDER_BY_CUSTOMER(token)],
    queryFn: () => client.order.confirmOrderByCustomer(token),
    enabled: !!token,
  });

  return query;
}

export function useRejectOrderByCustomer(token?: string | null) {
  const query = useQuery({
    queryKey: [API_ENDPOINTS.REJECT_ORDER_BY_CUSTOMER(token)],
    queryFn: () => client.order.rejectOrderByCustomer(token),
    enabled: !!token,
  });

  return query;
}

import { DocumentIcon } from 'assets';
import { Button, Input, InputError, Label, Modal, TextArea } from 'components';
import { Formik } from 'formik';
import type { FC } from 'react';
import React, { useMemo } from 'react';
import {
  queryClient,
  useCreateNewInvoice,
  useGetCustomersList,
} from 'services';
import type { FormInvoiceData, ToastStateProps } from 'services/types';
import { CreateInvoiceSchema } from 'utils';
import { ReactSelectComponent } from 'components';
import { API_ENDPOINTS } from '../services/client/api-endpoints';

type CreateInvoiceFromProps = {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  initialValues?: FormInvoiceData;
  setToastState: React.Dispatch<React.SetStateAction<ToastStateProps>>;
};

const CreateInvoiceFrom: FC<CreateInvoiceFromProps> = ({
  setShowModal,
  showModal,
  initialValues,
  setToastState,
}) => {
  const previewData = !!initialValues?.id;

  const { createNewInvoice, isCreatingInvoice } = useCreateNewInvoice();
  const { data: customersList } = useGetCustomersList();

  const transformCustomerList = useMemo<
    { id: string; label: string; value: string }[] | undefined
  >(
    () =>
      customersList?.data?.map((customer) => ({
        id: customer.id,
        label: `${customer.first_name} ${customer.last_name}`,
        value: customer.id,
      })),
    [customersList],
  );

  const transformedInitialValues = () => {
    if (initialValues) {
      initialValues['description'] = '';
    }
    return initialValues;
  };

  const modalHeader = (
    <div className="flex flex-col items-center pb-5 border-b border-solid dark:border-gray-600 border-gray-300 rounded-t ">
      <div className="border p-3 rounded-xl w-fit">
        <DocumentIcon className="dark:stroke-white stroke-black" />
      </div>

      <h3 className="text-lg font-semibold mt-4">
        {previewData ? 'Invoice details' : 'Create Invoice'}
      </h3>
      <p className="text-gray-600 dark:text-gray-300 mx-5">
        {previewData
          ? 'Explore your Invoice details'
          : 'Create your Invoice for free in less than 5 minutes.'}
      </p>
    </div>
  );

  return (
    <div>
      <Formik
        initialValues={
          transformedInitialValues() || {
            terminal: '',
            customer: undefined,
            // sending_email: '',
            amount: '',
            // my_wallet: '',
            // currency_pay: '',
            description: '',
          }
        }
        onSubmit={(values, { setSubmitting }) => {
          if (values.customer?.id) {
            createNewInvoice(
              {
                ...values,
                amount: values.amount.toString(),
                customer_id: values.customer?.id,
                description: values.description || undefined,
              },
              {
                onSuccess: (response) => {
                  queryClient.invalidateQueries({
                    queryKey: [API_ENDPOINTS.GET_PAGINATED_INVOICES()],
                    exact: false,
                  });
                  setToastState({
                    active: true,
                    toastMessage: response.message,
                  });
                  setTimeout(() => {
                    setSubmitting(false);
                    setShowModal(false);
                  }, 400);
                },
                onError: (error) => {
                  setToastState({
                    active: true,
                    toastMessage: error.response?.data.message,
                  });
                  setSubmitting(false);
                },
              },
            );
          }
        }}
        validationSchema={previewData ? undefined : CreateInvoiceSchema}>
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} id="addInvoiceForm">
            <Modal
              onClose={() => setShowModal(false)}
              showModal={showModal}
              header={modalHeader}
              footer={
                <div className="flex items-center justify-center p-4 border-t border-solid dark:border-gray-600 border-gray-300 rounded-b">
                  {!previewData && (
                    <Button
                      name="Create invoice"
                      type="submit"
                      isLoading={isSubmitting || isCreatingInvoice}
                      variant="primary"
                    />
                  )}
                </div>
              }
              className="inset-0 h-[100vh]">
              <div className="max-h-[50vh] min-w-[300px] md:min-w-[500px]">
                <div className="flex md:flex-row flex-col gap-2 items-start md:items-center ">
                  <Label text="Terminal*" className="w-36" />
                  <div className="w-full">
                    {previewData ? (
                      <p className="w-full ml-2">{values.terminal}</p>
                    ) : (
                      <Input
                        type="text"
                        onChange={handleChange}
                        handleBlur={handleBlur}
                        value={values.terminal}
                        name="terminal"
                        disabled={previewData}
                        placeholder="e.g. NIZA Ex"
                      />
                    )}
                  </div>
                </div>
                {errors.terminal && touched.terminal ? (
                  <InputError
                    errorMessage={errors.terminal}
                    className="ml-2 md:ml-32 pt-2 "
                  />
                ) : null}
                <div className="flex mt-4 md:flex-row flex-col gap-2 items-start md:items-center">
                  <Label text="Customer*" className="w-36 " />
                  {/* <SingleSelect
                    className="w-full"
                    selected={values.customer}
                    onChange={handleChange}
                    handleBlur={handleBlur}
                  /> */}
                  {previewData ? (
                    <p className="w-full ml-2">{values.customer?.label}</p>
                  ) : (
                    <ReactSelectComponent
                      name="customer"
                      onClick={(val) => setFieldValue('customer', val)}
                      options={transformCustomerList}
                    />
                  )}
                </div>
                {errors.customer && touched.customer ? (
                  <InputError
                    errorMessage={errors.customer as string}
                    className="ml-2 md:ml-32 pt-2 "
                  />
                ) : null}

                {/* <div className="flex mt-4 md:flex-row flex-col gap-2 md:gap-5items-start md:items-center">
                  <Label text="Last name*" className="w-36 " />
                  <Input
                    type="text"
                    className="w-full"
                    onChange={handleChange}
                    handleBlur={handleBlur}
                    value={values.last_name}
                    name="last_name"
                    disabled={previewData}
                    placeholder="e.g. Doe"
                  />
                </div>

                {errors.last_name && touched.last_name ? (
                  <InputError
                    errorMessage={errors.last_name}
                    className="ml-2 md:ml-32 pt-2 "
                  />
                ) : null} */}

                {/* <div className="flex mt-4 md:flex-row flex-col gap-2 md:gap-5items-start md:items-center">
                  <Label text="Sending email" className="w-36 " />
                  <Input
                    type="text"
                    className="w-full"
                    onChange={handleChange}
                    handleBlur={handleBlur}
                    value={values.sending_email}
                    name="sending_email"
                    disabled={previewData}
                    placeholder="e.g. johndoe@gmail.com"
                  />
                </div>
                {errors.sending_email && touched.sending_email ? (
                  <InputError
                    errorMessage={errors.sending_email}
                    className="ml-2 md:ml-32 pt-2 "
                  />
                ) : null} */}

                <div className="flex mt-4 md:flex-row flex-col gap-2 items-start md:items-center">
                  <Label text="Amount*" className="w-36 " />
                  {previewData ? (
                    <p className="w-full ml-2">{values.amount}</p>
                  ) : (
                    <Input
                      type="number"
                      className="w-full"
                      onChange={handleChange}
                      handleBlur={handleBlur}
                      value={values.amount}
                      name="amount"
                      disabled={previewData}
                      placeholder="e.g. $500"
                    />
                  )}
                </div>
                {errors.amount && touched.amount ? (
                  <InputError
                    errorMessage={errors.amount}
                    className="ml-2 md:ml-32 pt-2 "
                  />
                ) : null}

                {/* <div className="flex mt-4 md:flex-row flex-col gap-2 md:gap-5items-start md:items-center">
                  <Label text="My wallet*" className="w-36 " />
                  <SingleSelect className="w-full" disabled={previewData} />
                </div>

                <div className="flex mt-4 md:flex-row flex-col gap-2 md:gap-5items-start md:items-center">
                  <Label text="Currency Pay*" className="w-36 " />
                  <SingleSelect className="w-full" disabled={previewData} />
                </div> */}

                <div className="flex mt-4 pb-2 md:flex-row flex-col gap-2 items-start md:items-center">
                  <Label text="Description" className="w-36 " />
                  {previewData ? (
                    <p className="w-full ml-2">{values.description}</p>
                  ) : (
                    <TextArea
                      className="w-full"
                      onChange={handleChange}
                      handleBlur={handleBlur}
                      value={values.description}
                      name="description"
                      disabled={previewData}
                      placeholder="Enter a description..."
                    />
                  )}
                </div>

                {errors.description && touched.description ? (
                  <InputError
                    errorMessage={errors.description}
                    className="ml-2 md:ml-32 pt-2 "
                  />
                ) : null}
              </div>
            </Modal>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default CreateInvoiceFrom;

import { useEffect, useState } from 'react';

export default function useDarkSide() {
  const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
  const systemTheme = mediaQuery.matches ? 'dark' : 'light';

  const [theme, setTheme] = useState(localStorage.theme || systemTheme);
  const colorTheme = theme === 'dark' ? 'light' : 'dark';

  useEffect(() => {
    const root = window.document.documentElement;
    root.classList.remove(colorTheme);
    root.classList.add(theme || systemTheme);

    // save theme to local storage
    localStorage.setItem('theme', theme || systemTheme);
  }, [theme, colorTheme, systemTheme]);

  return { colorTheme, setTheme };
}

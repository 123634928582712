import type { FC } from 'react';
import { Button } from '../Button';
import { ArrowLeft, ArrowRight } from 'assets';
import GeneratePagination from './GeneratePagination';

type TablePaginationProps = {
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  currentPage: number;
  totalPages: number;
};

const TablePagination: FC<TablePaginationProps> = ({
  currentPage,
  setCurrentPage,
  totalPages,
}) => {
  return (
    <div className="w-full bg-white dark:bg-bg-dark flex justify-center md:justify-between rounded-b-xl border dark:border-bg-dark pt-3 px-6 pb-4">
      <div className="hidden md:flex min-w-[114px]">
        {currentPage > 1 && (
          <Button
            name="Previous"
            size="small"
            variant="default"
            iconBefore={
              <ArrowLeft className="stroke-gray-700 dark:stroke-gray-300" />
            }
            disabled={currentPage < 2}
            onClick={() => setCurrentPage(currentPage - 1)}
          />
        )}
      </div>

      <GeneratePagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />

      <div className="hidden md:flex min-w-[84px]">
        {currentPage < totalPages && (
          <Button
            name="Next"
            size="small"
            variant="default"
            disabled={currentPage >= totalPages}
            iconAfter={
              <ArrowRight className="stroke-gray-700 dark:stroke-gray-300" />
            }
            onClick={() => setCurrentPage(currentPage + 1)}
            className="hidden md:flex "
          />
        )}
      </div>
    </div>
  );
};

export default TablePagination;

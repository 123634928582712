import { PageHeader, Switcher, Tab } from 'components';
import { useState, type FC } from 'react';
import { BrandDetails, FeeManagement, Products } from './components';

const tabItems = [
  {
    id: 1,
    label: 'Brand Details',
    value: 'brandDetails',
  },
  {
    id: 2,
    label: 'Fee Management',
    value: 'feeManagement',
  },
  {
    id: 3,
    label: 'Products',
    value: 'products',
  },
];

const Settings: FC = () => {
  const [activeSection, setActiveSection] = useState<string>('brandDetails');

  return (
    <div>
      <PageHeader title="Settings" rightContent={<Switcher />} />

      <Tab
        activeSection={activeSection}
        setActiveSection={setActiveSection}
        tabItems={tabItems}
      />
      {activeSection === 'brandDetails' && (
        <div className="mt-5 max-w-fit">
          <BrandDetails />
        </div>
      )}
      {activeSection === 'feeManagement' && (
        <div className="mt-5">
          <FeeManagement />
        </div>
      )}

      {activeSection === 'products' && (
        <div className="mt-5">
          <Products />
        </div>
      )}
    </div>
  );
};

export default Settings;

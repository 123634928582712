import type { FC, ReactElement } from 'react';
import type { Product } from 'services/types';

type ProductCardProps = {
  product: Product;
  actionButton?: ReactElement | null;
};

const ProductCard: FC<ProductCardProps> = ({ product, actionButton }) => {
  return (
    <div className="flex flex-col justify-between border dark:border-gray-600 border-gray-300 w-full xl:w-[49%] rounded-xl bg-white dark:bg-bg-dark p-5 gap-8 min-h-40">
      <div className="flex justify-between">
        <div>
          <h1 className="text-primary  text-lg font-medium">{product.name}</h1>
          <p className="text-sm text-gray-text dark:text-gray-300">
            {product.description}
          </p>
        </div>

        <div>
          {product.is_enabled ? (
            <span className="text-primary">Enabled</span>
          ) : (
            <span className="text-error-500">Disabled</span>
          )}
        </div>
      </div>

      {product.is_enabled ? (
        <div className="self-end">{actionButton}</div>
      ) : null}
    </div>
  );
};

export default ProductCard;

import { ChevronLeftIcon, ThreeDotsIcon } from 'assets';
import { useEncryptedLocalStorageState, useLocalStorageState } from 'hooks';
import type { FC, ReactElement } from 'react';
import { useState } from 'react';
import type { OnrampSessionType } from 'services/types';
import { ENCRYPTED_ONRAMP_SESSION_TOKEN, ONRAMP_USER_SESSION, cn } from 'utils';
import OnrampUserInfo from './OnrampUserInfo';

type OnRampCardLayoutProps = {
  children: ReactElement | ReactElement[];
  setStep?: () => void;
  className?: string;
  showUserData?: boolean;
  childrenClassName?: string;
};

const OnRampCardLayout: FC<OnRampCardLayoutProps> = ({
  children,
  setStep,
  className,
  showUserData = true,
  childrenClassName,
}) => {
  const [displayUserInfo, setDisplayUserInfo] = useState(false);
  const [onrampSession] =
    useLocalStorageState<OnrampSessionType>(ONRAMP_USER_SESSION);

  const [onrampSessionToken] = useEncryptedLocalStorageState(
    ENCRYPTED_ONRAMP_SESSION_TOKEN,
  );

  return (
    <div
      className={cn(
        'flex flex-col justify-between relative max-w-[566px] bg-white dark:bg-bg-dark  w-full p-5 rounded-[20px] shadow-lg overflow-hidden h-fit',
        className,
      )}>
      <div
        onClick={() => setDisplayUserInfo(false)}
        className={cn(childrenClassName)}>
        {children}
      </div>
      {setStep ? (
        <div
          className="absolute cursor-pointer p-5 top-2 left-2"
          onClick={() => {
            if (setStep) {
              setStep();
            }
          }}>
          <ChevronLeftIcon className="stroke-black dark:stroke-white" />
        </div>
      ) : null}

      {onrampSessionToken && showUserData ? (
        <div
          className="absolute top-2 right-2 p-4 cursor-pointer"
          onClick={(e) => {
            e.stopPropagation();
            setDisplayUserInfo(true);
          }}>
          <ThreeDotsIcon
            height="30px"
            width="30px"
            className="stroke-black dark:stroke-white fill-black dark:fill-white"
          />
        </div>
      ) : null}

      <OnrampUserInfo
        displayUserInfo={displayUserInfo}
        setDisplayUserInfo={setDisplayUserInfo}
        onrampSession={onrampSession}
      />
    </div>
  );
};

export default OnRampCardLayout;

import { useOnrampInitialData } from 'providers/OnrampDataProvider';
import type { FC } from 'react';
import React, { useEffect, useMemo } from 'react';
import { useGetOnrampPaymentMethods, useUpdateOnrampProgress } from 'services';
import type { PaymentMethod } from 'services/types';
import { cn, getUrlParams } from 'utils';
import { Skeleton } from '../../Skeleton';
import OnrampPaymentMethodItem from './components/OnrampPaymentMethodItem';

type OnrampSelectPaymentMethodProps = {
  setSelectedPayment: React.Dispatch<
    React.SetStateAction<PaymentMethod | undefined>
  >;
  selectedPayment: PaymentMethod | undefined;
  isCreatingDraftOrder: boolean;
};

const OnrampSelectPaymentMethod: FC<OnrampSelectPaymentMethodProps> = ({
  setSelectedPayment,
  selectedPayment,
  isCreatingDraftOrder,
}) => {
  const { data: paymentMethods, isLoading: isGettingPaymentMethods } =
    useGetOnrampPaymentMethods();

  const initialData = getUrlParams('data');

  const { updateOnrampProgress } = useUpdateOnrampProgress();

  const { onrampProgress } = useOnrampInitialData();

  const getProgressPaymentMethod = useMemo(() => {
    return paymentMethods?.data?.find(
      (payments) =>
        payments.provider ===
        onrampProgress?.data.payment_method?.payment_method,
    );
  }, [onrampProgress?.data.payment_method, paymentMethods?.data]);

  useEffect(() => {
    if (!selectedPayment && getProgressPaymentMethod) {
      setSelectedPayment(getProgressPaymentMethod);
    }
  }, [getProgressPaymentMethod, selectedPayment, setSelectedPayment]);

  const handleSelectPaymentMethod = (paymentMethod: PaymentMethod) => {
    if (onrampProgress?.data.order_id && initialData) {
      setSelectedPayment(paymentMethod);
      updateOnrampProgress({
        on_ramp_data: initialData,
        order_id: onrampProgress?.data.order_id,
        payment_method: JSON.stringify({
          payment_method: paymentMethod.provider,
        }),
      });
    }
  };

  return (
    <div className="flex flex-col justify-between h-full min-h-96 ">
      <div className="mt-10">
        <div>
          <h1 className="text-center text-2xl">Chose payment method</h1>
        </div>

        <div className="mt-10">
          {isGettingPaymentMethods ? (
            <div>
              <Skeleton className="w-full h-[88px] rounded-[10px] my-5" />
              <Skeleton className="w-full h-[88px] rounded-[10px] my-5" />
              <Skeleton className="w-full h-[88px] rounded-[10px] my-5" />
              <Skeleton className="w-full h-[88px] rounded-[10px] my-5" />
            </div>
          ) : (
            paymentMethods?.data?.map((paymentMethod) => (
              <OnrampPaymentMethodItem
                methodLogo={paymentMethod?.icon}
                selectedPayment={selectedPayment}
                value={'1'}
                name={paymentMethod?.name}
                onClick={() => handleSelectPaymentMethod(paymentMethod)}
                key={paymentMethod.provider}
                className={cn({
                  'pointer-events-none': isCreatingDraftOrder,
                })}
              />
            ))
          )}
        </div>
      </div>

      <div></div>
    </div>
  );
};

export default OnrampSelectPaymentMethod;

import * as Yup from 'yup';

export const CreateInvoiceSchema = Yup.object().shape({
  terminal: Yup.string().required('Terminal is required'),
  customer: Yup.object().required('Customer is required'),
  amount: Yup.number()
    .typeError('Please enter a valid amount')
    .required('Amount is required'),
  description: Yup.string(),
});

export const CreateCustomerSchema = Yup.object().shape({
  first_name: Yup.string().required('First name is required'),
  last_name: Yup.string().required('Last name is required'),
  email: Yup.string()
    .email('Please enter a valid email')
    .required('Email is required'),
});

export const OnrampLoginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Please enter a valid email')
    .required('Email is required'),
  mobile_number: Yup.string()
    .min(5, 'Please enter the full number')
    .required('Mobile number is required'),
});

export const OnrampUserDataUpdateSchema = Yup.object().shape({
  first_name: Yup.string().required('First name is required'),
  last_name: Yup.string().required('Last name is required'),
  address_line1: Yup.string().required('Address line 1 is required'),
  address_line2: Yup.string(),
  country: Yup.object()
    .shape({
      id: Yup.string().required('Country ID is required'),
      label: Yup.string().required('Country label is required'),
      value: Yup.string().required('Country is required'),
    })
    .required('Country is required'),
  city: Yup.string().required('City is required'),
  state: Yup.string(),
  postal_code: Yup.string().required('Postal code is required'),
  dob: Yup.string().required('Date of birth is required'),
});

export const GenerateOnrampLinkSchema = Yup.object().shape({
  customer_wallet_address: Yup.string().required(
    'Customer wallet address is required',
  ),
  source_amount: Yup.string().required('Source amount is required'),
  destination_currency: Yup.object()
    .shape({
      id: Yup.string().required('Destination ID is required'),
      label: Yup.string().required('Destination label is required'),
      value: Yup.string().required('Destination is required'),
    })
    .required('Destination currency is required'),
  source_currency: Yup.object()
    .shape({
      id: Yup.string().required('Source ID is required'),
      label: Yup.string().required('Source label is required'),
      value: Yup.string().required('Source is required'),
    })
    .required('Source currency is required'),
  network: Yup.object()
    .shape({
      id: Yup.string().required('Network ID is required'),
      label: Yup.string().required('Network label is required'),
      value: Yup.string().required('Network is required'),
    })
    .required('Network destination is required'),
});

import * as React from 'react';
import type { SVGProps } from 'react';
const ChevronDownIconSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={14}
    height={8}
    viewBox="0 0 14 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M13.25 0.875L7 7.125L0.75 0.875"
      stroke="#4E4E4E"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const ChevronDownIcon = React.memo(ChevronDownIconSvg);
export default ChevronDownIcon;

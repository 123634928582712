import type { FC } from 'react';
import React, { useState } from 'react';
import useDarkSide from 'hooks/useDarkSide';
import { DarkModeSwitch } from 'react-toggle-dark-mode';

type SwitcherProps = {
  className?: string;
  height?: number;
  width?: number;
};

const Switcher: FC<SwitcherProps> = ({ className, height, width }) => {
  const { colorTheme, setTheme } = useDarkSide();
  const [darkSide, setDarkSide] = useState(
    colorTheme === 'light' ? true : false,
  );

  const toggleDarkMode = (checked: boolean) => {
    setTheme(colorTheme);
    setDarkSide(checked);
  };

  return (
    <div className={className}>
      <DarkModeSwitch
        checked={darkSide}
        onChange={toggleDarkMode}
        size={56}
        sunColor="#101828"
        style={{
          height: height ?? 40,
          width: width ?? 40,
        }}
      />
    </div>
  );
};
export default Switcher;

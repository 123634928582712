export function getFormattedCurrency(
  amount = 0,
  currency: string | null = 'USD',
  digits = 2,
  notation: Intl.NumberFormatOptions['notation'] = 'standard',
) {
  try {
    return new Intl.NumberFormat('en-US', {
      style: currency ? 'currency' : undefined,
      currency: currency || undefined,
      notation: notation,
      maximumFractionDigits: digits,
      compactDisplay: 'long',
    }).format(amount);
  } catch (error) {
    return '';
  }
}

export default getFormattedCurrency;

import * as React from 'react';
import type { SVGProps } from 'react';
const KeyIconSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={52}
    height={52}
    viewBox="0 0 52 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <g filter="url(#filter0_d_138_9874)">
      <rect x={2} y={1} width={48} height={48} rx={12} fill="white" />
      <rect x={2.5} y={1.5} width={47} height={47} rx={11.5} stroke="#EAECF0" />
      <rect
        width={24}
        height={24}
        rx={5}
        transform="matrix(1 0 0 -1 14 37)"
        stroke="#1DA069"
        strokeWidth={1.5}
      />
      <path
        d="M32.6031 24.4L24.2031 24.4"
        stroke="black"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.6016 26.8L32.6016 24.4"
        stroke="black"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.6016 26.8L29.6016 24.4"
        stroke="black"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx={21.8031}
        cy={24.4}
        r={2.4}
        transform="rotate(-180 21.8031 24.4)"
        stroke="black"
        strokeWidth={1.5}
      />
    </g>
    <defs>
      <filter
        id="filter0_d_138_9874"
        x={0}
        y={0}
        width={52}
        height={52}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB">
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={1} />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_138_9874"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_138_9874"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

const KeyIcon = React.memo(KeyIconSvg);

export default KeyIcon;

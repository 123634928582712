import * as React from 'react';
import type { SVGProps } from 'react';
const LogOutBannerLogoSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={156}
    height={85}
    viewBox="0 0 156 85"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <ellipse
      cx={78}
      cy={73}
      rx={77.5}
      ry={11.5}
      fill="black"
      fillOpacity={0.03}
    />
    <rect
      x={31}
      y={6}
      width={96}
      height={66}
      rx={5.5}
      fill="#E3F3ED"
      stroke="black"
    />
    <circle cx={40.5} cy={13.5} r={2} fill="#1DA069" />
    <circle cx={46.5} cy={13.5} r={2} fill="#1DA069" />
    <circle cx={52.5} cy={13.5} r={2} fill="#1DA069" />
    <line x1={30.5} y1={21} x2={127.5} y2={21} stroke="black" />
    <mask id="path-7-inside-1_0_1" fill="white">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M123.514 24.5C130.142 24.5 135.514 19.1274 135.514 12.5C135.514 5.87258 130.142 0.5 123.514 0.5C116.887 0.5 111.514 5.87258 111.514 12.5C111.514 14.2434 111.886 15.9 112.555 17.3948C112.695 17.7078 112.668 18.0666 112.579 18.3978L111.57 22.1642C111.172 23.6513 112.533 25.0122 114.02 24.6137L117.786 23.6045C118.171 23.5013 118.589 23.4531 118.957 23.6046C120.362 24.1818 121.901 24.5 123.514 24.5Z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M123.514 24.5C130.142 24.5 135.514 19.1274 135.514 12.5C135.514 5.87258 130.142 0.5 123.514 0.5C116.887 0.5 111.514 5.87258 111.514 12.5C111.514 14.2434 111.886 15.9 112.555 17.3948C112.695 17.7078 112.668 18.0666 112.579 18.3978L111.57 22.1642C111.172 23.6513 112.533 25.0122 114.02 24.6137L117.786 23.6045C118.171 23.5013 118.589 23.4531 118.957 23.6046C120.362 24.1818 121.901 24.5 123.514 24.5Z"
      fill="#1DA069"
    />
    <path
      d="M111.57 22.1642L112.536 22.423L111.57 22.1642ZM114.02 24.6137L114.279 25.5796H114.279L114.02 24.6137ZM118.957 23.6046L118.577 24.5296L118.957 23.6046ZM112.555 17.3948L113.467 16.9865L112.555 17.3948ZM134.514 12.5C134.514 18.5751 129.589 23.5 123.514 23.5V25.5C130.694 25.5 136.514 19.6797 136.514 12.5H134.514ZM123.514 1.5C129.589 1.5 134.514 6.42487 134.514 12.5H136.514C136.514 5.3203 130.694 -0.5 123.514 -0.5V1.5ZM112.514 12.5C112.514 6.42487 117.439 1.5 123.514 1.5V-0.5C116.334 -0.5 110.514 5.3203 110.514 12.5H112.514ZM113.467 16.9865C112.855 15.6177 112.514 14.1 112.514 12.5H110.514C110.514 14.3869 110.917 16.1824 111.642 17.8032L113.467 16.9865ZM112.536 22.423L113.545 18.6566L111.614 18.139L110.604 21.9054L112.536 22.423ZM113.761 23.6478C113.017 23.847 112.337 23.1666 112.536 22.423L110.604 21.9054C110.007 24.1361 112.048 26.1773 114.279 25.5796L113.761 23.6478ZM117.527 22.6386L113.761 23.6478L114.279 25.5796L118.045 24.5704L117.527 22.6386ZM123.514 23.5C122.034 23.5 120.624 23.2081 119.337 22.6796L118.577 24.5296C120.101 25.1554 121.769 25.5 123.514 25.5V23.5ZM118.045 24.5704C118.364 24.4849 118.517 24.5046 118.577 24.5296L119.337 22.6796C118.66 22.4016 117.978 22.5177 117.527 22.6386L118.045 24.5704ZM111.642 17.8032C111.643 17.8061 111.653 17.8294 111.653 17.8885C111.653 17.9498 111.642 18.0333 111.614 18.139L113.545 18.6566C113.661 18.2258 113.742 17.6012 113.467 16.9865L111.642 17.8032Z"
      fill="black"
      mask="url(#path-7-inside-1_0_1)"
    />
    <circle
      cx={63.5}
      cy={38.5}
      r={2.28125}
      fill="#1DA069"
      stroke="#1DA069"
      strokeWidth={1.4375}
    />
    <circle
      cx={95.5}
      cy={38.5}
      r={2.28125}
      fill="#1DA069"
      stroke="#1DA069"
      strokeWidth={1.4375}
    />
    <path
      d="M67.5 52.5C67.5 52.5 73.0385 56.5 79.5 56.5C85.9615 56.5 91.5 52.5 91.5 52.5"
      stroke="#1DA069"
      strokeWidth={2.875}
      strokeLinecap="round"
    />
    <path
      d="M127.854 16.1481C127.948 16.2418 128 16.3687 128 16.5009C128 16.6332 127.948 16.76 127.854 16.8538C127.786 16.9206 126.176 18.5 123.5 18.5C121.163 18.5 119.467 17.1 118.5 16.0094V17.5C118.5 17.6326 118.447 17.7598 118.354 17.8536C118.26 17.9473 118.133 18 118 18C117.867 18 117.74 17.9473 117.646 17.8536C117.553 17.7598 117.5 17.6326 117.5 17.5V14.5C117.5 14.3674 117.553 14.2402 117.646 14.1464C117.74 14.0527 117.867 14 118 14H121C121.133 14 121.26 14.0527 121.354 14.1464C121.447 14.2402 121.5 14.3674 121.5 14.5C121.5 14.6326 121.447 14.7598 121.354 14.8536C121.26 14.9473 121.133 15 121 15H118.965C119.735 15.9594 121.312 17.5 123.5 17.5C125.75 17.5 127.134 16.1588 127.148 16.145C127.242 16.0517 127.369 15.9996 127.502 16.0002C127.634 16.0008 127.761 16.054 127.854 16.1481ZM129 7C128.867 7 128.74 7.05268 128.646 7.14645C128.553 7.24021 128.5 7.36739 128.5 7.5V8.99062C127.533 7.9 125.837 6.5 123.5 6.5C120.824 6.5 119.214 8.07938 119.146 8.14625C119.052 8.2399 118.999 8.36706 118.999 8.49974C118.999 8.63242 119.051 8.75976 119.145 8.85375C119.239 8.94774 119.366 9.00067 119.498 9.0009C119.631 9.00114 119.759 8.94865 119.852 8.855C119.866 8.84125 121.25 7.5 123.5 7.5C125.688 7.5 127.265 9.04063 128.035 10H126C125.867 10 125.74 10.0527 125.646 10.1464C125.553 10.2402 125.5 10.3674 125.5 10.5C125.5 10.6326 125.553 10.7598 125.646 10.8536C125.74 10.9473 125.867 11 126 11H129C129.133 11 129.26 10.9473 129.354 10.8536C129.447 10.7598 129.5 10.6326 129.5 10.5V7.5C129.5 7.36739 129.447 7.24021 129.354 7.14645C129.26 7.05268 129.133 7 129 7Z"
      fill="white"
    />
  </svg>
);

const LogOutBannerLogo = React.memo(LogOutBannerLogoSvg);

export default LogOutBannerLogo;

import * as React from 'react';
import type { SVGProps } from 'react';
const BeneficiaryIconSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    {...props}>
    <path
      d="M9.1665 9.16667H5.1665C4.23308 9.16667 3.76637 9.16667 3.40985 9.34832C3.09625 9.50811 2.84128 9.76308 2.68149 10.0767C2.49984 10.4332 2.49984 10.8999 2.49984 11.8333V17.5M17.4998 17.5V5.16667C17.4998 4.23325 17.4998 3.76654 17.3182 3.41002C17.1584 3.09641 16.9034 2.84144 16.5898 2.68166C16.2333 2.5 15.7666 2.5 14.8332 2.5H11.8332C10.8998 2.5 10.433 2.5 10.0765 2.68166C9.76292 2.84144 9.50795 3.09641 9.34816 3.41002C9.1665 3.76654 9.1665 4.23325 9.1665 5.16667V17.5M18.3332 17.5H1.6665M12.0832 5.83333H14.5832M12.0832 9.16667H14.5832M12.0832 12.5H14.5832"
      stroke="#D0D5DD"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const BeneficiaryIcon = React.memo(BeneficiaryIconSvg);
export default BeneficiaryIcon;

import type { FC, ReactElement } from 'react';
import React from 'react';

type CollapseProps = {
  activeCollapse: boolean;
  children?: ReactElement;
};

const Collapse: FC<CollapseProps> = ({ activeCollapse, children }) => {
  return (
    <div className="relative overflow-hidden">
      <div
        className={`${activeCollapse ? 'h-full' : '-translate-y-full h-0 '} py-2 transition ease-in-out transform overflow-hidden`}>
        {children}
      </div>
    </div>
  );
};

export default Collapse;

import type { FC } from 'react';
import React from 'react';
import type { OnrampPaymentInstitution } from 'services/types';
import { cn } from 'utils';

type PaymentInstitutionCardProps = {
  institution: OnrampPaymentInstitution;
  onClick: (institution: OnrampPaymentInstitution) => void;
  disabled?: boolean;
};

const PaymentInstitutionCard: FC<PaymentInstitutionCardProps> = ({
  institution,
  onClick,
  disabled,
}) => {
  return (
    <div
      className="flex flex-col justify-between mb-5"
      onClick={() => {
        if (disabled) {
          return;
        }
        onClick(institution);
      }}>
      <p className="flex mt-auto pb-2">{institution.full_name}</p>

      <div
        className={cn(
          'flex h-[80px] justify-center items-center bg-bg-secondaryLight dark:bg-bg-secondaryDark rounded-2xl hover:ring-2 ring-primary cursor-pointer',
          {
            'cursor-progress hover:ring-0': disabled,
          },
        )}>
        <img src={institution.media_url} alt="" className="h-14 w-15" />
      </div>
    </div>
  );
};

export default PaymentInstitutionCard;

import type { FC } from 'react';
import React, { useEffect, useState } from 'react';
import { useConvertOnrampAsset, useOnrampPayByCard } from 'services';
import { ONRAMP_USER_SESSION } from 'utils';
import { useLocalStorageState } from 'hooks';
import type { OnrampSessionType, PaymentMethod } from 'services/types';
import { LoadingIcon, NewTabIcon } from 'assets';
import { Button } from 'components';
import { useOnrampInitialData } from 'providers/OnrampDataProvider';

type PayByCardProps = {
  selectedPayment: PaymentMethod | undefined;
};

const PayByCard: FC<PayByCardProps> = ({ selectedPayment }) => {
  const [payByCardUrl, setPayByCardUrl] = useState('');
  const [onrampSession] =
    useLocalStorageState<OnrampSessionType>(ONRAMP_USER_SESSION);
  const { payByCard, isPayingByCard } = useOnrampPayByCard();

  const { onrampData, isLoading, onrampProgress } = useOnrampInitialData();

  const { data: convertData, isLoading: isLoadingConvert } =
    useConvertOnrampAsset({
      amount: onrampData?.data?.source_amount || '',
      from: onrampData?.data?.source_currency || '',
      to: onrampData?.data?.destination_currency || '',
    });

  const paymentSum =
    (convertData?.fees?.[0]?.converted_amount || 0) +
    (convertData?.fees?.[1]?.converted_amount || 0) +
    parseFloat(onrampData?.data?.source_amount || '0');

  useEffect(() => {
    if (onrampProgress?.data?.order_id && selectedPayment?.id) {
      payByCard(
        {
          order_id: onrampProgress?.data?.order_id,
          payment_method_id: selectedPayment?.id,
        },
        {
          onSuccess: (response) => {
            setPayByCardUrl(response.redirect_url);
          },
        },
      );
    }
  }, [
    onrampProgress?.data?.order_id,
    selectedPayment?.id,
    payByCard,
    onrampSession,
  ]);

  if (isPayingByCard) {
    return (
      <div className="w-full h-[590px] outline-none rounded-2xl flex justify-center items-center">
        <LoadingIcon className="min-h-16 min-w-16" />
      </div>
    );
  }

  return (
    <div className="h-[590px] flex justify-center items-center flex-col">
      <div>
        <h2 className="text-center mt-8 mb-2 text-2xl">
          Payment total is{' '}
          <span className="text-primary">
            {isLoadingConvert || isLoading ? (
              <span className="animate-pulse"> ...</span>
            ) : (
              paymentSum.toFixed(2) + ' ' + onrampData?.data?.source_currency
            )}
          </span>{' '}
        </h2>
        <h2 className="text-center mb-8 text-2xl">
          Receive{' '}
          <span className="text-primary">
            {isLoadingConvert || isLoading ? (
              <span className="animate-pulse"> ...</span>
            ) : (
              parseFloat(convertData?.converted_amount || '0')?.toFixed(8) +
              ' ' +
              onrampData?.data?.destination_currency
            )}
          </span>
        </h2>
      </div>

      <Button
        name="Pay by Debit / Credit Card"
        variant="primary"
        onClick={() => window.open(payByCardUrl, '_blank')}
        iconAfter={<NewTabIcon className="h-5 w-5 fill-white" />}
      />
    </div>
  );
};

export default PayByCard;

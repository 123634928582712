import { MerchantLogoDark, MerchantLogoLight } from 'assets';
import type { FC } from 'react';
import { Switcher } from '../DarkModeSwitch';

const InvoiceProcessingHeader: FC = () => {
  return (
    <div className="flex justify-between px-5 md:px-24 lg:px-52">
      <div className="font-poppins text-2xl font-semibold flex gap-2">
        <div>
          <MerchantLogoDark className="hidden dark:inline" />
          <MerchantLogoLight className="inline dark:hidden" />
        </div>
        <div>
          <h3 className="font-bold ">NIZA</h3>
          <p className="font-thin italic text-primary">Merchant</p>
        </div>
      </div>

      <div className="flex flex-col md:flex-row">
        <Switcher className="ml-14 pt-4 md:pt-0" height={30} width={30} />
      </div>
    </div>
  );
};

export default InvoiceProcessingHeader;

import type { FC, ReactElement } from 'react';
import { twMerge } from 'tailwind-merge';
import React from 'react';

export type TagProps = {
  variant: 'success' | 'error' | 'default' | 'warning';
  text?: string;
  iconBefore?: ReactElement;
  iconAfter?: ReactElement;
  className?: string;
};

const variantStyles: Record<TagProps['variant'], string> = {
  success: 'bg-success-50 text-success-700 ',
  error: 'bg-error-50 text-error-700',
  default: 'bg-gray-100 text-gray-700',
  warning: 'bg-warning-50 text-warning-700',
};

const Tag: FC<TagProps> = ({
  variant,
  iconBefore,
  iconAfter,
  text,
  className,
}) => {
  return (
    <div
      className={twMerge(
        'py-0.5 pr-2 pl-1.5 rounded-2xl text-center flex items-center gap-2 w-fit',
        variantStyles[variant],
        className,
      )}>
      {iconBefore && <span className="ml-2">{iconBefore}</span>}
      {text}
      {iconAfter && <span className="ml-2">{iconAfter}</span>}
    </div>
  );
};

export default Tag;

import ButtonLink from './ButtonLink.component';
import {
  DevelopersIcon,
  DocumentIcon,
  HelpIcon,
  SettingsIcon,
  UserLogo,
} from 'assets';
import { SingleSelect, Toast } from 'components';
import { useState, type FC } from 'react';
import { useLocalStorageState } from 'hooks';
import { cn, USER_SESSION } from 'utils';
import type { MerchantAuthStorage, ToastStateProps } from 'services/types';
import { useLogout, useSwitchEnvironment } from 'services';
import {
  removeEncryptedTokenFromStorage,
  setEncryptedTokenToStorage,
} from '../../../services/storage';
import { useMediaQuery } from 'react-responsive';

const MenuItems: FC = () => {
  const [authData, setAuthData] =
    useLocalStorageState<MerchantAuthStorage>(USER_SESSION);
  const { switchEnvironment, isSwitchingEnvironment } = useSwitchEnvironment();
  const { logout } = useLogout();
  const [toastState, setToastState] = useState<ToastStateProps>({
    active: false,
    toastMessage: '',
  });

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 768px)',
  });

  const options = [
    { id: 1, label: 'Live', value: 'live' },
    { id: 2, label: 'Sandbox', value: 'sandbox' },
  ];

  const selectedEnvironment = authData?.environment
    ? {
        label: authData?.environment === 'live' ? 'Live' : 'Sandbox',
        value: authData?.environment,
        id: authData?.environment === 'live' ? 1 : 2,
      }
    : undefined;

  const onEnvironmentSelect = (environment: {
    label: string;
    value: string;
    id: number;
  }) => {
    if (selectedEnvironment?.id !== environment.id) {
      switchEnvironment(
        {},
        {
          onSuccess: (response) => {
            if (response.new_token && response.new_environment) {
              setAuthData({
                ...authData,
                environment: response.new_environment,
              });

              setEncryptedTokenToStorage(response.new_token);

              setToastState({
                active: true,
                toastMessage: response.message,
                variant: 'default',
              });

              setTimeout(() => window.location.reload(), 1000);
            }
          },
          onError: (error) => {
            setToastState({
              active: true,
              toastMessage: error.response?.data.message,
              variant: 'error',
            });
          },
        },
      );
    }
  };

  const handleLogout = () => {
    logout(undefined, {
      onSuccess: (response) => {
        setToastState({
          active: true,
          toastMessage: response.message,
          variant: 'default',
        });

        localStorage.removeItem(USER_SESSION);
        removeEncryptedTokenFromStorage();

        setTimeout(() => window.location.reload(), 1000);
      },

      onError: (error) => {
        setToastState({
          active: true,
          toastMessage: error?.response?.data?.message,
          variant: 'error',
        });

        localStorage.removeItem(USER_SESSION);
        removeEncryptedTokenFromStorage();
        setTimeout(() => window.location.reload(), 1000);
      },
    });
  };

  return (
    <div
      className={cn(
        'flex h-full w-full flex-col justify-between  overflow-y-scroll ',
        {
          'h-[calc(100%-150px)]': isDesktopOrLaptop,
        },
      )}>
      <div>
        <div className="flex flex-col">
          <h2 className="font-medium  text-textMenuGray dark:text-white">
            MENU
          </h2>

          <div className="ml-4 mt-4 flex flex-col gap-4 text-lg">
            <ButtonLink
              icon={<UserLogo className="dark:stroke-white stroke-black" />}
              path=""
              name="Customers"
              key={'First'}
            />
            <ButtonLink
              icon={<DocumentIcon className="dark:stroke-white stroke-black" />}
              path="invoices"
              name="Invoices"
            />
            <ButtonLink
              icon={<DocumentIcon className="dark:stroke-white stroke-black" />}
              path="orders"
              name="Orders"
            />
            <ButtonLink
              icon={
                <DevelopersIcon className="dark:stroke-white stroke-black" />
              }
              path="developers"
              name="Developers"
            />
            <ButtonLink
              icon={<SettingsIcon className="dark:stroke-white stroke-black" />}
              path="settings"
              name="Settings"
            />
          </div>
        </div>
      </div>

      <div>
        <h2 className="mt-5 font-medium  text-textMenuGray dark:text-white">
          SUPPORT
        </h2>
        <div className="ml-4 mt-4 flex flex-col gap-2 text-lg">
          <ButtonLink
            icon={<HelpIcon className="dark:stroke-white stroke-black" />}
            name="Help"
            onClick={() =>
              window.open('https://help.niza.io/hc/en-us', '_blank')
            }
          />
        </div>
      </div>

      <div className="mt-5 left-5 md:left-0 w-full max-w-64 pr-5">
        <div>
          <h2 className="mb-4 font-medium text-textMenuGray dark:text-white">
            ENVIRONMENT
          </h2>

          <SingleSelect
            options={options}
            selected={selectedEnvironment}
            optionClick={onEnvironmentSelect}
            key={'environmentSelect'}
            isLoading={isSwitchingEnvironment}
          />
        </div>
        <div className="mt-8 flex w-full content-center gap-2">
          <UserLogo
            height={42}
            width={42}
            className="self-center rounded-full bg-primary bg-opacity-25 p-2 dark:stroke-white stroke-black"
          />
          <div>
            <h2 className="font-medium text-textMenuGray dark:text-white">
              {authData?.first_name || 'user'}
            </h2>
            <div className="flex flex-col gap-2  text-lg">
              <button
                className="underline focus:text-primary active:text-primary"
                onClick={handleLogout}>
                Log Out
              </button>
            </div>
          </div>
        </div>
      </div>

      {toastState.active && (
        <Toast
          text={toastState?.toastMessage || 'Item copied successfully'}
          onClose={() => setToastState({ active: false, toastMessage: '' })}
          variant={toastState.variant || 'default'}
        />
      )}
    </div>
  );
};

export default MenuItems;

import React, { useState } from 'react';
import { BillingInformation, PaymentProcessor } from './sections';

const InvoiceProcessing = () => {
  const [selectedPayment, setSelectedPayment] = useState<
    | 'pay_by_card'
    | 'pay_by_bank'
    | 'bank_transfer_sepa'
    | 'bank_transfer_swift'
    | undefined
  >();

  return (
    <div className="flex flex-col lg:flex-row min-h-[750px] h-fit gap-4  justify-center overflow-hidden">
      <BillingInformation
        selectedPayment={selectedPayment}
        setSelectedPayment={setSelectedPayment}
      />

      <PaymentProcessor paymentType={selectedPayment} />
    </div>
  );
};

export default InvoiceProcessing;

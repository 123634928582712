import client from '../client';
import type { AxiosError } from 'axios';
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  AuthorizeUser,
  AuthorizeUserResponse,
  BusinessBrandDetailsResponse,
  ChangeBrandDetailsData,
  ChangeBrandDetailsResponse,
  GeneralError,
  LoginResponse,
  SuccessResponse,
  SwitchEnvironmentResponse,
} from '../types';
import { API_ENDPOINTS } from '../client/api-endpoints';

export function useLoginWithNiza(accessToken: unknown) {
  const query = useQuery<LoginResponse, AxiosError<GeneralError>>({
    queryKey: [API_ENDPOINTS.LOGIN_WITH_NIZA],
    queryFn: client.auth.loginWithNiza,
    enabled: !accessToken,
  });

  return query;
}

export function useAuthorizeUser() {
  const { mutate, isPending } = useMutation<
    AuthorizeUserResponse,
    AxiosError<GeneralError>,
    AuthorizeUser
  >({
    mutationFn: client.auth.authorizeUser,
  });

  return {
    authorizeUser: mutate,
    isAuthorizingUser: isPending,
  };
}

export function useGetBusinessBrandDetails() {
  const query = useQuery<
    BusinessBrandDetailsResponse,
    AxiosError<GeneralError>
  >({
    queryKey: [API_ENDPOINTS.GET_BUSINESS_BRAND_DETAILS],
    queryFn: client.user.getBusinessBrandDetails,
  });
  return query;
}

export function useChangeBrandDetails() {
  const { mutate, isPending } = useMutation<
    ChangeBrandDetailsResponse,
    AxiosError<GeneralError>,
    ChangeBrandDetailsData
  >({
    mutationFn: client.user.changeBrandDetails,
  });

  return {
    changeBrandDetails: mutate,
    isChangingBrandDetails: isPending,
  };
}

export function useRemoveBrandLogo() {
  const { mutate: removeBrandLogo, isPending: isRemovingBrandLogo } =
    useMutation<SuccessResponse, AxiosError<GeneralError>>({
      mutationFn: client.user.removeBrandLogo,
    });

  return {
    removeBrandLogo,
    isRemovingBrandLogo,
  };
}

export function useSwitchEnvironment() {
  const { mutate, isPending } = useMutation<
    SwitchEnvironmentResponse,
    AxiosError<GeneralError>,
    unknown
  >({
    mutationFn: client.user.switchUserEnvironment,
  });

  return {
    switchEnvironment: mutate,
    isSwitchingEnvironment: isPending,
  };
}

export function useLogout() {
  const { mutate, isPending } = useMutation<
    SuccessResponse,
    AxiosError<GeneralError>,
    unknown
  >({
    mutationFn: client.auth.logout,
  });

  return {
    logout: mutate,
    isLoginOut: isPending,
  };
}

import type { FC } from 'react';
import React from 'react';

interface MenuDrawerProps {
  children: React.JSX.Element;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const MenuDrawer: FC<MenuDrawerProps> = ({ children, isOpen, setIsOpen }) => {
  return (
    <main
      className={
        'fixed inset-0 z-10 transform overflow-hidden bg-gray-900 bg-opacity-25 ease-in-out ' +
        (isOpen
          ? 'translate-x-0 opacity-100 transition-opacity duration-500'
          : 'translate-x-full opacity-0 transition-all delay-500  ')
      }
      onClick={() => setIsOpen(false)}>
      <section
        className={
          ' bg-white dark:bg-bg-drawerDark text-black dark:text-white delay-400 absolute right-0 h-full w-screen max-w-xs transform shadow-xl transition-all duration-500 ease-in-out  ' +
          (isOpen ? 'translate-x-0 ' : ' translate-x-full ')
        }>
        <article className="relative flex h-full w-screen max-w-lg flex-col overflow-y-scroll pb-10">
          <div className="p-5 h-full">{children}</div>
        </article>
      </section>
      <section
        className=" h-full w-screen cursor-pointer "
        onClick={() => {
          setIsOpen(false);
        }}></section>
    </main>
  );
};

export default MenuDrawer;

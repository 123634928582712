import React from 'react';
import useDarkSide from 'hooks/useDarkSide';
import OnrampHeader from '../Header/OnrampHeader';

export default function SingedInLayout({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) {
  useDarkSide();
  return (
    <div
      lang="en"
      className="dark:bg-bg-secondaryDark bg-bg-secondaryLight dark:text-white pt-5 md:px-6 md:pt-8  lg:px-14 lg:pt-16  flex flex-col w-full overflow-hidden ease-linear transition-all duration-100">
      <OnrampHeader />
      <main className="w-full p-2 mt-5 md:p-8 lg:px-16 lg:py-14 rounded-2xl mb-10 min-h-[100vh]">
        {children}
      </main>
    </div>
  );
}

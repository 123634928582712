import {
  CopyIcon,
  CurlyArrows,
  KeyIcon,
  KeyLockedIcon,
  RefreshIcon,
} from 'assets';
import React, { useState } from 'react';
import { Button, Input, PageHeader, Toast } from 'components';
import {
  useCreateWebhookConnection,
  useGetDeveloperConfigurations,
} from 'services';

const Developers = () => {
  const [newWebHook, setNewWebHook] = useState('');
  const [toastState, setToastState] = useState<{
    active: boolean;
    toastMessage?: string;
  }>({
    active: false,
    toastMessage: '',
  });

  const {
    data: developerConfig,
    isLoading: isLoadingDeveloperConfig,
    refetch,
  } = useGetDeveloperConfigurations();

  const { createWebhookConnection, isCreatingWebhookConnection } =
    useCreateWebhookConnection();

  const handleCopyToClipboard = (text?: string, toastMessage?: string) => {
    if (text) {
      navigator.clipboard.writeText(text);
      setToastState({
        active: true,
        toastMessage: toastMessage,
      });
    }
  };

  const handleWebHookSubmit = () => {
    if (newWebHook) {
      createWebhookConnection(
        { url: newWebHook },
        {
          onSuccess: (response) => {
            if (response.success) {
              refetch();
              setToastState({
                active: true,
                toastMessage: response.message,
              });
            }
          },
          onError: (error) => {
            setToastState({
              active: true,
              toastMessage: error.response?.data.message,
            });
          },
        },
      );
    }
  };

  return (
    <div className="overflow-hidden">
      <PageHeader
        title="Developers"
        // description=""
      />

      <div className="flex-row xl:flex gap-5 mt-12">
        <div className="flex flex-col justify-between border border-gray-300 dark:border-gray-600 w-full xl:w-1/2 rounded-xl bg-white dark:bg-bg-dark p-5 gap-8">
          <div className="flex gap-2">
            <KeyIcon />
            <div>
              <h2>API Key</h2>
              <p className="text-sm text-gray-text dark:text-gray-300">
                An API Key uniquely identifies you as a{' '}
                <span className="text-primary font-semibold">Partner</span>
              </p>
            </div>
          </div>

          <div className="flex mt-4 justify-between gap-2 items-center">
            <Input
              type="text"
              value={developerConfig?.data?.apiKeyConfig?.apiKey || ''}
              className="w-full "
              inputClassName="bg-bg-secondaryLight dark:bg-bg-secondaryDark border-0 text-primary dark:text-primary"
              readOnly={true}
              isLoading={isLoadingDeveloperConfig}
            />
            <Button
              name="Copy"
              variant="secondary"
              iconBefore={
                <CopyIcon className="dark:stroke-white " stroke="#292929" />
              }
              onClick={() =>
                handleCopyToClipboard(
                  developerConfig?.data?.apiKeyConfig?.apiKey || '',
                  'You just copied successfully API Key',
                )
              }
            />
          </div>
        </div>

        <div className="flex flex-col justify-between border border-gray-300 dark:border-gray-600 w-full xl:w-1/2 rounded-xl bg-white dark:bg-bg-dark p-5 gap-8 mt-5 xl:mt-0">
          <div className="flex gap-2">
            <KeyLockedIcon />
            <div>
              <div className="flex items-center gap-2 mb-2">
                <h2>Secret Key</h2>
                {/* <Button
                  name="Refresh"
                  iconAfter={<RefreshIcon />}
                  size="medium"
                  variant="primary"
                /> */}
              </div>
              <p className="text-sm text-gray-text dark:text-gray-300">
                An API Secret is to be used to refresh/generate your{' '}
                <span className="text-primary font-semibold">
                  API Access Token
                </span>
              </p>
            </div>
          </div>

          <div className="flex mt-4 justify-between items-center gap-2">
            <Input
              type="text"
              value={developerConfig?.data?.apiKeyConfig?.secretKey || ''}
              className="w-full "
              inputClassName="bg-bg-secondaryLight dark:bg-bg-secondaryDark border-0 text-primary dark:text-primary"
              readOnly={true}
              isLoading={isLoadingDeveloperConfig}
            />
            <Button
              name="Copy"
              variant="secondary"
              iconBefore={
                <CopyIcon className="dark:stroke-white " stroke="#292929" />
              }
              onClick={() =>
                handleCopyToClipboard(
                  developerConfig?.data?.apiKeyConfig?.secretKey || '',
                  'You just copied successfully Secret Key',
                )
              }
            />
          </div>
        </div>
      </div>

      <div className="flex-row xl:flex gap-5 mt-5">
        <div className="flex flex-col justify-between border border-gray-300 dark:border-gray-600 w-full xl:w-1/2 rounded-xl bg-white dark:bg-bg-dark p-5 gap-8">
          <div className="flex gap-2">
            <KeyLockedIcon />
            <div>
              <h2>Web Hook</h2>
              <p className="text-sm text-gray-text dark:text-gray-300">
                Add your web hook connection
              </p>
            </div>
          </div>

          <div className="flex mt-4 justify-between items-center gap-2">
            <Input
              type="text"
              className="w-full "
              inputClassName="bg-bg-secondaryLight dark:bg-bg-secondaryDark border-0 text-primary dark:text-primary pr-16"
              onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                setNewWebHook(e?.target?.value);
              }}
              value={
                newWebHook || developerConfig?.data?.webhookConfig?.url || ''
              }
              iconEnd={
                <CopyIcon
                  className="dark:stroke-white mt-2 pt-2 cursor-pointer"
                  stroke="#292929"
                  onClick={() =>
                    handleCopyToClipboard(
                      'https.niza.global.com/',
                      'You just copied successfully Web Hook',
                    )
                  }
                />
              }
              isLoading={isLoadingDeveloperConfig}
            />

            <Button
              name="Submit"
              variant="secondary"
              isLoading={isCreatingWebhookConnection}
              onClick={() => handleWebHookSubmit()}
            />
          </div>
        </div>

        <div className="flex flex-col justify-between border border-gray-300 dark:border-gray-600 w-full xl:w-1/2 rounded-xl bg-white dark:bg-bg-dark p-5 gap-8 mt-5 xl:mt-0 overflow-hidden">
          <div className="flex gap-2 ">
            <div>
              <h2>API Documentation</h2>
              <p className="text-sm text-gray-text dark:text-gray-300">
                We have covered everything, to integrate with us please read the
                <span className="text-primary font-semibold">
                  {' '}
                  Documentation.
                </span>
              </p>
            </div>
          </div>

          <div className="flex mt-4 justify-between items-center flex-row-reverse relative">
            <div className="flex">
              <CurlyArrows className="dark:fill-white fill-black absolute right-52 top-3 w-24  sm:w-32 md:w-fit" />
              <Button name="Read Documentation" variant="primary" />
            </div>
          </div>
        </div>
      </div>

      {toastState.active && (
        <Toast
          text={toastState?.toastMessage || 'Item copied successfully'}
          onClose={() => setToastState({ active: false, toastMessage: '' })}
          iconBefore={<CopyIcon className="dark:stroke-black" stroke="white" />}
          variant="default"
        />
      )}
    </div>
  );
};

export default Developers;

import type { FC } from 'react';
import React, { useEffect, useState } from 'react';
import type {
  OnrampSessionType,
  PaymentMethod,
  ToastStateProps,
} from 'services/types';
import { ONRAMP_USER_SESSION, cn, getUrlParams } from 'utils';
import OnRampCardLayout from '../layout/OnRampCardLayout';
import OnrampPaymentProcessor from './OnrampPaymentProcessor';
import OnrampSelectPaymentMethod from './OnrampSelectPaymentMethod';
import {
  useCreateDraftOrder,
  useGetOnrampProgress,
  useUpdateOnrampProgress,
} from 'services';
import { useLocalStorageState } from 'hooks';
import OnrampSandboxPaymentProcessor from './OnrampSandboxPaymentProcessor';
import { LoadingIcon } from 'assets';
import { useOnrampInitialData } from 'providers/OnrampDataProvider';

type PayStepProps = {
  setUrlStep: (
    s: React.SetStateAction<
      Partial<{
        step?: any;
      }>
    >,
  ) => void;
  setToastState: React.Dispatch<React.SetStateAction<ToastStateProps>>;
};

const PayStep: FC<PayStepProps> = ({ setToastState, setUrlStep }) => {
  const [onrampSession, setOnrampSession] =
    useLocalStorageState<OnrampSessionType>(ONRAMP_USER_SESSION);

  const initialData = getUrlParams('data');
  const liveMode = getUrlParams('livemode');
  const { onrampProgress, setOnrampProgress, onrampProgressFetched } =
    useOnrampInitialData();
  const { updateOnrampProgress } = useUpdateOnrampProgress();
  const { getOnrampProgress } = useGetOnrampProgress();

  const { createDraftOrder, isCreatingDraftOrder } = useCreateDraftOrder();

  const [selectedPayment, setSelectedPayment] = useState<
    PaymentMethod | undefined
  >();

  useEffect(() => {
    if (
      !!initialData &&
      !onrampProgress?.data?.order_id &&
      onrampProgressFetched
    ) {
      createDraftOrder(
        {
          initial_data: initialData,
        },
        {
          onSuccess: (response) => {
            setOnrampSession({
              ...onrampSession,
              daftOrderId: response.draftOrder.id,
              draftEmail: onrampSession?.email,
              data: initialData,
            });

            updateOnrampProgress(
              {
                on_ramp_data: initialData,
                order_id: response.draftOrder.id,
                payment_method: null,
              },
              {
                onSuccess: () => {
                  getOnrampProgress(
                    {
                      on_ramp_data: initialData,
                    },
                    {
                      onSuccess: (response) => {
                        if (setOnrampProgress) {
                          setOnrampProgress({
                            ...response,
                            data: {
                              ...response.data,
                              payment_method: response.data.payment_method
                                ? JSON.parse(response.data.payment_method)
                                : null,
                            },
                          });
                        }
                      },
                    },
                  );
                },
              },
            );

            setToastState({
              active: true,
              toastMessage: response.message,
              variant: 'default',
            });
          },
          onError: (error) => {
            setToastState({
              active: true,
              toastMessage: error.response?.data.message,
            });
          },
        },
      );
    }
  }, [
    createDraftOrder,
    getOnrampProgress,
    initialData,
    onrampProgress?.data?.order_id,
    onrampProgressFetched,
    onrampSession,
    setOnrampProgress,
    setOnrampSession,
    setToastState,
    updateOnrampProgress,
  ]);

  // useEffect(() => {
  //   if (onrampSession?.daftOrderId && initialData) {
  //     updateOnrampProgress({
  //       on_ramp_data: initialData,
  //       order_id: onrampSession?.daftOrderId,
  //       payment_method: null,
  //     });
  //   }
  // }, [onrampSession?.daftOrderId, initialData, updateOnrampProgress]);

  return (
    <div className="flex w-full justify-center gap-5 ">
      <OnRampCardLayout
        setStep={() => {
          if (selectedPayment) {
            if (
              initialData &&
              onrampProgress?.data?.order_id &&
              setOnrampProgress
            ) {
              updateOnrampProgress(
                {
                  on_ramp_data: initialData,
                  order_id: onrampProgress?.data?.order_id,
                  payment_method: null,
                },
                {
                  onSuccess: () => {
                    setOnrampProgress({
                      ...onrampProgress,
                      data: {
                        ...onrampProgress?.data,
                        payment_method: null,
                      },
                    });
                    setSelectedPayment(undefined);
                  },
                },
              );
            } else {
              setSelectedPayment(undefined);
            }
          } else {
            setUrlStep({ step: 'user-details' });
          }
        }}
        className="min-h-[730px] "
        childrenClassName="h-full">
        <div className="flex flex-col justify-between  h-full">
          <div
            className={cn('ease-linear transition-transform duration-300 ', {
              'opacity-0 w-0 -translate-x-full h-0 hidden': !!selectedPayment,
              'opacity-100': !selectedPayment,
            })}>
            <OnrampSelectPaymentMethod
              selectedPayment={selectedPayment}
              setSelectedPayment={setSelectedPayment}
              isCreatingDraftOrder={
                isCreatingDraftOrder && !onrampSession?.daftOrderId
              }
            />
          </div>

          <div
            className={cn('ease-linear transition-all duration-300 mt-10', {
              'opacity-0 w-0 translate-x-full h-0 duration-0': !selectedPayment,
              'opacity-100 min-h-96': !!selectedPayment,
            })}>
            {liveMode === 'true' ? (
              <OnrampPaymentProcessor
                setSelectedPayment={setSelectedPayment}
                selectedPayment={selectedPayment}
              />
            ) : (
              <OnrampSandboxPaymentProcessor onrampSession={onrampSession} />
            )}
          </div>

          <div className="flex justify-center">
            {isCreatingDraftOrder ? (
              <div className="flex items-center">
                <LoadingIcon />
                <p>Creating draft order</p>
              </div>
            ) : null}
          </div>
        </div>
      </OnRampCardLayout>
    </div>
  );
};

export default PayStep;

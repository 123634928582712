import { Formik } from 'formik';
import { useEncryptedLocalStorageState } from 'hooks';
import { useOnrampInitialData } from 'providers/OnrampDataProvider';
import type { FC } from 'react';
import React, { useEffect, useMemo } from 'react';
import {
  useGetAllCountries,
  useGetOnrampCustomerDetails,
  useGetSumsubApplicantStatus,
  useUpdateOnrampCustomerDetails,
} from 'services';
import type { ToastStateProps } from 'services/types';
import {
  ENCRYPTED_ONRAMP_SESSION_TOKEN,
  OnrampUserDataUpdateSchema,
} from 'utils';
import { Button } from '../../Button';
import {
  Input,
  InputError,
  Label,
  ReactSelectComponent,
} from '../../FormComponents';
import DateInput from '../../FormComponents/DateInput/DateInput';
import OnRampCardLayout from '../layout/OnRampCardLayout';

type UserDataStepProps = {
  setUrlStep: (
    s: React.SetStateAction<
      Partial<{
        step?: any;
      }>
    >,
  ) => void;
  setToastState: React.Dispatch<React.SetStateAction<ToastStateProps>>;
};

const UserDataStep: FC<UserDataStepProps> = ({ setUrlStep, setToastState }) => {
  const { onrampProgress } = useOnrampInitialData();

  const [onrampSessionToken] = useEncryptedLocalStorageState(
    ENCRYPTED_ONRAMP_SESSION_TOKEN,
  );

  const {
    data: userDetails,
    refetch,
    isLoading,
  } = useGetOnrampCustomerDetails();

  // const { data: clientCountry } = useGetCountry({
  //   countryId: userDetails?.data?.country,
  // });

  const { data: allCountries, isLoading: isLoadingCountries } =
    useGetAllCountries();

  const {
    data: userVerificationStatus,
    isLoading: isLoadingUserVerificationStatus,
  } = useGetSumsubApplicantStatus();

  useEffect(() => {
    if (onrampProgress?.data.payment_method) {
      setUrlStep({
        step: 'pay',
      });
    }
  }, [onrampProgress?.data.payment_method, setUrlStep]);

  const selectedCountry = useMemo(() => {
    const findSelectedCountry = allCountries?.find(
      (country) => country.iso_3166_2 === userDetails?.data?.country,
    );

    return {
      id: findSelectedCountry?.id ?? '',
      label: findSelectedCountry?.name ?? '',
      value: findSelectedCountry?.iso_3166_2 ?? '',
    };
  }, [allCountries, userDetails?.data?.country]);

  const memoCountriesList = useMemo<
    { id: string; label: string; value: string }[] | undefined
  >(
    () =>
      allCountries?.map((country) => ({
        id: country?.id?.toString(),
        label: country.name,
        value: country.iso_3166_2,
      })),
    [allCountries],
  );

  const initialValues = useMemo(() => {
    if (userDetails && userDetails.data) {
      const {
        first_name,
        last_name,
        address_line1,
        address_line2,
        city,
        state,
        postal_code,
        dob,
      } = userDetails.data;
      return {
        first_name: first_name ?? '',
        last_name: last_name ?? '',
        address_line1: address_line1 ?? '',
        address_line2: address_line2 ?? '',
        country: selectedCountry ?? '',
        city: city ?? '',
        state: state ?? '',
        postal_code: postal_code ?? '',
        dob: dob ?? '',
      };
    }
    return {
      first_name: '',
      last_name: '',
      address_line1: '',
      address_line2: '',
      country: {
        id: '',
        label: '',
        value: '',
      },
      city: '',
      state: '',
      postal_code: '',
      dob: '',
    };
  }, [userDetails, selectedCountry]);

  const { isUpdatingCustomerDetails, updateCustomerDetails } =
    useUpdateOnrampCustomerDetails();

  const handleContinueButton = () => {
    if (userVerificationStatus?.isVerified) {
      setUrlStep({ step: 'pay' });
    } else {
      setUrlStep({ step: 'kyc-verification' });
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={OnrampUserDataUpdateSchema}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        updateCustomerDetails(
          {
            ...values,
            country: values.country.value,
          },
          {
            onSuccess: (response) => {
              setToastState({
                active: true,
                toastMessage: response.message ?? 'Updated',
                variant: 'default',
              });
              setSubmitting(false);
              refetch().then(() => resetForm());
            },
            onError: (error) => {
              setSubmitting(false);
              setToastState({
                active: true,
                toastMessage: error.response?.data.message ?? 'Error updating',
                variant: 'error',
              });
            },
          },
        );
      }}>
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        dirty,
        handleReset,
        initialValues,
      }) => (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
          className="w-full flex justify-center">
          <OnRampCardLayout
            setStep={() => {
              if (onrampSessionToken) {
                setUrlStep({ step: undefined });
              } else {
                setUrlStep({
                  step: 'verify',
                });
              }
            }}
            className="min-h-[712px]">
            <div className="flex flex-col justify-between h-full">
              <div>
                <div className="text-center border-b pb-3">
                  <h1 className="text-xl md:text-2xl mx-5">Personal details</h1>
                </div>

                <div className="mt-5 flex flex-col gap-4">
                  <div className="flex gap-2  w-full">
                    <div className="flex flex-col   w-full">
                      <Label text="First Name" className="mb-2" />
                      <Input
                        type="text"
                        name="first_name"
                        onChange={handleChange}
                        handleBlur={handleBlur}
                        value={values.first_name}
                        placeholder="e.g. John"
                        isLoading={isLoading}
                      />
                      {errors.first_name && touched.first_name ? (
                        <InputError
                          errorMessage={errors.first_name}
                          className="pt-2 ml-2 md:ml-2"
                        />
                      ) : null}
                    </div>
                    <div className="flex flex-col  w-full">
                      <Label text="Last Name" className="mb-2" />
                      <Input
                        type="text"
                        name="last_name"
                        onChange={handleChange}
                        handleBlur={handleBlur}
                        value={values.last_name}
                        placeholder="e.g. Doe"
                        isLoading={isLoading}
                      />
                      {errors.last_name && touched.last_name ? (
                        <InputError
                          errorMessage={errors.last_name}
                          className="pt-2 ml-2 md:ml-2"
                        />
                      ) : null}
                    </div>
                  </div>

                  <div>
                    <Label text="Date of birth" className="mb-2" />

                    <DateInput
                      handleBlur={handleBlur}
                      onChange={handleChange}
                      value={values.dob}
                      setFieldValue={setFieldValue}
                      isLoading={isLoading}
                    />
                    {/* 
                    <Input
                      type="date"
                      name="dob"
                      onChange={handleChange}
                      handleBlur={handleBlur}
                      value={values.dob}
                      isLoading={isLoading}
                    /> */}
                    {errors.dob && touched.dob ? (
                      <InputError
                        errorMessage={errors.dob}
                        className="pt-2 ml-2 md:ml-2"
                      />
                    ) : null}
                  </div>

                  <div>
                    <Label text="Address line 1" className="mb-2" />
                    <Input
                      type="text"
                      name="address_line1"
                      onChange={handleChange}
                      handleBlur={handleBlur}
                      value={values.address_line1}
                      isLoading={isLoading}
                    />
                    {errors.address_line1 && touched.address_line1 ? (
                      <InputError
                        errorMessage={errors.address_line1}
                        className="pt-2 ml-2 md:ml-2"
                      />
                    ) : null}
                  </div>

                  <div>
                    <Label text="Address line 2" className="mb-2" />
                    <Input
                      type="text"
                      name="address_line2"
                      onChange={handleChange}
                      handleBlur={handleBlur}
                      value={values.address_line2}
                      isLoading={isLoading}
                    />
                    {errors.address_line2 && touched.address_line2 ? (
                      <InputError
                        errorMessage={errors.address_line2}
                        className="pt-2 ml-2 md:ml-2"
                      />
                    ) : null}
                  </div>
                  <div className="flex gap-2 w-full">
                    <div className="w-full">
                      <Label text="State" className="mb-2" />
                      <Input
                        type="text"
                        name="state"
                        onChange={handleChange}
                        handleBlur={handleBlur}
                        value={values.state}
                        isLoading={isLoading}
                      />
                      {errors.state && touched.state ? (
                        <InputError
                          errorMessage={errors.state}
                          className="pt-2 ml-2 md:ml-2"
                        />
                      ) : null}
                    </div>
                    <div className="w-full">
                      <Label text="City" className="mb-2" />
                      <Input
                        type="text"
                        name="city"
                        onChange={handleChange}
                        handleBlur={handleBlur}
                        value={values.city}
                        isLoading={isLoading}
                      />
                      {errors.city && touched.city ? (
                        <InputError
                          errorMessage={errors.city}
                          className="pt-2 ml-2 md:ml-2"
                        />
                      ) : null}
                    </div>

                    <div className="w-full">
                      <Label text="Country" className="mb-2" />
                      <ReactSelectComponent
                        name="country"
                        onClick={(country) => {
                          setFieldValue('country', country);
                        }}
                        options={memoCountriesList}
                        initialOption={selectedCountry}
                        value={values?.country}
                        isLoading={isLoadingCountries || isLoading}
                      />
                      {errors.country && touched.country ? (
                        <InputError
                          errorMessage={errors.country.value}
                          className="pt-2 ml-2 md:ml-2"
                        />
                      ) : null}
                    </div>
                  </div>

                  <div>
                    <Label text="Postal code" className="mb-2" />
                    <Input
                      type="text"
                      name="postal_code"
                      onChange={handleChange}
                      handleBlur={handleBlur}
                      value={values.postal_code}
                      isLoading={isLoading}
                    />
                    {errors.postal_code && touched.postal_code ? (
                      <InputError
                        errorMessage={errors.postal_code}
                        className="pt-2 ml-2 md:ml-2"
                      />
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="mt-5 text-center">
                {dirty || !initialValues.first_name ? (
                  <div className="flex gap-4">
                    <Button
                      type="submit"
                      name="Save"
                      variant="primary"
                      className="w-full mt-2"
                      isLoading={
                        isUpdatingCustomerDetails || isSubmitting || isLoading
                      }
                      disabled={isLoading}
                    />

                    <Button
                      type="reset"
                      name="Cancel"
                      variant="secondary"
                      className="w-full mt-2"
                      onClick={() => handleReset()}
                    />
                  </div>
                ) : (
                  <Button
                    name="Continue"
                    variant="primary"
                    className="w-full mt-2"
                    disabled={isLoadingUserVerificationStatus}
                    onClick={() => {
                      handleContinueButton();
                    }}
                  />
                )}
              </div>
            </div>
          </OnRampCardLayout>
        </form>
      )}
    </Formik>
  );
};

export default UserDataStep;

import { Formik } from 'formik';
import type { FC } from 'react';
import React from 'react';
import { Button, Input, InputError, Label, Modal } from 'components';
import { NotVerifiedIcon, UserLogo, VerifiedIcon } from 'assets';
import { CreateCustomerSchema } from 'utils';
import {
  queryClient,
  useChangeCustomerDetails,
  useStoreNewCustomer,
} from 'services';
import { API_ENDPOINTS } from '../services/client/api-endpoints';
import type { Customer, ToastStateProps } from 'services/types';
import { useDateFormatter } from '../hooks';

type AddCustomerFromProps = {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  initialValues?: Customer;
  setToastState: React.Dispatch<React.SetStateAction<ToastStateProps>>;
  infoMode?: boolean;
};

const AddCustomerForm: FC<AddCustomerFromProps> = ({
  setShowModal,
  showModal,
  initialValues,
  setToastState,
  infoMode,
}) => {
  const isEditing = !!initialValues?.id;
  const { storeNewCustomer, isStoringNewCustomer } = useStoreNewCustomer();
  const { dateFormatter } = useDateFormatter();

  const { changeCustomerDetails, isChangingCustomerDetails } =
    useChangeCustomerDetails({ id: initialValues?.id || '' });

  const modalHeader = (
    <div className="flex flex-col items-center pb-5 border-b border-solid dark:border-gray-600 border-gray-300 rounded-t ">
      <div className="border p-3 rounded-xl w-fit">
        <UserLogo className="dark:stroke-white stroke-black" />
      </div>

      <h3 className="text-lg font-semibold mt-4">
        {infoMode
          ? 'Customer details'
          : isEditing
            ? 'Edit customer'
            : 'Add customer'}
      </h3>
      <p className="text-gray-600 dark:text-gray-300 mx-5">
        {infoMode
          ? ''
          : isEditing
            ? 'Edit customer information.'
            : ' Add new customers for free in less than 5 min.'}
      </p>
    </div>
  );
  return (
    <div>
      <Formik
        initialValues={
          initialValues || {
            first_name: '',
            last_name: '',
            email: '',
            kyc_status: '',
            mobile_number: '',
            created_at: '',
            city: '',
            dob: '',
            kyc_data: {
              client_comment: '',
              completed_at: '',
              moderation_comment: '',
              reject_labels: null,
              reject_type: null,
              review_answer: null,
            },
          }
        }
        onSubmit={(values, { setSubmitting }) => {
          if (isEditing) {
            changeCustomerDetails(
              {
                email: values.email,
                first_name: values.first_name,
                last_name: values.last_name,
              },
              {
                onSuccess: (response) => {
                  queryClient.invalidateQueries({
                    queryKey: [API_ENDPOINTS.GET_PAGINATED_CUSTOMERS()],
                    exact: false,
                  });
                  setToastState({
                    active: true,
                    toastMessage: response.message,
                    variant: 'default',
                  });
                  setTimeout(() => {
                    setSubmitting(false);
                    setShowModal(false);
                  }, 400);
                },

                onError: (error) => {
                  setToastState({
                    active: true,
                    toastMessage: error.response?.data.message,
                    variant: 'error',
                  });
                  setTimeout(() => {
                    setSubmitting(false);
                    setShowModal(false);
                  }, 400);
                },
              },
            );
          } else {
            storeNewCustomer(values, {
              onSuccess: (response) => {
                queryClient.invalidateQueries({
                  queryKey: [API_ENDPOINTS.GET_PAGINATED_CUSTOMERS()],
                  exact: false,
                });
                setToastState({
                  active: true,
                  toastMessage: response.message,
                  variant: 'default',
                });
                setTimeout(() => {
                  setSubmitting(false);
                  setShowModal(false);
                }, 400);
              },
              onError: (error) => {
                setToastState({
                  active: true,
                  toastMessage: error.response?.data.message,
                  variant: 'error',
                });
                setSubmitting(false);
                setShowModal(false);
              },
            });
          }
        }}
        validationSchema={CreateCustomerSchema}>
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit} id="addCustomerForm">
            <Modal
              onClose={() => setShowModal(false)}
              showModal={showModal}
              header={modalHeader}
              footer={
                infoMode ? undefined : (
                  <div className="flex items-center justify-center p-4 border-t border-solid dark:border-gray-600 border-gray-300 rounded-b">
                    <Button
                      name={
                        initialValues?.id ? 'Edit customer' : 'Add customer'
                      }
                      type="submit"
                      isLoading={
                        isSubmitting ||
                        isChangingCustomerDetails ||
                        isStoringNewCustomer
                      }
                      variant="primary"
                    />
                  </div>
                )
              }
              className="inset-0 h-[100vh]">
              <div className="max-h-[50vh] min-w-[300px] md:min-w-[500px]">
                <div className="flex md:flex-row flex-col gap-5 items-start md:items-center ">
                  <Label text="First name" className="w-36" />
                  <div className="w-full">
                    {infoMode ? (
                      <div>{values.first_name}</div>
                    ) : (
                      <Input
                        type="text"
                        onChange={handleChange}
                        handleBlur={handleBlur}
                        value={values.first_name}
                        name="first_name"
                        placeholder="e.g. John"
                      />
                    )}
                  </div>
                </div>
                {errors.first_name && touched.first_name ? (
                  <InputError
                    errorMessage={errors.first_name}
                    className="ml-2 md:ml-32 pt-2 "
                  />
                ) : null}
                <div className="flex mt-4 md:flex-row flex-col gap-5 items-start md:items-center">
                  <Label text="Last name" className="w-36 " />
                  <div className="w-full">
                    {infoMode ? (
                      <div>{values.last_name}</div>
                    ) : (
                      <Input
                        type="text"
                        className="w-full"
                        onChange={handleChange}
                        handleBlur={handleBlur}
                        value={values.last_name}
                        name="last_name"
                        placeholder="e.g. Doe"
                      />
                    )}
                  </div>
                </div>
                {errors.last_name && touched.last_name ? (
                  <InputError
                    errorMessage={errors.last_name}
                    className="ml-2 md:ml-32 pt-2 "
                  />
                ) : null}

                <div className="flex mt-4 md:flex-row flex-col gap-5 items-start md:items-center">
                  <Label text="Email" className="w-36 " />
                  <div className="w-full">
                    {infoMode ? (
                      <div>{values.email}</div>
                    ) : (
                      <Input
                        type="email"
                        className="w-full"
                        onChange={handleChange}
                        handleBlur={handleBlur}
                        value={values.email}
                        name="email"
                        placeholder="e.g. johndoe@gmail.com"
                      />
                    )}
                  </div>
                </div>
                {errors.email && touched.email ? (
                  <InputError
                    errorMessage={errors.email}
                    className="ml-2 md:ml-32 pt-2 "
                  />
                ) : null}

                <div className="flex mt-4 md:flex-row flex-col gap-5 items-start md:items-center">
                  <Label text="Verified" className="w-36 " />
                  <div className="w-full">
                    {infoMode &&
                      (values.kyc_status === 'completed' &&
                      values?.kyc_data.review_answer === 'GREEN' ? (
                        <VerifiedIcon className="fill-black dark:fill-white" />
                      ) : (
                        <NotVerifiedIcon className="fill-black dark:fill-white" />
                      ))}
                  </div>
                </div>

                <div className="flex mt-4 md:flex-row flex-col gap-5 items-start md:items-center">
                  <Label text="Mobile number" className="w-36 " />
                  <div className="w-full">
                    {infoMode && <div>{values.mobile_number ?? '--'}</div>}
                  </div>
                </div>

                <div className="flex mt-4 md:flex-row flex-col gap-5 items-start md:items-center">
                  <Label text="Date of birth" className="w-36 " />
                  <div className="w-full">
                    {infoMode && <div>{values.dob ?? '--'}</div>}
                  </div>
                </div>

                <div className="flex mt-4 md:flex-row flex-col gap-5 items-start md:items-center">
                  <Label text="City" className="w-36 " />
                  <div className="w-full">
                    {infoMode && <div>{values.city ?? '--'}</div>}
                  </div>
                </div>

                <div className="flex mt-4 md:flex-row flex-col gap-5 items-start md:items-center">
                  <Label text="Created at" className="w-36 " />
                  <div className="w-full">
                    {infoMode && (
                      <div>
                        {dateFormatter({ date: values.created_at }) ?? '--'}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Modal>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default AddCustomerForm;

import { DownArrow, LoadingIcon } from 'assets';
import type { FC } from 'react';
import React, { useEffect, useRef, useState } from 'react';

type SingleSelectProps = {
  options?: {
    id: number;
    label: string;
    value: string;
  }[];
  setSelected?: React.Dispatch<
    React.SetStateAction<
      | {
          id: number;
          value: string;
          label: string;
        }
      | undefined
    >
  >;
  optionClick?: (option: { label: string; value: string; id: number }) => void;
  selected?:
    | {
        id: number;
        value: string;
        label: string;
      }
    | undefined;

  className?: string;
  dropDownClassName?: string;
  disabled?: boolean;
  isLoading?: boolean;
  label?: string;
};

const SingleSelect: FC<SingleSelectProps> = ({
  options,
  setSelected,
  selected,
  className,
  disabled,
  optionClick,
  isLoading,
  dropDownClassName,
  label,
}) => {
  const [activeDropdown, setActiveDropdown] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setActiveDropdown(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className={`relative  ${className}`}>
      <button
        id="dropdownDefaultButton"
        onClick={(e) => {
          e.stopPropagation();
          setActiveDropdown(!activeDropdown);
        }}
        className=" w-full bg-transparent border border-gray-300 dark:border-gray-600 focus:ring-2 focus:outline-none focus:ring-primary focus:border-primary font-medium rounded-lg text-sm px-5 py-3 text-center inline-flex items-center justify-between"
        type="button"
        disabled={disabled}>
        {selected?.label ? (
          selected.label
        ) : (
          <span className=" text-gray-400">{label || 'Select'}</span>
        )}{' '}
        {isLoading ? <LoadingIcon /> : <DownArrow />}
      </button>
      <div
        id="dropdown"
        ref={dropdownRef}
        className={`z-10 top-14 left-0 w-full  ${activeDropdown ? 'inline' : 'hidden'} absolute bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700  overflow-hidden overflow-y-auto ${dropDownClassName}`}>
        {options?.length ? (
          <ul
            className="text-sm text-gray-700 dark:text-gray-200"
            aria-labelledby="dropdownDefaultButton"
            onClick={() => setActiveDropdown(!activeDropdown)}>
            {options?.map((option) => (
              <li key={option.id}>
                <button
                  onClick={() => {
                    if (optionClick) {
                      optionClick(option);
                    } else if (setSelected) {
                      setSelected(option);
                    }
                  }}
                  type="button"
                  className={`block w-full text-left px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white ${option.id === selected?.id && 'bg-primary bg-opacity-15'}`}>
                  {option.label}
                </button>
              </li>
            ))}
          </ul>
        ) : (
          <div
            className="text-sm px-4 py-2 text-gray-700 dark:text-gray-200"
            onClick={() => setActiveDropdown(!activeDropdown)}>
            No options available
          </div>
        )}
      </div>
    </div>
  );
};

export default SingleSelect;

import type { FC } from 'react';
import React from 'react';
import { cn } from 'utils';

type SkeletonProps = {
  className?: string;
};

const Skeleton: FC<SkeletonProps> = ({ className }) => {
  return (
    <div role="status" className="w-full animate-pulse">
      <div
        className={cn(
          'bg-gray-200 rounded-full dark:bg-gray-700',
          className,
        )}></div>
    </div>
  );
};

export default Skeleton;

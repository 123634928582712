import { LoadingIcon, PaymentRejectedIcon } from 'assets';
import { getUrlParams } from 'utils';
import { useRejectOrderByCustomer } from 'services';

const RejectOrder = () => {
  const token = getUrlParams('token');

  const { data: confirmOrderData, isLoading } = useRejectOrderByCustomer(token);

  if (!isLoading) {
    return (
      <div className="flex flex-col justify-center items-center h-screen text-center gap-2 bg-white text-black ">
        <PaymentRejectedIcon />
        <p className="text-2xl">Order canceled</p>
        <p>Process failed</p>
      </div>
    );
  }
  return (
    <div className="flex flex-col justify-center items-center h-screen text-center gap-2 bg-white text-black ">
      <LoadingIcon className="h-[100px] w-[100px] " />
    </div>
  );
};

export default RejectOrder;

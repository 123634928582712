import { CheckMark } from 'assets';
import type { FC } from 'react';
import type { PaymentMethod } from 'services/types';
import { cn, getAssetImageUri } from 'utils';

export type OnrampPaymentMethodItemProps = {
  methodLogo: string;
  name?: string;
  onClick?: () => void;
  value: string;
  selectedPayment: PaymentMethod | undefined;
  className?: string;
};

const OnrampPaymentMethodItem: FC<OnrampPaymentMethodItemProps> = ({
  methodLogo,
  name,
  onClick,
  value,
  selectedPayment,
  className,
}) => {
  return (
    <div
      className={cn(
        'flex justify-between py-[13px] px-[16px] items-center border rounded-[10px] my-5 cursor-pointer hover:bg-primary hover:bg-opacity-5 border-gray-300  dark:border-gray-600 ',
        {
          'bg-primary bg-opacity-10 border-primary':
            selectedPayment?.provider === value,
        },
        className,
      )}
      onClick={onClick}>
      <div className="flex items-center gap-2 font-medium">
        <img
          src={getAssetImageUri({ asset_id: methodLogo, paymentImages: true })}
          className="w-[60px] h-[60px] rounded-2xl"
          alt=""
        />{' '}
        {name}
      </div>
      <div
        className={`p-1 rounded-full ${selectedPayment === name && 'bg-primary'} `}>
        <CheckMark
          className={`h-4 w-4  ${selectedPayment?.provider === value && 'stroke-white'}`}
          stroke="fill"
        />
      </div>
    </div>
  );
};

export default OnrampPaymentMethodItem;

import * as React from 'react';
import type { SVGProps } from 'react';
const KeyLockedIconSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={52}
    height={53}
    viewBox="0 0 52 53"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <g filter="url(#filter0_d_141_9946)">
      <rect x={2} y={1.5} width={48} height={48} rx={12} fill="white" />
      <rect x={2.5} y={2} width={47} height={47} rx={11.5} stroke="#EAECF0" />
      <rect
        width={24}
        height={24}
        rx={5}
        transform="matrix(1 0 0 -1 14 37.5)"
        stroke="#1DA069"
        strokeWidth={1.5}
      />
      <path
        d="M32.6031 24.9L24.2031 24.9"
        stroke="black"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.6016 27.3L32.6016 24.9"
        stroke="black"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.6016 27.3L29.6016 24.9"
        stroke="black"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx={21.8031}
        cy={24.9}
        r={2.4}
        transform="rotate(-180 21.8031 24.9)"
        stroke="black"
        strokeWidth={1.5}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.5 34V33C34.5 31.6193 35.6193 30.5 37 30.5C38.3807 30.5 39.5 31.6193 39.5 33V34H40.5C40.7761 34 41 34.2239 41 34.5V39C41 39.8284 40.3284 40.5 39.5 40.5H34.5C33.6716 40.5 33 39.8284 33 39V34.5C33 34.2239 33.2239 34 33.5 34H34.5ZM35.5 33C35.5 32.1716 36.1716 31.5 37 31.5C37.8284 31.5 38.5 32.1716 38.5 33V34H35.5V33ZM37.5 37.25C37.5 36.8358 37.8358 36.5 38.25 36.5H38.255C38.6692 36.5 39.005 36.8358 39.005 37.25V37.255C39.005 37.6692 38.6692 38.005 38.255 38.005H38.25C37.8358 38.005 37.5 37.6692 37.5 37.255V37.25Z"
        fill="#292929"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_141_9946"
        x={0}
        y={0.5}
        width={52}
        height={52}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB">
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={1} />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_141_9946"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_141_9946"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

const KeyLockedIcon = React.memo(KeyLockedIconSvg);

export default KeyLockedIcon;

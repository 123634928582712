import * as React from 'react';
import type { SVGProps } from 'react';
const Building06IconSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={18}
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M9.16797 8.16667H5.16797C4.23455 8.16667 3.76784 8.16667 3.41132 8.34832C3.09771 8.50811 2.84275 8.76308 2.68296 9.07668C2.5013 9.4332 2.5013 9.89991 2.5013 10.8333V16.5M17.5013 16.5V4.16667C17.5013 3.23325 17.5013 2.76654 17.3196 2.41002C17.1599 2.09641 16.9049 1.84144 16.5913 1.68166C16.2348 1.5 15.7681 1.5 14.8346 1.5H11.8346C10.9012 1.5 10.4345 1.5 10.078 1.68166C9.76438 1.84144 9.50941 2.09641 9.34963 2.41002C9.16797 2.76654 9.16797 3.23325 9.16797 4.16667V16.5M18.3346 16.5H1.66797M12.0846 4.83333H14.5846M12.0846 8.16667H14.5846M12.0846 11.5H14.5846"
      stroke="#1DA069"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const Building06Icon = React.memo(Building06IconSvg);
export default Building06Icon;

import * as React from 'react';
import type { SVGProps } from 'react';
const XIconSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={14}
    fill="none"
    {...props}>
    <path
      stroke="fill"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={props.strokeWidth ?? 2}
      d="M13 1 1 13M1 1l12 12"
    />
  </svg>
);

const XIcon = React.memo(XIconSvg);
export default XIcon;

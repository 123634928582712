import * as React from 'react';
import type { SVGProps } from 'react';
const XTagIconSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={8}
    height={8}
    viewBox="0 0 8 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M6.5 1.5L1.5 6.5M1.5 1.5L6.5 6.5"
      stroke="#F04438"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const XTagIcon = React.memo(XTagIconSvg);
export default XTagIcon;

import type { FC, ReactElement } from 'react';
import React from 'react';

type BankInfoItemProps = {
  icon: ReactElement;
  title: string;
  description?: string;
  className?: string;
};

const BankInfoItem: FC<BankInfoItemProps> = ({
  icon,
  title,
  description,
  className,
}) => {
  return (
    <div className={`flex p-[10px] gap-[10px] items-center ${className}`}>
      {icon}
      <p className="text-sm font-medium">{title}</p>
      <p className="font-medium">{description}</p>
    </div>
  );
};

export default BankInfoItem;

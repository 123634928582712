import * as React from 'react';
import type { SVGProps } from 'react';
const FileO2IconSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={20}
    viewBox="0 0 16 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M9.66536 9.16669H4.66536M6.33203 12.5H4.66536M11.332 5.83335H4.66536M14.6654 5.66669V14.3334C14.6654 15.7335 14.6654 16.4336 14.3929 16.9683C14.1532 17.4387 13.7707 17.8212 13.3003 18.0609C12.7656 18.3334 12.0655 18.3334 10.6654 18.3334H5.33203C3.9319 18.3334 3.23183 18.3334 2.69705 18.0609C2.22665 17.8212 1.8442 17.4387 1.60451 16.9683C1.33203 16.4336 1.33203 15.7335 1.33203 14.3334V5.66669C1.33203 4.26656 1.33203 3.56649 1.60451 3.03171C1.8442 2.56131 2.22665 2.17885 2.69705 1.93917C3.23183 1.66669 3.9319 1.66669 5.33203 1.66669H10.6654C12.0655 1.66669 12.7656 1.66669 13.3003 1.93917C13.7707 2.17885 14.1532 2.56131 14.3929 3.03171C14.6654 3.56649 14.6654 4.26656 14.6654 5.66669Z"
      stroke="#1DA069"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const FileO2Icon = React.memo(FileO2IconSvg);
export default FileO2Icon;

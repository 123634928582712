import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAuthorizeUser } from 'services';
import useDarkSide from 'hooks/useDarkSide';
import { LogOutModal } from 'components/LogOutModal';
import { USER_SESSION } from 'utils';
import { setEncryptedTokenToStorage } from 'services/storage';

const Authenticate: FC = () => {
  useDarkSide();
  const { authorizeUser } = useAuthorizeUser();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const state = searchParams.get('state');
  const [errorLoginIn, setErrorLoginIn] = useState(false);

  useEffect(() => {
    if (token && state) {
      authorizeUser(
        { token: token, state: state },
        {
          onSuccess: (response) => {
            if (response?.token) {
              localStorage.setItem(
                USER_SESSION,
                JSON.stringify({
                  environment: response.environment,
                  first_name: response.first_name,
                  last_name: response.last_name,
                }),
              );
              setEncryptedTokenToStorage(response.token);
              window.location.replace('/');
            } else {
              setErrorLoginIn(true);
            }
          },
          onError: () => {
            setErrorLoginIn(true);
          },
        },
      );
    } else {
      setErrorLoginIn(true);
    }
  }, [authorizeUser, state, token]);

  return (
    <div className="flex h-screen w-full justify-center items-center dark:bg-bg-dark text-black dark:text-white">
      {errorLoginIn ? (
        <LogOutModal title="Could not log in" />
      ) : (
        <div className="flex">
          <h1 className="text-4xl">Loading</h1>
          <div className="flex items-center text-4xl ">
            <div className="animate-pulse-fast mr-1 delay-100">.</div>
            <div className="animate-pulse-fast mr-1 delay-200">.</div>
            <div className="animate-pulse-fast delay-300">.</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Authenticate;

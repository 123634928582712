import type { FC, InputHTMLAttributes, ReactElement } from 'react';
import React from 'react';
import { Skeleton } from '../../Skeleton';
import { cn } from 'utils';

type InputProps = {
  type: React.HTMLInputTypeAttribute | undefined;
  placeholder?: string;
  iconStart?: ReactElement;
  iconEnd?: ReactElement;
  required?: boolean;
  disabled?: boolean;
  className?: string;
  inputClassName?: string;
  id?: string;
  name?: string;
  readOnly?: boolean;
  defaultValue?: string | number | readonly string[] | undefined;
  onChange?: {
    (e: React.ChangeEvent<HTMLInputElement>): void;
    <T = string | React.ChangeEvent<unknown>>(
      field: T,
    ): T extends React.ChangeEvent<unknown>
      ? void
      : (e: string | React.ChangeEvent<unknown>) => void;
  };
  handleBlur?: {
    (e: React.FocusEvent<unknown, Element>): void;
    <T = unknown>(
      fieldOrEvent: T,
    ): T extends string ? (e: unknown) => void : void;
  };
  value?: string | number | readonly string[] | undefined;
  isLoading?: boolean;
  skeletonClassName?: string;
};

type AllInputProps = InputHTMLAttributes<HTMLInputElement> & InputProps;

const Input: FC<AllInputProps> = ({
  type,
  placeholder,
  iconEnd,
  iconStart,
  required = false,
  disabled,
  className,
  inputClassName,
  id,
  name,
  handleBlur,
  onChange,
  value,
  readOnly = false,
  defaultValue,
  isLoading,
  ...props
}) => {
  return (
    <div className="w-full">
      {isLoading ? (
        <div className="w-full">
          <Skeleton className="h-[50px] w-full rounded-lg" />
        </div>
      ) : (
        <div className={cn(className)}>
          <div className="relative">
            {iconStart && (
              <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none ">
                {iconStart}
              </div>
            )}
            <input
              type={type}
              className={cn(
                `block  w-full p-3 text-base ${iconStart && 'ps-10'}  text-gray-900 border border-gray-300 rounded-lg  focus:outline-none focus:ring-2 focus:ring-primary focus:border-primary dark:border-gray-600 dark:placeholder-gray-400  `,
                inputClassName
                  ? inputClassName
                  : ' dark:text-white bg-white dark:bg-bg-dark',
              )}
              placeholder={placeholder}
              required={required}
              disabled={disabled}
              id={id}
              name={name}
              onChange={onChange}
              onBlur={handleBlur}
              value={value}
              readOnly={readOnly}
              defaultValue={defaultValue}
              {...props}
            />

            {iconEnd && (
              <div className="absolute end-2 bottom-1.5 font-medium rounded-lg text-sm px-4 py-2">
                {iconEnd}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Input;

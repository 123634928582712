import * as React from 'react';
import type { SVGProps } from 'react';
const AtMailIconSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={18}
    height={17}
    viewBox="0 0 18 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M12.75 8.50001C12.75 9.49457 12.3549 10.4484 11.6517 11.1517C10.9484 11.8549 9.99456 12.25 9 12.25C8.00544 12.25 7.05161 11.8549 6.34835 11.1517C5.64509 10.4484 5.25 9.49457 5.25 8.50001C5.25 7.50545 5.64509 6.55162 6.34835 5.84836C7.05161 5.1451 8.00544 4.75001 9 4.75001C9.99456 4.75001 10.9484 5.1451 11.6517 5.84836C12.3549 6.55162 12.75 7.50545 12.75 8.50001ZM12.75 8.50001C12.75 9.88084 13.5892 11 14.625 11C15.6608 11 16.5 9.88084 16.5 8.50001C16.5 6.76484 15.8983 5.08337 14.7975 3.74207C13.6968 2.40078 12.1649 1.48267 10.4631 1.14417C8.76129 0.805672 6.99475 1.06773 5.46448 1.88569C3.9342 2.70365 2.73489 4.02691 2.07088 5.63C1.40688 7.23309 1.31926 9.01681 1.82296 10.6773C2.32667 12.3377 3.39053 13.7721 4.83327 14.7361C6.27601 15.7001 8.00837 16.134 9.73518 15.964C11.462 15.7939 13.0764 15.0303 14.3033 13.8033M12.75 8.50001V5.37501"
      stroke="#DD5050"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const AtMailIcon = React.memo(AtMailIconSvg);
export default AtMailIcon;

interface Environment {
  apiUrl: string;
  nizaAppUrl: string;
}

const environment: Environment = {
  apiUrl: process.env.REACT_APP_API_URL || '',
  nizaAppUrl: process.env.REACT_APP_NIZA_APP_URL || '',
};

export default environment;

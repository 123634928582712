import { LoadingIcon, PaymentSuccessIcon } from 'assets';
import { getUrlParams } from 'utils';
import { useConfirmOrderByCustomer } from '../../services';

const ConfirmOrder = () => {
  const token = getUrlParams('token');

  const { data: confirmOrderData, isLoading } =
    useConfirmOrderByCustomer(token);

  if (!isLoading) {
    return (
      <div className="flex flex-col justify-center items-center h-screen text-center gap-2 bg-white text-black ">
        <PaymentSuccessIcon />
        <p className="text-2xl">Order confirmed</p>
        <p>
          Order confirmed successfully. <br />
          If you encounter any issues, please contact our{' '}
          <a className="text-blue-600" href="mailto:support@niza.io">
            support team.
          </a>
        </p>
      </div>
    );
  }

  return (
    <div className="flex flex-col justify-center items-center h-screen text-center gap-2 bg-white text-black ">
      <LoadingIcon className="h-[100px] w-[100px] " />
    </div>
  );
};

export default ConfirmOrder;

import { useState, useEffect } from 'react';

const useHtmlClass = () => {
  const [htmlClass, setHtmlClass] = useState('');

  useEffect(() => {
    // Set the initial class
    setHtmlClass(document.documentElement.className);

    // Add an observer to watch for changes to the class attribute
    const observer = new MutationObserver(() => {
      setHtmlClass(document.documentElement.className);
    });

    observer.observe(document.documentElement, {
      attributes: true,
      attributeFilter: ['class'],
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  return htmlClass === 'dark' ? true : false;
};

export default useHtmlClass;

import { SearchIcon } from 'assets';
import { Input, Skeleton, Toast } from 'components';
import { useOnrampInitialData } from 'providers/OnrampDataProvider';
import type { FC } from 'react';
import React, { useEffect, useMemo, useState } from 'react';
import {
  useGetOnrampPaymentInstitutions,
  useInitiateOnrampPayment,
  useUpdateOnrampProgress,
} from 'services';
import type {
  InitiateOnrampPaymentResponse,
  OnrampPaymentInstitution,
  PayByBnkTransferResponse,
  PaymentMethod,
  ToastStateProps,
} from 'services/types';
import PaymentInstitutionCard from '../components/PaymentInstitutionCard';
import BankAuthenticate from './payByBankSteps/BankAuthenticate';
import NeedHelp from './payByBankSteps/NeedHelp';
import PaymentSummary from './payByBankSteps/PaymentSummary';
import { getUrlParams } from 'utils';

type PayByBankProps = {
  selectedPayment: PaymentMethod | undefined;
  bankDetails?: PayByBnkTransferResponse;
};

const PayByBank: FC<PayByBankProps> = ({ selectedPayment, bankDetails }) => {
  const [search, setSearch] = useState('');
  const [selectedBank, setSelectedBank] =
    useState<InitiateOnrampPaymentResponse>();
  const [selectedInstitution, setSelectedInstitution] =
    useState<OnrampPaymentInstitution>();
  const [needHelp, setNeedHelp] = useState(false);
  const [toastState, setToastState] = useState<ToastStateProps>({
    active: false,
    toastMessage: '',
  });

  const { data: paymentInstitutions, isLoading: isLoadingPaymentInstitutions } =
    useGetOnrampPaymentInstitutions();
  const { initiateOnrampPayment, isInitiatingOnrampPayment } =
    useInitiateOnrampPayment();
  const { updateOnrampProgress } = useUpdateOnrampProgress();

  const { onrampProgress } = useOnrampInitialData();
  const initialData = getUrlParams('data');

  const filteredPaymentInstitutions = useMemo(() => {
    return paymentInstitutions?.filter((payment) =>
      payment.full_name?.toLowerCase().includes(search.toLowerCase()),
    );
  }, [search, paymentInstitutions]);

  const handleInstitutionSelect = (institution: OnrampPaymentInstitution) => {
    if (initialData && onrampProgress?.data.order_id) {
      setSelectedInstitution(institution);
      updateOnrampProgress({
        on_ramp_data: initialData,
        order_id: onrampProgress?.data.order_id,
        payment_method: JSON.stringify({
          payment_method: selectedPayment?.provider,
          selected_institution: institution.id,
        }),
      });
    }
  };

  const preselectedInstitution = useMemo(() => {
    return paymentInstitutions?.find(
      (institution) =>
        institution.id ===
        parseInt(
          onrampProgress?.data.payment_method?.selected_institution ?? '',
        ),
    );
  }, [
    onrampProgress?.data.payment_method?.selected_institution,
    paymentInstitutions,
  ]);

  useEffect(() => {
    if (preselectedInstitution) {
      setSelectedInstitution(preselectedInstitution);
    }
  }, [preselectedInstitution]);

  const handleInitiatePayment = (institution: OnrampPaymentInstitution) => {
    if (
      institution.institution_id &&
      onrampProgress?.data.order_id &&
      selectedPayment?.id
    ) {
      initiateOnrampPayment(
        {
          institution_id: institution.institution_id.toString(),
          order_id: onrampProgress?.data.order_id,
          payment_method_id: selectedPayment?.id,
        },
        {
          onSuccess: (response) => {
            setSelectedBank(response);
          },
          onError: (error) => {
            setToastState({
              active: true,
              variant: 'error',
              toastMessage: error.response?.data.message,
            });
          },
        },
      );
    }
  };

  const skeletonElements = Array.from({ length: 5 }, (_, index) => (
    <div key={index}>
      <Skeleton className="h-6 mb-2" />
      <Skeleton className="h-[80px] rounded-2xl mb-5" />
    </div>
  ));

  if (!selectedInstitution) {
    return (
      <div>
        <div className="text-center text-2xl mb-5 border-b pb-2 border-borderLight dark:border-borderDark">
          Select your bank
        </div>
        <div></div>
        <Input
          type="text"
          className="mb-5"
          placeholder="Search"
          iconStart={<SearchIcon className="fill-black dark:fill-white" />}
          value={search}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setSearch(e.target.value)
          }
        />
        {isLoadingPaymentInstitutions ? (
          <div className="grid grid-cols-2 gap-2 max-h-[500px] overflow-y-auto px-2">
            {skeletonElements}
          </div>
        ) : (
          <div className="grid grid-cols-2 gap-2 max-h-[500px] overflow-y-auto px-2">
            {filteredPaymentInstitutions?.map((paymentInstitution) => (
              <PaymentInstitutionCard
                institution={paymentInstitution}
                key={paymentInstitution.id}
                onClick={handleInstitutionSelect}
                disabled={isInitiatingOnrampPayment}
              />
            ))}
          </div>
        )}
      </div>
    );
  }

  return (
    <div>
      {selectedBank ? (
        needHelp ? (
          <NeedHelp setNeedHelp={setNeedHelp} />
        ) : (
          <BankAuthenticate
            selectedBank={selectedBank}
            selectedInstitution={selectedInstitution}
            setNeedHelp={setNeedHelp}
            setSelectedBank={setSelectedBank}
            bankDetails={bankDetails}
            setToastState={setToastState}
          />
        )
      ) : (
        <PaymentSummary
          handleInitiatePayment={handleInitiatePayment}
          isInitiatingOnrampPayment={isInitiatingOnrampPayment}
          selectedInstitution={selectedInstitution}
          setSelectedInstitution={setSelectedInstitution}
          bankDetails={bankDetails}
        />
      )}

      {toastState.active && (
        <Toast
          text={toastState?.toastMessage ?? ''}
          onClose={() => setToastState({ active: false, toastMessage: '' })}
          variant="default"
        />
      )}
    </div>
  );
};

export default PayByBank;

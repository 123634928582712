import * as React from 'react';
import type { SVGProps } from 'react';
const HourGlassIconSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M26.3992 24.57L17.448 16L26.3992 7.43C26.4043 7.42427 26.4098 7.41885 26.4155 7.41375C26.695 7.13405 26.8853 6.77777 26.9624 6.38993C27.0395 6.00209 26.9999 5.6001 26.8486 5.23476C26.6973 4.86942 26.4411 4.55713 26.1124 4.33736C25.7837 4.11758 25.3972 4.00018 25.0017 4H7.00173C6.60641 4.00043 6.22008 4.11801 5.89155 4.33788C5.56301 4.55775 5.307 4.87005 5.15586 5.23533C5.00471 5.60062 4.96521 6.00251 5.04235 6.39023C5.11948 6.77796 5.30979 7.13412 5.58923 7.41375L5.60423 7.43L14.5555 16L5.60423 24.57L5.58923 24.5863C5.30979 24.8659 5.11948 25.222 5.04235 25.6098C4.96521 25.9975 5.00471 26.3994 5.15586 26.7647C5.307 27.13 5.56301 27.4423 5.89155 27.6621C6.22008 27.882 6.60641 27.9996 7.00173 28H25.0017C25.3973 28.0001 25.7839 27.8828 26.1129 27.6632C26.4418 27.4435 26.6982 27.1312 26.8496 26.7658C27.0011 26.4004 27.0408 25.9983 26.9637 25.6104C26.8867 25.2224 26.6963 24.866 26.4167 24.5863C26.4106 24.5812 26.4047 24.5758 26.3992 24.57ZM12.2255 11H19.778L16.0017 14.615L12.2255 11ZM25.0017 6L21.8667 9H10.1367L7.00173 6H25.0017ZM7.00173 26L15.0017 18.3425V21C15.0017 21.2652 15.1071 21.5196 15.2946 21.7071C15.4822 21.8946 15.7365 22 16.0017 22C16.2669 22 16.5213 21.8946 16.7088 21.7071C16.8964 21.5196 17.0017 21.2652 17.0017 21V18.3425L25.0017 26H7.00173Z"
      fill="fill"
    />
  </svg>
);

const HourGlassIcon = React.memo(HourGlassIconSvg);
export default HourGlassIcon;

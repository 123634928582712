import type { FC } from 'react';
import RightMenu from './RightMenu';
import TopHeader from './TopHeader';
import { useMediaQuery } from 'react-responsive';

const Header: FC = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 768px)',
  });

  if (isDesktopOrLaptop) {
    return <RightMenu />;
  }

  return <TopHeader />;
};

export default Header;

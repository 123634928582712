import type { FC } from 'react';
import React from 'react';
import { Button } from 'components';
import { PaymentRejectedIcon } from 'assets';

type RejectedStatusResponseProps = {
  paymentSum: number;
  sourceCurrency: string | undefined;
  beneficiary: string | undefined;
  //   reference: string | null | undefined;
  //   setToastState: (value: React.SetStateAction<ToastStateProps>) => void;
  setUrlStep: (
    s: React.SetStateAction<
      Partial<{
        step?: any;
      }>
    >,
  ) => void;
};

const RejectedStatusResponse: FC<RejectedStatusResponseProps> = ({
  beneficiary,
  paymentSum,
  setUrlStep,
  sourceCurrency,
}) => {
  return (
    <div className="flex flex-col  items-center min-h-[600px] justify-between">
      <div className="mx-2 md:mx-12">
        <p className="text-xl text-center">
          Your{' '}
          <span className="text-error-700">
            {paymentSum.toFixed(2)} {sourceCurrency}{' '}
          </span>
          transfer to <br />
          <span className="text-error-700">{beneficiary}</span> failed
        </p>
      </div>

      <PaymentRejectedIcon />

      <Button
        name="Return"
        variant="primary"
        className="w-full"
        onClick={() =>
          setUrlStep({
            step: undefined,
          })
        }
      />
    </div>
  );
};

export default RejectedStatusResponse;

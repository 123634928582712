import {
  Button,
  Input,
  InputError,
  Label,
  OnrampConfirmEmailStep,
} from 'components';
import { Formik } from 'formik';
import { useOnrampInitialData } from 'providers/OnrampDataProvider';
import type { FC } from 'react';
import React, { useState } from 'react';
// import PhoneInput from 'react-phone-number-input';
// import 'react-phone-number-input/style.css';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import {
  useVerifyOnrampCustomer,
  useVerifyOnrampCustomerWithNiza,
} from 'services';
import type { OnrampSessionType, ToastStateProps } from 'services/types';
import { OnrampLoginSchema, cn, getUrlParams } from 'utils';
import OnRampCardLayout from '../layout/OnRampCardLayout';
import { useHtmlClass } from '../../../hooks';
import './style.css';

type OnrampEnterEmailStepProps = {
  setUrlStep: (
    s: React.SetStateAction<
      Partial<{
        step?: any;
      }>
    >,
  ) => void;
  setOnrampSession: React.Dispatch<React.SetStateAction<OnrampSessionType>>;
  onrampSession: OnrampSessionType;
  setToastState?: React.Dispatch<React.SetStateAction<ToastStateProps>>;
};

enum VerifyMode {
  normal = 'normal',
  niza = 'niza',
}

const OnrampEnterEmailStep: FC<OnrampEnterEmailStepProps> = ({
  setUrlStep,
  // userData,
  setOnrampSession,
  onrampSession,
  setToastState,
}) => {
  const [verifyEmail, setVerifyEmail] = useState(2);
  const [verifyMode, setVerifyMode] = useState(VerifyMode.niza);
  const [hasVerifiedNizaUser, setHasVerifiedNizaUser] = useState(true);
  const { onrampData } = useOnrampInitialData();
  const business_id = onrampData?.data.reference;
  const livemode = getUrlParams('livemode');

  const darkMode = useHtmlClass();

  const { verifyOnrampCustomer, isVerifyingOnrampCustomer } =
    useVerifyOnrampCustomer();
  const { verifyOnrampCustomerWithNiza, isVerifyingOnrampCustomerWithNiza } =
    useVerifyOnrampCustomerWithNiza();

  if (verifyEmail === 3) {
    return (
      <OnrampConfirmEmailStep
        setUrlStep={setUrlStep}
        setVerifyEmail={setVerifyEmail}
        setOnrampSession={setOnrampSession}
        onrampSession={onrampSession}
        setToastState={setToastState}
      />
    );
  }

  return (
    <Formik
      initialValues={{
        email: '',
        mobile_number: '',
      }}
      validationSchema={OnrampLoginSchema}
      onSubmit={(values, { setSubmitting }) => {
        if (business_id && livemode) {
          if (verifyMode === VerifyMode.normal) {
            verifyOnrampCustomer(
              {
                business_id: business_id,
                livemode: livemode === 'true' ? true : false,
                email: values.email,
                mobile_number: values.mobile_number,
              },
              {
                onSuccess: (response) => {
                  if (response.email) {
                    setOnrampSession({
                      ...onrampSession,
                      email: response.email,
                    });
                    if (setToastState) {
                      setToastState({
                        active: true,
                        toastMessage: response.message ?? 'Updated',
                        variant: 'default',
                      });
                    }
                    setVerifyEmail(3);
                  }
                },
                onError: (error) => {
                  if (setToastState) {
                    setToastState({
                      active: true,
                      toastMessage: error.response?.data.message ?? 'Error',
                      variant: 'error',
                    });
                  }
                },
              },
            );
          }

          if (verifyMode === VerifyMode.niza) {
            verifyOnrampCustomerWithNiza(
              {
                business_id: business_id,
                livemode: livemode === 'true' ? true : false,
                email: values.email,
                mobile_number: values.mobile_number,
              },
              {
                onSuccess: (response) => {
                  if (response.email) {
                    setOnrampSession({
                      ...onrampSession,
                      email: response.email,
                    });
                    if (setToastState) {
                      setToastState({
                        active: true,
                        toastMessage: response.message ?? 'Updated',
                        variant: 'default',
                      });
                    }
                    setVerifyEmail(3);
                  }
                },
                onError: (error) => {
                  if (setToastState) {
                    setHasVerifiedNizaUser(false);
                    setToastState({
                      active: true,
                      toastMessage: error.response?.data.message ?? 'Error',
                      variant: 'error',
                    });
                  }
                },
              },
            );
          }
        }

        setSubmitting(false);
      }}>
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
      }) => (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
          className="w-full flex justify-center">
          <OnRampCardLayout setStep={() => setUrlStep({ step: undefined })}>
            <div>
              <div className="text-center border-b pb-3">
                <h1 className="text-lg">Buy 0.0755 ETH</h1>
                <p className="text-xs">
                  Log in / <span className="text-primary">Verify</span> / Pay
                </p>
              </div>

              <div className="flex flex-col-reverse md:flex-row justify-between mt-5 p-0 md:p-4 rounded-xl items-start md:items-center gap-5">
                <div className="flex flex-col gap-2">
                  <p className="text-2xl font-poppins text-center">
                    Sign up or Log in to <span className="bold">NIZA</span>{' '}
                    <span className="italic text-primary">On Ramp</span>
                  </p>
                  <p className="px-4 sm:px-16  md:px-20 text-center">
                    Save your payment information to check out safely at
                    thousands of online retailers.
                  </p>
                </div>
              </div>

              <div className="flex flex-col justify-between mt-5  rounded-xl">
                <Label text="Email Address" className="mb-2" />
                <Input
                  type="email"
                  name="email"
                  placeholder="youremail@domain.com"
                  onChange={handleChange}
                  handleBlur={handleBlur}
                  value={values.email}
                />
                {errors.email && touched.email ? (
                  <InputError
                    errorMessage={errors.email}
                    className="pt-2 ml-2 md:ml-2"
                  />
                ) : null}

                <Label text="Phone Number" className="mb-2 mt-4" />
                <div>
                  <PhoneInput
                    placeholder="Enter phone number"
                    value={values.mobile_number}
                    inputProps={{
                      name: 'mobile_number',
                    }}
                    onBlur={handleBlur}
                    onChange={(value) => setFieldValue('mobile_number', value)}
                    containerClass="border border-gray-300 rounded-lg  h-[50px] dark:border-gray-600 dark:placeholder-gray-400 focus-within:ring-2 focus-within:ring-primary focus-within:border-primary"
                    enableSearch
                    buttonStyle={{
                      backgroundColor: 'transparent',
                      border: 'unset',
                    }}
                    inputStyle={{
                      width: '100%',
                      height: '100%',
                      border: 'unset',
                      backgroundColor: 'transparent',
                    }}
                    containerStyle={{
                      color: darkMode ? 'white' : 'black',
                    }}
                    dropdownStyle={{
                      backgroundColor: darkMode ? '#101828' : 'white',
                      position: 'fixed',
                      top: '10vh',
                      height: '100vh',
                      zIndex: 10,
                      minHeight: '80vh',
                      borderRadius: '10px',
                    }}
                    searchStyle={{
                      backgroundColor: darkMode ? '#101828' : 'white',
                    }}
                  />
                </div>

                {errors.mobile_number && touched.mobile_number ? (
                  <InputError
                    errorMessage={errors.mobile_number}
                    className="pt-2 ml-2 md:ml-2"
                  />
                ) : null}
              </div>
            </div>

            <div className="mt-16 text-center">
              <p className="text-sm">
                By continuing you agree to the Terms of Service and Privacy
                Policy
              </p>
              <div className="flex gap-2">
                <Button
                  // type="submit"
                  name="Continue"
                  variant="primary"
                  className="w-full mt-2"
                  // disabled={!values.email || !values.mobile_number}
                  isLoading={isVerifyingOnrampCustomer}
                  onClick={() => {
                    setVerifyMode(VerifyMode.normal);
                    handleSubmit();
                  }}
                />
                {hasVerifiedNizaUser && (
                  <Button
                    // type="submit"
                    name="Continue with Niza"
                    variant="secondary"
                    className="w-full mt-2"
                    // disabled={!values.email || !values.mobile_number}
                    isLoading={isVerifyingOnrampCustomerWithNiza}
                    onClick={() => {
                      setVerifyMode(VerifyMode.niza);
                      handleSubmit();
                    }}
                  />
                )}
              </div>
            </div>
          </OnRampCardLayout>
        </form>
      )}
    </Formik>
  );
};

export default OnrampEnterEmailStep;

import type { FC, ReactElement } from 'react';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

type ButtonLinkProps = {
  path?: string;
  name: string;
  icon?: ReactElement;
  onClick?: () => void;
};

const ButtonLink: FC<ButtonLinkProps> = ({ icon, name, path, onClick }) => {
  const [isActive, setIsActive] = useState(false);
  const location = useLocation();
  useEffect(() => {
    if (location.pathname && location.pathname === transformName(name)) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }, [location.pathname, name]);

  const transformName = (name: string) => {
    switch (name.toLowerCase()) {
      case 'customers':
        return '/';
      default:
        return `/${name.toLowerCase()}`;
    }
  };

  const commonProps = {
    onClick,
    className: `flex w-full max-w-44 gap-2 active:text-primary cursor-pointer ${isActive && 'text-primary'}`,
  };

  return onClick ? (
    <div {...commonProps}>
      {icon} {name}
    </div>
  ) : (
    <Link to={`/${path}`} {...commonProps}>
      {icon} {name}
    </Link>
  );
};

export default ButtonLink;

import type { FC, ReactElement } from 'react';
import React from 'react';
import type { PaymentMethodItem } from 'components/InvoiceProcessingComponents/PaymentMethodItem';
import {
  BankTransferSepaPayment,
  BankTransferSwiftPayment,
} from '../components';

const selectedPaymentMethod: Record<PaymentMethodItem['value'], ReactElement> =
  {
    bank_transfer_sepa: <BankTransferSepaPayment />,
    bank_transfer_swift: <BankTransferSwiftPayment />,
    pay_by_bank: <></>,
    pay_by_card: <></>,
  };

type PaymentProcessorProps = {
  paymentType:
    | 'pay_by_card'
    | 'pay_by_bank'
    | 'bank_transfer_sepa'
    | 'bank_transfer_swift'
    | undefined;
};

const PaymentProcessor: FC<PaymentProcessorProps> = ({ paymentType }) => {
  return (
    <div
      className={`max-w-[622px] w-full shadow-lg bg-white dark:bg-bg-dark rounded-[20px] ease-linear transition-all duration-300 overflow-hidden self-center lg:self-auto ${paymentType ? 'opacity-100 ' : 'opacity-0 w-0 -translate-x-full'} `}>
      {paymentType ? selectedPaymentMethod[paymentType] : null}
    </div>
  );
};

export default PaymentProcessor;

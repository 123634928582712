import type { FC, ReactNode } from 'react';
import { createContext, useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useGetInitialOnrampData, useGetOnrampProgress } from 'services';
import { getEncryptedOnrampTokenFromStorage } from 'services/storage';
import type {
  GetOnrampInitialDataResponse,
  JsonParsedOnrampProgress,
} from 'services/types';

interface OnrampDataType {
  onrampData?: GetOnrampInitialDataResponse;
  isLoading?: boolean;
  onrampError?: string;
  onrampProgress?: JsonParsedOnrampProgress | undefined;
  setOnrampProgress?: React.Dispatch<
    React.SetStateAction<JsonParsedOnrampProgress | undefined>
  >;
  onrampProgressFetched?: boolean;
}

const OnrampDataContext = createContext<OnrampDataType>({
  isLoading: true,
  onrampData: undefined,
  onrampError: '',
  onrampProgress: undefined,
  setOnrampProgress: undefined,
  onrampProgressFetched: false,
});

export const useOnrampInitialData = () => {
  return useContext(OnrampDataContext);
};

interface OnrampDataProviderProps {
  children: ReactNode;
}

const OnrampDataProvider: FC<OnrampDataProviderProps> = ({ children }) => {
  const [searchParams] = useSearchParams();

  const [onrampData, setOnrampData] = useState<GetOnrampInitialDataResponse>();
  const [onrampError, setOnrampError] = useState<string>();
  const [onrampProgress, setOnrampProgress] =
    useState<JsonParsedOnrampProgress>();
  const [onrampProgressFetched, setOnrampProgressFetched] = useState(false);

  const ref = searchParams.get('ref');
  const livemode = searchParams.get('livemode');
  const data = searchParams.get('data');
  const onrampSessionToken = getEncryptedOnrampTokenFromStorage();

  const { mutate: getOnrampData, isPending: isLoading } =
    useGetInitialOnrampData();
  const { getOnrampProgress } = useGetOnrampProgress();

  useEffect(() => {
    if (ref && livemode && data) {
      getOnrampData(
        {
          data: data,
          livemode: livemode === 'true' ? true : false,
          ref: ref,
        },
        {
          onSuccess: (response) => {
            setOnrampData(response);
          },
          onError: (error) => {
            setOnrampError(error.response?.data.message);
          },
        },
      );
    }
  }, [data, getOnrampData, livemode, ref]);

  useEffect(() => {
    if (data && !!onrampSessionToken) {
      getOnrampProgress(
        {
          on_ramp_data: data,
        },
        {
          onSuccess: (response) => {
            setOnrampProgress({
              ...response,
              data: {
                ...response.data,
                payment_method: response.data.payment_method
                  ? JSON.parse(response.data.payment_method)
                  : null,
              },
            });
            setOnrampProgressFetched(true);
          },
          onError: () => {
            setOnrampProgressFetched(true);
          },
        },
      );
    }
  }, [data, getOnrampProgress, onrampSessionToken]);

  return (
    <OnrampDataContext.Provider
      value={{
        onrampData: onrampData,
        isLoading: isLoading,
        onrampError: onrampError,
        onrampProgress: onrampProgress,
        setOnrampProgress: setOnrampProgress,
        onrampProgressFetched: onrampProgressFetched,
      }}>
      {children}
    </OnrampDataContext.Provider>
  );
};

export default OnrampDataProvider;

import * as React from 'react';
import type { SVGProps } from 'react';
const PayByCardSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={48}
    height={48}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <rect width={48} height={48} rx={6} fill="#25575A" />
    <path
      d="M6.40551 22.467C5.81372 21.4421 6.16506 20.1315 7.19023 19.5396L32.2496 5.0716C33.2748 4.47971 34.5856 4.8307 35.1774 5.85556L43.2138 19.7731C43.8056 20.7979 43.4542 22.1085 42.4291 22.7004L17.3697 37.1684C16.3445 37.7603 15.0337 37.4093 14.4419 36.3845L6.40551 22.467Z"
      fill="#033B47"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35.9086 17.519C37.3867 16.6656 37.9013 14.7899 37.0581 13.3294C36.2149 11.8689 34.3332 11.3768 32.8551 12.2301C32.1915 12.6133 31.7221 13.2024 31.4826 13.8676C30.7873 13.7429 30.043 13.8549 29.3799 14.2377C27.9019 15.0911 27.3872 16.9668 28.2304 18.4273C29.0736 19.8878 30.9554 20.3799 32.4334 19.5266C33.097 19.1435 33.5664 18.5543 33.8059 17.8892C34.5012 18.0138 35.2455 17.9018 35.9086 17.519Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.8095 17.8941C34.1054 17.0765 34.0538 16.1436 33.5883 15.3372C33.1242 14.5335 32.3457 14.023 31.493 13.8687C31.733 13.2055 32.2018 12.6182 32.8638 12.236C34.3418 11.3826 36.2236 11.8748 37.0668 13.3352C37.91 14.7957 37.3953 16.6715 35.9173 17.5248C35.2526 17.9086 34.5063 18.0202 33.8095 17.8941Z"
      fill="#C6C6C6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.7973 17.8867C34.0911 17.0704 34.0387 16.1398 33.5741 15.3352C33.1097 14.5307 32.3301 14.0201 31.4765 13.8662C31.1828 14.6825 31.2352 15.6132 31.6997 16.4178C32.1642 17.2222 32.9437 17.7329 33.7973 17.8867Z"
      fill="#306269"
    />
    <g filter="url(#filter0_dd_214_21875)">
      <path
        d="M8.9988 22.8081C8.91317 22.6598 8.9648 22.4697 9.11413 22.3834L15.9508 18.4363C16.1001 18.3501 16.2906 18.4004 16.3762 18.5487L18.3475 21.9626C18.4331 22.1109 18.3815 22.301 18.2322 22.3872L11.3955 26.3344C11.2462 26.4206 11.0557 26.3703 10.9701 26.222L8.9988 22.8081Z"
        fill="#BAD6F0"
      />
    </g>
    <g filter="url(#filter1_dd_214_21875)">
      <path
        d="M14.4239 31.8999C14.2513 31.601 14.3537 31.2187 14.6527 31.0461L20.8402 27.4737C21.1392 27.3011 21.5216 27.4035 21.6942 27.7024C21.8668 28.0013 21.7643 28.3836 21.4653 28.5562L15.2778 32.1285C14.9788 32.3012 14.5965 32.1988 14.4239 31.8999Z"
        fill="#D0E7FD"
      />
    </g>
    <g filter="url(#filter2_dd_214_21875)">
      <path
        d="M18.0899 18.0935C17.9173 17.7946 18.0197 17.4123 18.3187 17.2397L32.3929 9.11393C32.6919 8.94129 33.0743 9.04367 33.2469 9.34258C33.4195 9.6415 33.317 10.0238 33.018 10.1964L18.9438 18.3221C18.6448 18.4948 18.2625 18.3924 18.0899 18.0935Z"
        fill="#B5D5EF"
      />
    </g>
    <g filter="url(#filter3_dd_214_21875)">
      <path
        d="M22.7422 27.1008C22.5696 26.8019 22.6721 26.4196 22.9711 26.247L29.1586 22.6746C29.4576 22.502 29.8399 22.6044 30.0125 22.9033C30.1851 23.2022 30.0827 23.5845 29.7836 23.7571L23.5961 27.3295C23.2971 27.5021 22.9148 27.3997 22.7422 27.1008Z"
        fill="#D0E7FD"
      />
    </g>
    <g filter="url(#filter4_dd_214_21875)">
      <path
        d="M31.0567 22.2961C30.8841 21.9972 30.9865 21.6149 31.2855 21.4423L37.473 17.87C37.772 17.6973 38.1544 17.7997 38.327 18.0986C38.4996 18.3975 38.3971 18.7798 38.0981 18.9524L31.9106 22.5248C31.6116 22.6974 31.2293 22.595 31.0567 22.2961Z"
        fill="#D0E7FD"
      />
    </g>
    <rect
      width={0.304239}
      height={4.56232}
      transform="matrix(0.866025 -0.5 0.500055 0.865994 10.3125 21.6904)"
      fill="url(#paint0_linear_214_21875)"
    />
    <rect
      width={0.304155}
      height={8.51868}
      transform="matrix(0.500055 0.865994 -0.866025 0.5 16.8867 19.4402)"
      fill="url(#paint1_linear_214_21875)"
    />
    <path
      d="M8.73247 26.3459C8.44012 25.1992 9.13304 24.0325 10.2801 23.7402L38.3198 16.5938C39.4669 16.3014 40.6338 16.9941 40.9262 18.1408L44.8963 33.7139C45.1886 34.8606 44.4957 36.0273 43.3486 36.3196L15.3089 43.466C14.1618 43.7584 12.9949 43.0657 12.7026 41.919L8.73247 26.3459Z"
      fill="url(#paint2_linear_214_21875)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.6981 30.867L28.3581 31.2085L26.3476 27.5184C26.2536 27.3491 26.1114 27.2144 25.9432 27.1767C25.523 27.0814 25.0748 27.0345 24.6085 27.0721L24.5698 26.9202L26.7284 26.37C27.0263 26.2941 27.3079 26.4654 27.4127 26.7209L28.6595 29.4346L29.1476 25.7534L30.4504 25.4214L29.6981 30.867ZM32.455 30.166L31.1895 30.4885L30.9742 25.2895L32.2397 24.967L32.455 30.166ZM34.2235 25.9163C34.193 25.6411 34.3777 25.4322 34.6384 25.3658C35.0383 25.2232 35.504 25.1858 35.9247 25.2803L35.8774 24.1612C35.4663 24.1041 35.0372 24.1322 34.6654 24.227C33.4372 24.54 32.7176 25.4512 32.9593 26.3996C33.1432 27.1211 33.8728 27.3386 34.3779 27.4529C34.9193 27.5572 35.1442 27.6617 35.165 27.8988C35.252 28.2401 34.9183 28.4869 34.5466 28.5817C34.0997 28.6956 33.6238 28.6957 33.1664 28.6099L33.2138 29.7296C33.709 29.8052 34.2119 29.7582 34.6588 29.6444C36.0455 29.3308 36.7274 28.43 36.4663 27.4053C36.1374 26.1149 34.3685 26.4852 34.2235 25.9163ZM41.3117 27.9045L39.0495 23.2272L37.9702 23.5023C37.7468 23.5593 37.5621 23.7681 37.5456 24.0147L36.8456 29.0428L38.1483 28.7108L38.2246 27.9237L39.8253 27.5157L40.1579 28.1986L41.3117 27.9045ZM38.4991 24.791L39.3447 26.5556L38.3026 26.8212L38.4991 24.791Z"
      fill="white"
    />
    <g filter="url(#filter5_dd_214_21875)">
      <path
        d="M11.1332 27.3749C11.0909 27.209 11.1921 27.0399 11.3592 26.9974L19.0089 25.0477C19.176 25.0051 19.3457 25.1051 19.388 25.271L20.3619 29.0911C20.4042 29.257 20.303 29.426 20.1359 29.4686L12.4862 31.4183C12.3191 31.4609 12.1494 31.3609 12.1071 31.1949L11.1332 27.3749Z"
        fill="#D0E7FD"
      />
    </g>
    <g filter="url(#filter6_dd_214_21875)">
      <path
        d="M13.9044 37.6042C13.8191 37.2697 14.0212 36.9294 14.3558 36.8441L21.2792 35.0796C21.6137 34.9943 21.9541 35.1963 22.0394 35.5308C22.1246 35.8653 21.9225 36.2056 21.588 36.2908L14.6646 38.0554C14.33 38.1406 13.9897 37.9386 13.9044 37.6042Z"
        fill="#D0E7FD"
      />
    </g>
    <g filter="url(#filter7_dd_214_21875)">
      <path
        d="M21.17 25.307C21.0847 24.9726 21.2868 24.6323 21.6214 24.547L37.3695 20.5334C37.704 20.4481 38.0444 20.6501 38.1297 20.9846C38.2149 21.3191 38.0128 21.6593 37.6783 21.7446L21.9302 25.7583C21.5956 25.8435 21.2553 25.6415 21.17 25.307Z"
        fill="#D0E7FD"
      />
    </g>
    <g filter="url(#filter8_dd_214_21875)">
      <path
        d="M23.2071 35.237C23.1219 34.9025 23.324 34.5622 23.6585 34.477L30.5819 32.7124C30.9165 32.6271 31.2568 32.8292 31.3421 33.1636C31.4274 33.4981 31.2253 33.8384 30.8907 33.9236L23.9673 35.6882C23.6327 35.7735 23.2924 35.5714 23.2071 35.237Z"
        fill="#D0E7FD"
      />
    </g>
    <g filter="url(#filter9_dd_214_21875)">
      <path
        d="M32.506 32.8639C32.4207 32.5294 32.6228 32.1892 32.9574 32.1039L39.8807 30.3394C40.2153 30.2541 40.5557 30.4561 40.6409 30.7906C40.7262 31.1251 40.5241 31.4653 40.1895 31.5506L33.2661 33.3151C32.9316 33.4004 32.5912 33.1984 32.506 32.8639Z"
        fill="#D0E7FD"
      />
    </g>
    <rect
      width={0.304239}
      height={4.56232}
      transform="matrix(0.969023 -0.246972 0.247033 0.969007 12.7051 26.6541)"
      fill="url(#paint3_linear_214_21875)"
    />
    <rect
      width={0.304155}
      height={8.51868}
      transform="matrix(0.247033 0.969007 -0.969023 0.246972 19.6367 26.2681)"
      fill="url(#paint4_linear_214_21875)"
    />
    <defs>
      <filter
        id="filter0_dd_214_21875"
        x={3.25703}
        y={9.69429}
        width={20.8316}
        height={21.6822}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB">
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius={1}
          operator="dilate"
          in="SourceAlpha"
          result="effect1_dropShadow_214_21875"
        />
        <feOffset dy={-3} />
        <feGaussianBlur stdDeviation={2.35} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.587031 0 0 0 0 0.79473 0 0 0 0 0.920833 0 0 0 0.38 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_214_21875"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={2} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_214_21875"
          result="effect2_dropShadow_214_21875"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_214_21875"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_dd_214_21875"
        x={8.63984}
        y={18.6899}
        width={18.8375}
        height={17.5225}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB">
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius={1}
          operator="dilate"
          in="SourceAlpha"
          result="effect1_dropShadow_214_21875"
        />
        <feOffset dy={-3} />
        <feGaussianBlur stdDeviation={2.35} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.587031 0 0 0 0 0.79473 0 0 0 0 0.920833 0 0 0 0.38 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_214_21875"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={1.5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.156863 0 0 0 0 0.2 0 0 0 0 0.254902 0 0 0 0.26 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_214_21875"
          result="effect2_dropShadow_214_21875"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_214_21875"
          result="shape"
        />
      </filter>
      <filter
        id="filter2_dd_214_21875"
        x={12.3059}
        y={0.330029}
        width={26.7242}
        height={22.076}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB">
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius={1}
          operator="dilate"
          in="SourceAlpha"
          result="effect1_dropShadow_214_21875"
        />
        <feOffset dy={-3} />
        <feGaussianBlur stdDeviation={2.35} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.587031 0 0 0 0 0.79473 0 0 0 0 0.920833 0 0 0 0.38 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_214_21875"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={1.5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.156863 0 0 0 0 0.2 0 0 0 0 0.254902 0 0 0 0.26 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_214_21875"
          result="effect2_dropShadow_214_21875"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_214_21875"
          result="shape"
        />
      </filter>
      <filter
        id="filter3_dd_214_21875"
        x={16.9582}
        y={13.8908}
        width={18.8375}
        height={17.5225}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB">
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius={1}
          operator="dilate"
          in="SourceAlpha"
          result="effect1_dropShadow_214_21875"
        />
        <feOffset dy={-3} />
        <feGaussianBlur stdDeviation={2.35} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.587031 0 0 0 0 0.79473 0 0 0 0 0.920833 0 0 0 0.38 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_214_21875"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={1.5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.156863 0 0 0 0 0.2 0 0 0 0 0.254902 0 0 0 0.26 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_214_21875"
          result="effect2_dropShadow_214_21875"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_214_21875"
          result="shape"
        />
      </filter>
      <filter
        id="filter4_dd_214_21875"
        x={25.2727}
        y={9.08613}
        width={18.8375}
        height={17.5225}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB">
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius={1}
          operator="dilate"
          in="SourceAlpha"
          result="effect1_dropShadow_214_21875"
        />
        <feOffset dy={-3} />
        <feGaussianBlur stdDeviation={2.35} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.587031 0 0 0 0 0.79473 0 0 0 0 0.920833 0 0 0 0.38 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_214_21875"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={1.5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.156863 0 0 0 0 0.2 0 0 0 0 0.254902 0 0 0 0.26 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_214_21875"
          result="effect2_dropShadow_214_21875"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_214_21875"
          result="shape"
        />
      </filter>
      <filter
        id="filter5_dd_214_21875"
        x={5.42305}
        y={16.3378}
        width={20.648}
        height={20.0901}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB">
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius={1}
          operator="dilate"
          in="SourceAlpha"
          result="effect1_dropShadow_214_21875"
        />
        <feOffset dy={-3} />
        <feGaussianBlur stdDeviation={2.35} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.587031 0 0 0 0 0.79473 0 0 0 0 0.920833 0 0 0 0.38 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_214_21875"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={2} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_214_21875"
          result="effect2_dropShadow_214_21875"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_214_21875"
          result="shape"
        />
      </filter>
      <filter
        id="filter6_dd_214_21875"
        x={8.18477}
        y={26.3601}
        width={19.5738}
        height={15.7149}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB">
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius={1}
          operator="dilate"
          in="SourceAlpha"
          result="effect1_dropShadow_214_21875"
        />
        <feOffset dy={-3} />
        <feGaussianBlur stdDeviation={2.35} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.587031 0 0 0 0 0.79473 0 0 0 0 0.920833 0 0 0 0.38 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_214_21875"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={1.5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.156863 0 0 0 0 0.2 0 0 0 0 0.254902 0 0 0 0.26 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_214_21875"
          result="effect2_dropShadow_214_21875"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_214_21875"
          result="shape"
        />
      </filter>
      <filter
        id="filter7_dd_214_21875"
        x={15.4504}
        y={11.8139}
        width={28.398}
        height={17.9639}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB">
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius={1}
          operator="dilate"
          in="SourceAlpha"
          result="effect1_dropShadow_214_21875"
        />
        <feOffset dy={-3} />
        <feGaussianBlur stdDeviation={2.35} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.587031 0 0 0 0 0.79473 0 0 0 0 0.920833 0 0 0 0.38 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_214_21875"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={1.5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.156863 0 0 0 0 0.2 0 0 0 0 0.254902 0 0 0 0.26 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_214_21875"
          result="effect2_dropShadow_214_21875"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_214_21875"
          result="shape"
        />
      </filter>
      <filter
        id="filter8_dd_214_21875"
        x={17.4875}
        y={23.9929}
        width={19.5738}
        height={15.7149}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB">
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius={1}
          operator="dilate"
          in="SourceAlpha"
          result="effect1_dropShadow_214_21875"
        />
        <feOffset dy={-3} />
        <feGaussianBlur stdDeviation={2.35} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.587031 0 0 0 0 0.79473 0 0 0 0 0.920833 0 0 0 0.38 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_214_21875"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={1.5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.156863 0 0 0 0 0.2 0 0 0 0 0.254902 0 0 0 0.26 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_214_21875"
          result="effect2_dropShadow_214_21875"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_214_21875"
          result="shape"
        />
      </filter>
      <filter
        id="filter9_dd_214_21875"
        x={26.7863}
        y={21.6198}
        width={19.5738}
        height={15.7149}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB">
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius={1}
          operator="dilate"
          in="SourceAlpha"
          result="effect1_dropShadow_214_21875"
        />
        <feOffset dy={-3} />
        <feGaussianBlur stdDeviation={2.35} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.587031 0 0 0 0 0.79473 0 0 0 0 0.920833 0 0 0 0.38 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_214_21875"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={1.5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.156863 0 0 0 0 0.2 0 0 0 0 0.254902 0 0 0 0.26 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_214_21875"
          result="effect2_dropShadow_214_21875"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_214_21875"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_214_21875"
        x1={0.152119}
        y1={0}
        x2={0.152119}
        y2={4.56232}
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#A3B4C9" />
        <stop offset={1} stopColor="#5189AB" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_214_21875"
        x1={0.152077}
        y1={0}
        x2={0.152077}
        y2={8.51868}
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#A3B4C9" />
        <stop offset={1} stopColor="#5189AB" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_214_21875"
        x1={-2}
        y1={23.9987}
        x2={69.3323}
        y2={40.0032}
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#5C8688" />
        <stop offset={1} stopColor="#174C57" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_214_21875"
        x1={0.152119}
        y1={0}
        x2={0.152119}
        y2={4.56232}
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#A3B4C9" />
        <stop offset={1} stopColor="#5189AB" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_214_21875"
        x1={0.152077}
        y1={0}
        x2={0.152077}
        y2={8.51868}
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#A3B4C9" />
        <stop offset={1} stopColor="#5189AB" />
      </linearGradient>
    </defs>
  </svg>
);

const PayByCard = React.memo(PayByCardSvg);

export default PayByCard;

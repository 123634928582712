import type { FC } from 'react';
import { useEffect } from 'react';
import type { ToastProps } from 'services/types';
import { cn } from '../../utils';

const variantStyles: Record<ToastProps['variant'], string> = {
  success: 'text-white bg-success-400 dark:text-black',
  error: 'text-white bg-error-400 dark:text-black',
  default: 'text-white bg-black dark:bg-white dark:text-black',
};

const Toast: FC<ToastProps> = ({
  text,
  onClose,
  iconAfter,
  iconBefore,
  variant = 'default',
}) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, [onClose]);
  return (
    <div
      id="toast-top-left"
      className={cn(
        'fixed flex z-50 items-center w-full max-w-fit   divide-x rtl:divide-x-reverse divide-gray-200 rounded-xl shadow bottom-10 left-1/2  dark:divide-gray-700 space-x transform -translate-x-1/2',
        variantStyles[variant],
      )}
      role="alert">
      <div className="text-sm font-normal py-4 px-5 text-center w-full flex items-center">
        {iconBefore && <span className="mr-2">{iconBefore}</span>}
        <p className="text-center">{text}</p>
        {iconAfter && <span className="ml-2">{iconAfter}</span>}
      </div>
    </div>
  );
};

export default Toast;

import { useState } from 'react';
import type { FC } from 'react';
import { useGetProductsList } from 'services';
import { Button, ProductCard, Skeleton } from 'components';
import { GenerateOnrampLink } from 'forms';

const Products: FC = () => {
  const [showModal, setShowModal] = useState(false);
  const { data: productsList, isLoading } = useGetProductsList();

  return (
    <div className="flex flex-wrap gap-5">
      {isLoading ? (
        <div className="flex w-full flex-col xl:flex-row gap-5">
          <Skeleton className="w-full h-44 rounded-xl " />
          <Skeleton className="w-full h-44 rounded-xl " />
        </div>
      ) : (
        productsList?.data?.map((product) => (
          <ProductCard
            product={product}
            key={product.id}
            actionButton={
              product.name === 'On-Ramp' ? (
                <Button
                  name="Generate Link"
                  variant="secondary"
                  onClick={() => {
                    setShowModal(true);
                  }}
                />
              ) : null
            }
          />
        ))
      )}

      <GenerateOnrampLink setShowModal={setShowModal} showModal={showModal} />
    </div>
  );
};

export default Products;

import client from '../client';
import type { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { API_ENDPOINTS } from '../client/api-endpoints';
import type {
  CoinNetworkResponse,
  ConvertAssetParams,
  FindAssetParams,
  GeneralError,
  GetAssetsListResponse,
} from '../types';

export function useGetCoinNetworks(data: { coin: string }) {
  const query = useQuery<CoinNetworkResponse, AxiosError<GeneralError>>({
    queryKey: [API_ENDPOINTS.GET_COIN_NETWORKS(data.coin)],
    queryFn: () => client.assets.getCoinNetworks(data),
    enabled: !!data.coin,
  });

  return query;
}

export function useGetAssetsList(data: { type: 'crypto' | 'fiat' }) {
  const query = useQuery<GetAssetsListResponse, AxiosError<GeneralError>>({
    queryKey: [API_ENDPOINTS.GET_ASSETS, data],
    queryFn: () => client.assets.getAssets(data),
  });

  return query;
}

export function useConvertAsset(data: ConvertAssetParams) {
  const query = useQuery<any, AxiosError<GeneralError>>({
    queryKey: [API_ENDPOINTS.CONVERT_ASSET, data],
    queryFn: () => client.assets.convertAsset(data),
    enabled: !!data.amount && !!data.from && !!data.to,
  });

  return query;
}

export function useFindAsset(data: FindAssetParams) {
  const query = useQuery({
    queryKey: [API_ENDPOINTS.FIND_ASSET(data.asset_id)],
    queryFn: () => client.assets.findAsset(data),
    enabled: !!data.asset_id,
  });

  return query;
}

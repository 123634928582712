import type { FC } from 'react';
import React from 'react';
import { Tag } from '../../Tag';

type TimelineProps = {
  timelines?: {
    date?: string;
    event?: string;
    status?: string;
  }[];
  isLoading?: boolean;
};

const Timeline: FC<TimelineProps> = ({ timelines, isLoading }) => {
  if (!timelines?.length && !isLoading) {
    return <div className="text-center text-primary">No data</div>;
  }
  return (
    <ol className="relative border-s border-gray-200 dark:border-gray-700">
      {timelines?.map((timeline) => (
        <li className="ms-4">
          <div className="absolute w-3 h-3 bg-primary rounded-full mt-1.5 -start-1.5 border border-primary dark:border-primary dark:bg-primary"></div>
          <time className="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">
            {timeline.date}
          </time>
          <div className="flex items-center gap-2 justify-between">
            <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
              {timeline.event}
            </h3>
            <Tag variant="default" text={timeline.status} />
          </div>
        </li>
      ))}
    </ol>
  );
};

export default Timeline;

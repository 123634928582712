import useUrlState from '@ahooksjs/use-url-state';
import {
  AccountNumberIcon,
  CheckMark,
  UserLogo,
  XIcon,
  XTagIcon,
} from 'assets';
import { Button, OrderItems } from 'components';
import type { FC } from 'react';
import React, { useState } from 'react';
import secureLocalStorage from 'react-secure-storage';
import { useLogoutOnrampCustomer } from 'services';
import type { OnrampSessionType, OnrampUrlState } from 'services/types';
import { ENCRYPTED_ONRAMP_SESSION_TOKEN } from 'utils';

type OnrampUserInfoProps = {
  setDisplayUserInfo: React.Dispatch<React.SetStateAction<boolean>>;
  displayUserInfo: boolean;
  onrampSession: OnrampSessionType;
};

const OnrampUserInfo: FC<OnrampUserInfoProps> = ({
  displayUserInfo,
  setDisplayUserInfo,
  onrampSession,
}) => {
  const [_, setUrlStep] = useUrlState<OnrampUrlState>();
  const { logoutOnrampCustomer, isLoginOutOnrampCustomer } =
    useLogoutOnrampCustomer();

  const [showOrdersHistory, setShowOrdersHistory] = useState(false);

  const handleLogout = () => {
    logoutOnrampCustomer(undefined, {
      onSuccess: () => {
        // localStorage.removeItem(ONRAMP_USER_SESSION);
        secureLocalStorage.removeItem(ENCRYPTED_ONRAMP_SESSION_TOKEN);

        setUrlStep({ step: undefined });
        window.location.reload();
      },
      onError: () => {
        // localStorage.removeItem(ONRAMP_USER_SESSION);
        secureLocalStorage.removeItem(ENCRYPTED_ONRAMP_SESSION_TOKEN);
        setUrlStep({ step: undefined });
        window.location.reload();
      },
    });
  };

  return (
    <div
      onClick={() => setDisplayUserInfo(false)}
      className={`absolute bg-white dark:bg-bg-dark bg-opacity-50 dark:bg-opacity-50 top-0 left-0 rounded-[20px] overflow-hidden w-full h-full transition-transform duration-500 ease-in-out ${displayUserInfo ? '' : '-translate-y-full'}`}>
      <div
        onClick={(e) => e.stopPropagation()}
        className="overflow-hidden min-h-[200px] px-5 bg-white dark:bg-bg-dark shadow-lg shadow-gray-300 dark:shadow-black rounded-xl">
        <div className="mt-16 flex flex-col">
          <div className="flex items-center gap-2">
            <UserLogo className="dark:stroke-white stroke-black" />
            <p>{onrampSession?.email || ''}</p>
          </div>

          <div className="flex gap-2 mt-2 items-center">
            {onrampSession?.isVerified ? (
              <div className="p-1">
                <CheckMark className="h-3 w-3 " strokeWidth={2} />{' '}
              </div>
            ) : (
              <div className="p-1">
                <XTagIcon className="stroke-error-700" width={12} height={12} />
              </div>
            )}
            <p>Identity verified</p>
          </div>

          <div className="flex gap-2 mt-2 items-center">
            <Button
              name="Orders history"
              variant="secondary"
              className="border-none p-0 h-fit"
              iconBefore={
                <AccountNumberIcon className="dark:stroke-white stroke-black" />
              }
              onClick={() => setShowOrdersHistory(true)}
            />
          </div>
        </div>

        <div className="absolute px-5 py-2 top-2 left-2 flex justify-between w-full items-center">
          <div onClick={() => setDisplayUserInfo(false)}>
            <XIcon className="stroke-black dark:stroke-white  cursor-pointer" />
          </div>

          <Button
            name="Log Out"
            variant="secondary"
            size="small"
            isLoading={isLoginOutOnrampCustomer}
            onClick={() => handleLogout()}
          />
        </div>
      </div>

      <OrderItems
        setShowOrdersHistory={setShowOrdersHistory}
        showOrdersHistory={showOrdersHistory}
      />
    </div>
  );
};

export default OnrampUserInfo;

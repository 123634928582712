import { Button } from 'components';
import { useOnrampInitialData } from 'providers/OnrampDataProvider';
import type { FC } from 'react';
import React from 'react';
import { useConvertOnrampAsset, useUpdateOnrampProgress } from 'services';
import type {
  OnrampPaymentInstitution,
  PayByBnkTransferResponse,
} from 'services/types';
import { getUrlParams } from 'utils';

type PaymentSummaryProps = {
  setSelectedInstitution: React.Dispatch<
    React.SetStateAction<OnrampPaymentInstitution | undefined>
  >;
  selectedInstitution: OnrampPaymentInstitution | undefined;
  bankDetails?: PayByBnkTransferResponse;
  handleInitiatePayment: (institution: OnrampPaymentInstitution) => void;
  isInitiatingOnrampPayment: boolean;
};

const PaymentSummary: FC<PaymentSummaryProps> = ({
  selectedInstitution,
  bankDetails,
  setSelectedInstitution,
  handleInitiatePayment,
  isInitiatingOnrampPayment,
}) => {
  const { onrampData, isLoading, onrampProgress } = useOnrampInitialData();
  const { updateOnrampProgress } = useUpdateOnrampProgress();
  const initialData = getUrlParams('data');

  const { data: convertData, isLoading: isLoadingConvert } =
    useConvertOnrampAsset({
      amount: onrampData?.data?.source_amount || '',
      from: onrampData?.data?.source_currency || '',
      to: onrampData?.data?.destination_currency || '',
    });

  const paymentSum =
    (convertData?.fees?.[0]?.converted_amount || 0) +
    (convertData?.fees?.[1]?.converted_amount || 0) +
    parseFloat(onrampData?.data?.source_amount || '0');

  return (
    <div className="flex flex-col">
      <div className="text-center border-b border-borderLight dark:border-borderDark pb-2">
        <h1 className="text-xl">Payment Summary</h1>
        <h2 className="mt-2 text-gray-700 dark:text-gray-300">
          We have partnered with Yapily Connect to securely initiate payment
          from your account at{' '}
          <span className="text-primary">{selectedInstitution?.full_name}</span>
        </h2>
      </div>
      <div>
        <h2 className="text-center mt-8 mb-2 text-2xl">
          Payment total is{' '}
          <span className="text-primary">
            {isLoadingConvert || isLoading ? (
              <span className="animate-pulse"> ...</span>
            ) : (
              paymentSum.toFixed(2) + ' ' + onrampData?.data?.source_currency
            )}
          </span>{' '}
        </h2>
        <h2 className="text-center mb-8 text-2xl">
          Receive{' '}
          <span className="text-primary">
            {isLoadingConvert || isLoading ? (
              <span className="animate-pulse"> ...</span>
            ) : (
              parseFloat(convertData?.converted_amount || '0')?.toFixed(8) +
              ' ' +
              onrampData?.data?.destination_currency
            )}
          </span>
        </h2>
      </div>

      <div className="border-b border-borderLight dark:border-borderDark pb-8">
        <p className="text-lg text-primary">Payee details</p>

        <div className="mt-2">
          <p>Payee Name</p>
          <p className="ml-2 text-gray-700 dark:text-gray-300">
            {bankDetails?.beneficiary}
          </p>
        </div>
        <div className="mt-2">
          <p>Account Number</p>
          <p className="ml-2 text-gray-700 dark:text-gray-300">
            {bankDetails?.account_number}
          </p>
        </div>
        <div className="mt-2">
          <p>Sort Code</p>
          <p className="ml-2 text-gray-700 dark:text-gray-300">
            {bankDetails?.bic}
          </p>
        </div>
      </div>

      <div className="mt-14">
        <div className="flex gap-5 w-full justify-center">
          <Button
            name="Cancel"
            variant="secondary"
            className="w-52"
            onClick={() => {
              if (setSelectedInstitution) {
                if (onrampProgress?.data.order_id && initialData) {
                  updateOnrampProgress({
                    on_ramp_data: initialData,
                    order_id: onrampProgress?.data.order_id,
                    payment_method: JSON.stringify({
                      payment_method: 'yapily',
                    }),
                  });
                }

                setSelectedInstitution(undefined);
              }
            }}
          />
          <Button
            name="Confirm"
            variant="primary"
            className="w-52"
            onClick={() => {
              if (selectedInstitution) {
                handleInitiatePayment(selectedInstitution);
              }
            }}
            isLoading={isInitiatingOnrampPayment}
          />
        </div>

        <p className="text-sm text-gray-700 dark:text-gray-300 mx-5 text-center mt-5">
          By using the service, you agree to Yapily’s initiating this payment
          and its{' '}
          <span
            className="underline text-gray-800 dark:text-gray-200 cursor-pointer"
            onClick={() =>
              window.open(
                'https://drive.google.com/file/d/11mNukqbBA_EbEBJd7bn9Idj1iiG8QWIL/view?pli=1',
                '_blank',
              )
            }>
            Terms & Conditions
          </span>{' '}
          and{' '}
          <span
            className="underline text-gray-800 dark:text-gray-200 cursor-pointer"
            onClick={() =>
              window.open(
                'https://www.yapily.com/legal/privacy-policy',
                '_blank',
              )
            }>
            Privacy Policy.
          </span>
        </p>
      </div>
    </div>
  );
};

export default PaymentSummary;

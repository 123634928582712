import type { FC } from 'react';
import React from 'react';

type BillingInformationItemProps = {
  thick?: boolean;
  title: string;
  details?: string;
};

const BillingInformationItem: FC<BillingInformationItemProps> = ({
  thick,
  title,
  details,
}) => {
  return (
    <div className="flex justify-between mt-2">
      <p className={`text-lg ${thick && 'font-semibold'}`}>{title}</p>
      <p className={`text-lg ${thick && 'font-semibold'}`}>{details}</p>
    </div>
  );
};

export default BillingInformationItem;

import * as React from 'react';
import type { SVGProps } from 'react';
const FindIconSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    // width="200px"
    // height="200px"
    viewBox="0 0 100 100"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      style={{
        fill: '#555555',
        stroke: 'fill',
        strokeWidth: 2,
      }}
      d="m 63,53 c 0,0 38,26 34,43 C 81,103 53,61 53,61"
    />
    <circle
      cx={36}
      cy={36}
      r={32}
      fill="none"
      stroke="#222222"
      style={{
        strokeWidth: 2,
      }}
    />
    <circle
      cx={36}
      cy={36}
      r={29}
      fill="none"
      stroke="fill" //<---
      style={{
        strokeWidth: 6,
      }}
    />
    <circle
      cx={36}
      cy={36}
      r={27}
      fill="#eeeeee"
      stroke="#222222"
      style={{
        strokeWidth: 1,
        fillOpacity: 0.7,
      }}
    />
  </svg>
);

const FindIcon = React.memo(FindIconSvg);

export default FindIcon;

import * as React from 'react';
import type { SVGProps } from 'react';
const PaymentRejectedIconSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={116}
    height={116}
    viewBox="0 0 116 116"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M6.70556 73.0749L2.22284 73.9617C2.00482 73.1982 1.80205 72.4283 1.61488 71.6523C1.42909 70.8819 1.25867 70.1056 1.10397 69.3236C0.37972 65.6627 0 61.8778 0 58.0043C0 54.1308 0.37972 50.346 1.10397 46.685C1.25867 45.903 1.42909 45.1267 1.61488 44.3563C1.80205 43.5803 2.00482 42.8104 2.22285 42.0469L6.70556 42.9337L5.78042 47.6101C5.11613 50.968 4.76709 54.4431 4.76709 58.0043C4.76709 61.5656 5.11612 65.0406 5.78042 68.3985L6.70556 73.0749Z"
      fill="#FF4405"
      fillOpacity={0.1}
    />
    <path
      d="M32.3847 104.922L29.8418 108.721C29.1453 108.333 28.4577 107.932 27.7792 107.517C27.1006 107.102 26.4312 106.673 25.7714 106.232C19.4464 101.997 14.0008 96.5515 9.7664 90.2266C9.32465 89.5667 8.89607 88.8973 8.48105 88.2187C8.06614 87.5403 7.66478 86.8527 7.27734 86.1562L11.0757 83.6133L13.7277 87.5746C17.6155 93.3817 22.6162 98.3825 28.4234 102.27L32.3847 104.922Z"
      fill="#FF4405"
      fillOpacity={0.17}
    />
    <path
      d="M73.0749 109.297L73.9617 113.78C73.1982 113.998 72.4283 114.2 71.6523 114.388C70.8819 114.573 70.1056 114.744 69.3236 114.898C65.6627 115.623 61.8778 116.002 58.0043 116.002C54.1308 116.002 50.346 115.623 46.685 114.898C45.903 114.744 45.1267 114.573 44.3563 114.388C43.5803 114.2 42.8104 113.998 42.0469 113.78L42.9337 109.297L47.6101 110.222C50.968 110.886 54.4431 111.235 58.0043 111.235C61.5656 111.235 65.0406 110.886 68.3985 110.222L73.0749 109.297Z"
      fill="#FF4405"
      fillOpacity={0.3}
    />
    <path
      d="M104.922 83.6133L108.721 86.1562C108.333 86.8527 107.932 87.5403 107.517 88.2187C107.102 88.8973 106.673 89.5668 106.232 90.2266C101.997 96.5515 96.5515 101.997 90.2266 106.232C89.5668 106.673 88.8973 107.102 88.2187 107.517C87.5403 107.932 86.8527 108.333 86.1562 108.721L83.6133 104.922L87.5746 102.27C93.3817 98.3825 98.3825 93.3817 102.27 87.5746L104.922 83.6133Z"
      fill="#FF4405"
      fillOpacity={0.4}
    />
    <path
      d="M114.384 71.6523C114.569 70.8819 114.74 70.1056 114.895 69.3236C115.619 65.6627 115.999 61.8778 115.999 58.0043C115.999 54.1308 115.619 50.3459 114.895 46.685C114.74 45.903 114.569 45.1267 114.384 44.3563C114.196 43.5803 113.994 42.8104 113.776 42.0469L109.293 42.9337L110.218 47.6101C110.882 50.968 111.231 54.4431 111.231 58.0043C111.231 61.5656 110.882 65.0406 110.218 68.3985L109.293 73.0749L113.776 73.9617C113.994 73.1982 114.196 72.4283 114.384 71.6523Z"
      fill="#FF4405"
      fillOpacity={0.6}
    />
    <path
      d="M83.6133 11.0757L86.1562 7.27734C86.8527 7.66478 87.5403 8.06614 88.2187 8.48105C88.8973 8.89607 89.5667 9.32465 90.2266 9.7664C96.5515 14.0008 101.997 19.4464 106.232 25.7714C106.673 26.4312 107.102 27.1006 107.517 27.7792C107.932 28.4577 108.333 29.1453 108.721 29.8418L104.922 32.3847L102.27 28.4234C98.3825 22.6162 93.3817 17.6155 87.5746 13.7277L83.6133 11.0757Z"
      fill="#FF4405"
      fillOpacity={0.8}
    />
    <path
      d="M42.9337 6.70556L42.0469 2.22284C42.8104 2.00482 43.5803 1.80205 44.3563 1.61488C45.1267 1.42909 45.903 1.25867 46.685 1.10397C50.3459 0.37972 54.1308 0 58.0043 0C61.8778 0 65.6627 0.37972 69.3236 1.10397C70.1056 1.25867 70.8819 1.42909 71.6523 1.61488C72.4283 1.80205 73.1982 2.00482 73.9617 2.22285L73.0749 6.70556L68.3985 5.78042C65.0406 5.11613 61.5656 4.76709 58.0043 4.76709C54.4431 4.76709 50.968 5.11612 47.6101 5.78042L42.9337 6.70556Z"
      fill="#FF4405"
      fillOpacity={0.8}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.27734 29.8418L11.0757 32.3847L13.7277 28.4234C17.6155 22.6163 22.6163 17.6155 28.4234 13.7277L32.3847 11.0757L29.8418 7.27734C29.1453 7.66478 28.4577 8.06614 27.7792 8.48106C27.1006 8.89607 26.4312 9.32465 25.7714 9.7664C19.4464 14.0008 14.0008 19.4464 9.7664 25.7714C9.32465 26.4312 8.89607 27.1006 8.48106 27.7792C8.06614 28.4577 7.66478 29.1453 7.27734 29.8418Z"
      fill="#FF4405"
      fillOpacity={0.4}
    />
    <path
      d="M78.3846 76.0756C78.5797 76.274 78.7345 76.5097 78.8401 76.769C78.9457 77.0283 79 77.3062 79 77.5869C79 77.8675 78.9457 78.1455 78.8401 78.4048C78.7345 78.6641 78.5797 78.8997 78.3846 79.0981C78.1895 79.2966 77.9579 79.454 77.703 79.5614C77.4481 79.6689 77.1749 79.7241 76.8989 79.7241C76.623 79.7241 76.3498 79.6689 76.0949 79.5614C75.84 79.454 75.6084 79.2966 75.4133 79.0981L58 61.382L40.5867 79.0981C40.1927 79.499 39.6583 79.7241 39.1011 79.7241C38.5438 79.7241 38.0094 79.499 37.6154 79.0981C37.2214 78.6973 37 78.1537 37 77.5869C37 77.02 37.2214 76.4764 37.6154 76.0756L55.0313 58.3621L37.6154 40.6486C37.2214 40.2477 37 39.7041 37 39.1373C37 38.5704 37.2214 38.0268 37.6154 37.626C38.0094 37.2252 38.5438 37 39.1011 37C39.6583 37 40.1927 37.2252 40.5867 37.626L58 55.3422L75.4133 37.626C75.8073 37.2252 76.3417 37 76.8989 37C77.4562 37 77.9906 37.2252 78.3846 37.626C78.7786 38.0268 79 38.5704 79 39.1373C79 39.7041 78.7786 40.2477 78.3846 40.6486L60.9687 58.3621L78.3846 76.0756Z"
      fill="#D92D20"
    />
  </svg>
);

const PaymentRejectedIcon = React.memo(PaymentRejectedIconSvg);
export default PaymentRejectedIcon;

import { useCallback, useEffect, useRef, useState } from 'react';

export default function useCountdownTimer(delay = 30, callback: () => void) {
  const Ref = useRef<NodeJS.Timer>();

  const [countdown, setCountdown] = useState(delay);

  const clearTimer = useCallback(() => {
    setCountdown(delay);

    if (Ref.current) {
      clearInterval(Ref.current);
    }
    Ref.current = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);
  }, [delay]);

  useEffect(() => {
    clearTimer();
  }, [clearTimer]);

  useEffect(() => {
    if (countdown === 1) {
      setTimeout(() => {
        clearTimer();
        callback?.();
      }, 1000);
    }
  }, [countdown, clearTimer, callback]);

  return {
    countdown,
    clearTimer,
  };
}

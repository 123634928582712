import type { FormikErrors } from 'formik';
import type { FC } from 'react';
import React from 'react';

type SwitchProps = {
  name?: string;
  checked?: boolean;
  onChange?: {
    (e: React.ChangeEvent<HTMLInputElement>): void;
    <T = string | React.ChangeEvent<unknown>>(
      field: T,
    ): T extends React.ChangeEvent<unknown>
      ? void
      : (e: string | React.ChangeEvent<unknown>) => void;
  };
  handleBlur?: {
    (e: React.FocusEvent<unknown, Element>): void;
    <T = unknown>(
      fieldOrEvent: T,
    ): T extends string ? (e: unknown) => void : void;
  };
  setFieldValue?: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => Promise<void | FormikErrors<any>>;
};

const Switch: FC<SwitchProps> = ({
  name,
  checked,
  handleBlur,
  onChange,
  setFieldValue,
}) => {
  return (
    <label className="inline-flex items-center cursor-pointer">
      <input
        name={name}
        type="checkbox"
        checked={checked}
        className="sr-only peer"
        onChange={(e) => {
          if (setFieldValue)
            setFieldValue('send_emails_to_customers', e.target.checked);
          if (onChange) onChange(e);
        }}
        onBlur={handleBlur}
      />
      <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-1 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-primary"></div>
    </label>
  );
};

export default Switch;

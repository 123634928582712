import * as React from 'react';
import type { SVGProps } from 'react';
const AccountNumberIconSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    {...props}>
    <path
      d="M11.6668 1.89258V5.3347C11.6668 5.80141 11.6668 6.03476 11.7577 6.21302C11.8376 6.36982 11.965 6.49731 12.1218 6.5772C12.3001 6.66803 12.5335 6.66803 13.0002 6.66803H16.4423M11.6668 14.168H6.66683M13.3335 10.8346H6.66683M16.6668 8.32482V14.3346C16.6668 15.7348 16.6668 16.4348 16.3943 16.9696C16.1547 17.44 15.7722 17.8225 15.3018 18.0622C14.767 18.3346 14.067 18.3346 12.6668 18.3346H7.3335C5.93336 18.3346 5.2333 18.3346 4.69852 18.0622C4.22811 17.8225 3.84566 17.44 3.60598 16.9696C3.3335 16.4348 3.3335 15.7348 3.3335 14.3346V5.66797C3.3335 4.26784 3.3335 3.56777 3.60598 3.03299C3.84566 2.56259 4.22811 2.18014 4.69852 1.94045C5.2333 1.66797 5.93336 1.66797 7.3335 1.66797H10.01C10.6215 1.66797 10.9272 1.66797 11.2149 1.73704C11.47 1.79829 11.7139 1.8993 11.9375 2.03637C12.1898 2.19097 12.406 2.40716 12.8384 2.83954L15.4953 5.4964C15.9276 5.92878 16.1438 6.14497 16.2984 6.39726C16.4355 6.62094 16.5365 6.8648 16.5978 7.11989C16.6668 7.40761 16.6668 7.71335 16.6668 8.32482Z"
      stroke="fill"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const AccountNumberIcon = React.memo(AccountNumberIconSvg);
export default AccountNumberIcon;

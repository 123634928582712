import { useMutation, useQuery } from '@tanstack/react-query';
import type { AxiosError } from 'axios';
import client from '../client';
import { API_ENDPOINTS } from '../client/api-endpoints';
import type {
  CreateInvoiceData,
  CreateInvoiceResponse,
  GeneralError,
  GetInvoiceDetailsParams,
  InvoiceData,
  PaginationParams,
  InvoicesListResponse,
  PaginatedInvoicesResponse,
} from '../types';

export function useGetInvoicesList() {
  const query = useQuery<InvoicesListResponse, AxiosError<GeneralError>>({
    queryKey: [API_ENDPOINTS.GET_INVOICES_LIST],
    queryFn: client.invoice.getInvoicesList,
  });

  return query;
}

export function useGetPaginatedInvoices(data: PaginationParams) {
  const query = useQuery<PaginatedInvoicesResponse, AxiosError<GeneralError>>({
    queryKey: [API_ENDPOINTS.GET_PAGINATED_INVOICES(data)],
    queryFn: () => client.invoice.getPaginatedInvoices(data),
  });

  return query;
}

export function useGetInvoiceDetails({ id }: GetInvoiceDetailsParams) {
  const query = useQuery<InvoiceData, AxiosError<GeneralError>>({
    queryKey: [API_ENDPOINTS.GET_INVOICE_DETAILS, id],
    queryFn: () => client.invoice.getInvoiceDetails(id),
  });

  return query;
}

export function useCreateNewInvoice() {
  const { mutate, isPending } = useMutation<
    CreateInvoiceResponse,
    AxiosError<GeneralError>,
    CreateInvoiceData
  >({
    mutationFn: client.invoice.createNewInvoice,
  });

  return {
    createNewInvoice: mutate,
    isCreatingInvoice: isPending,
  };
}

import React, { useEffect } from 'react';
import Header from '../Header/Header';
import useDarkSide from 'hooks/useDarkSide';
import { LogOutModal } from '../LogOutModal';
import { useNavigate } from 'react-router-dom';
import { getEncryptedTokenFromStorage } from '../../services/storage';

export default function SingedInLayout({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) {
  useDarkSide();

  const accessToken = getEncryptedTokenFromStorage();

  const navigate = useNavigate();
  useEffect(() => {
    if (!accessToken) {
      navigate('/sso/niza/callback');
    }
  }, [accessToken, navigate]);
  return (
    <div
      lang="en"
      className="dark:bg-bg-dark dark:text-white p-5 md:px-6 md:pt-8  lg:px-14 lg:pt-16  flex flex-col md:flex-row w-full overflow-hidden h-[100vh]  ease-linear transition-all duration-100">
      <Header />
      <main className="w-full overflow-x-auto bg-bg-secondaryLight dark:bg-bg-secondaryDark p-4 md:p-8 lg:px-16 lg:py-14 rounded-2xl mb-10 min-h-[50vh]">
        {children}
      </main>
      <LogOutModal />
    </div>
  );
}

import * as React from 'react';
import type { SVGProps } from 'react';
const DocumentIconSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <g clipPath="url(#clip0_81_62)">
      <path
        d="M7 21C5.89543 21 5 20.1046 5 19V3H14L19 8V19C19 20.1046 18.1046 21 17 21H7Z"
        stroke="fill"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 3V9H19"
        stroke="fill"
        strokeWidth={2}
        strokeLinejoin="round"
      />
      <path
        d="M9 13H15"
        stroke="#1DA069"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 17H15"
        stroke="#1DA069"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_81_62">
        <rect width={24} height={24} fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const DocumentIcon = React.memo(DocumentIconSvg);

export default DocumentIcon;

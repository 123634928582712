import * as React from 'react';
import type { SVGProps } from 'react';
const CurlyArrowsSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={159}
    height={64}
    viewBox="0 0 159 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M56.6028 27.6355L56.596 28.1355L56.6028 27.6355ZM154.705 11.7109C154.957 11.5976 155.069 11.3016 154.956 11.0497L153.11 6.94592C152.996 6.69408 152.7 6.58178 152.449 6.69507C152.197 6.80836 152.084 7.10436 152.198 7.35619L153.839 11.004L150.191 12.6451C149.939 12.7584 149.827 13.0544 149.94 13.3063C150.053 13.5581 150.349 13.6704 150.601 13.5571L154.705 11.7109ZM1.45776 80.9557C3.58604 76.1119 9.55844 62.7724 18.9323 50.7027C28.32 38.6153 41.0233 27.9232 56.596 28.1355L56.6096 27.1356C40.5498 26.9167 27.5827 37.9344 18.1425 50.0893C8.68859 62.2621 2.67583 75.6976 0.542238 80.5535L1.45776 80.9557ZM56.596 28.1355C61.4969 28.2023 64.7443 29.0815 66.7259 30.4187C68.6734 31.7327 69.4321 33.5095 69.3311 35.5324C69.2283 37.5912 68.2299 39.9336 66.5767 42.2841C64.9287 44.6272 62.6576 46.9384 60.0741 48.9283C54.88 52.9291 48.5549 55.5353 43.601 54.6595C41.1527 54.2267 39.0243 52.9429 37.4946 50.5139C35.9545 48.0684 35 44.4266 35 39.2546H34C34 44.534 34.9719 48.3847 36.6484 51.0468C38.3354 53.7255 40.714 55.1646 43.4269 55.6442C48.7956 56.5934 55.41 53.7831 60.6843 49.7206C63.3349 47.6789 65.6801 45.2969 67.3946 42.8595C69.1038 40.4295 70.214 37.9042 70.3299 35.5823C70.4475 33.2245 69.535 31.1078 67.2853 29.5897C65.0697 28.0948 61.5939 27.2035 56.6096 27.1356L56.596 28.1355ZM35 39.2546C35 31.8797 39.9097 25.5317 48.0718 20.3688C56.2255 15.2112 67.5297 11.3032 80.0766 8.79779C105.18 3.78504 135.078 4.42032 154.323 11.7224L154.677 10.7874C135.199 3.39684 105.097 2.78194 79.8808 7.81715C67.2682 10.3357 55.833 14.2762 47.5372 19.5237C39.2498 24.7658 34 31.3794 34 39.2546H35Z"
      fill="fill"
    />
    <path
      d="M60.5882 32.5718L60.6019 31.5719L60.5882 32.5718ZM36.5 43.7549L35.5715 44.1263L36.5 43.7549ZM158.445 10.1504C158.94 9.90466 159.141 9.30448 158.896 8.80989L154.89 0.750183C154.645 0.255599 154.044 0.0538993 153.55 0.299674C153.055 0.545449 152.854 1.14563 153.099 1.64021L156.659 8.8044L149.495 12.3645C149.001 12.6103 148.799 13.2105 149.045 13.7051C149.291 14.1996 149.891 14.4013 150.385 14.1556L158.445 10.1504ZM5.91574 86.1566C8.04082 81.313 13.9915 68.0052 23.323 55.9726C32.6823 43.904 45.2503 33.3626 60.5746 33.5717L60.6019 31.5719C44.302 31.3495 31.2062 42.5439 21.7426 54.7469C12.2512 66.9858 6.21992 80.4853 4.08426 85.3531L5.91574 86.1566ZM60.5746 33.5717C65.4266 33.638 68.8189 34.4563 71.0615 35.6787C73.2734 36.8845 74.3656 38.4771 74.7054 40.2032C75.0528 41.968 74.6407 44.0173 73.5474 46.1316C72.4577 48.2389 70.7238 50.3428 68.5519 52.1689C64.1917 55.8348 58.2329 58.2494 52.5115 57.482C46.8711 56.7255 41.2111 52.84 37.4285 43.3835L35.5715 44.1263C39.5589 54.0946 45.7361 58.5911 52.2457 59.4642C58.6743 60.3265 65.1783 57.6183 69.839 53.6997C72.1775 51.7335 74.0935 49.4297 75.324 47.0502C76.5508 44.6776 77.1291 42.1609 76.6677 39.8169C76.1987 37.4342 74.6831 35.3751 72.0187 33.9227C69.3849 32.4869 65.6324 31.6406 60.6019 31.5719L60.5746 33.5717ZM37.4285 43.3835C35.7441 39.1726 35.4062 35.2333 36.1775 31.5899C36.95 27.9411 38.8487 24.5257 41.7353 21.3935C47.5266 15.1095 57.2318 10.0374 69.3929 6.65585C93.6955 -0.10177 127.366 0.0149304 157.681 10.2028L158.319 8.307C127.634 -2.00511 93.5545 -2.13842 68.8571 4.72895C56.5182 8.15993 46.4109 13.369 40.2647 20.0381C37.1825 23.3825 35.0813 27.1117 34.2209 31.1757C33.3594 35.2453 33.7559 39.5871 35.5715 44.1263L37.4285 43.3835Z"
      fill="#1DA069"
    />
  </svg>
);

const CurlyArrows = React.memo(CurlyArrowsSvg);
export default CurlyArrows;

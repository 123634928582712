import type { FC, ReactElement } from 'react';
import React from 'react';
import { CheckMark } from 'assets';

export type PaymentMethodItem = {
  methodLogo: ReactElement;
  name?: string;
  onClick?: () => void;
  value:
    | 'pay_by_card'
    | 'pay_by_bank'
    | 'bank_transfer_sepa'
    | 'bank_transfer_swift';
  selectedPayment: string | undefined;
};

const PaymentMethodItem: FC<PaymentMethodItem> = ({
  methodLogo,
  name,
  onClick,
  value,
  selectedPayment,
}) => {
  return (
    <div
      className={`flex justify-between py-[13px] px-[16px] items-center border rounded-[10px] my-5 cursor-pointer hover:bg-primary hover:bg-opacity-5 ${selectedPayment === value ? 'bg-primary bg-opacity-10 border-primary' : 'border-gray-300  dark:border-gray-600 '}`}
      onClick={onClick}>
      <div className="flex items-center gap-2 font-medium">
        {methodLogo} {name}
      </div>
      <div
        className={`p-1 rounded-full ${selectedPayment === value && 'bg-primary'} `}>
        <CheckMark
          className={`h-4 w-4  ${selectedPayment === value && 'stroke-white'}`}
          stroke="fill"
        />
      </div>
    </div>
  );
};

export default PaymentMethodItem;

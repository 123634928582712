import type { AxiosRequestConfig } from 'axios';
import axios from 'axios';
import {
  getEncryptedOnrampTokenFromStorage,
  getEncryptedTokenFromStorage,
  removeEncryptedOnrampTokenFromStorage,
  removeEncryptedTokenFromStorage,
} from '../storage';
import { environment, getUrlParams } from 'utils';

const accessToken = getEncryptedTokenFromStorage();
const onrampSession = getEncryptedOnrampTokenFromStorage();

const headers = {
  'Content-Type': 'application/json',
  Authorization: `Bearer ${accessToken}`,
  'X-Customer-Session': onrampSession,
  'X-Reference-Session': getUrlParams('ref'),
  'X-Livemode': getUrlParams('livemode') === 'true' ? 1 : 0,
};

const baseURL = environment.apiUrl;

const currentUrl = window.location.href;
const queryString = currentUrl.split('?')[1];
const params = new URLSearchParams(queryString);

export const AxiosInstance = axios.create({
  baseURL: baseURL,
  timeout: 30000,
  headers: headers,
});

AxiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },

  (error) => {
    if (error?.response?.status === 401) {
      if (accessToken) {
        removeEncryptedTokenFromStorage();
        window.location.reload();
      }
      if (onrampSession || !!params.get('step')) {
        removeEncryptedOnrampTokenFromStorage();
        params.set('step', '');

        const newQueryString = params.toString();

        // Update the URL with the modified query string
        const newUrl =
          window.location.origin +
          window.location.pathname +
          '?' +
          newQueryString;

        // Redirect to the new URL
        window.location.href = newUrl;
      }
    }

    return Promise.reject(error);
  },
);

export class HttpClient {
  static async get<T>(url: string, params?: unknown) {
    const response = await AxiosInstance.get<T>(url, { params });

    return response.data;
  }

  static async post<T>(
    url: string,
    data: unknown,
    options?: AxiosRequestConfig<unknown> | undefined,
  ) {
    const response = await AxiosInstance.post<T>(url, data, options);

    return response.data;
  }

  static async put<T>(url: string, data: unknown) {
    const response = await AxiosInstance.put<T>(url, data);

    return response.data;
  }

  static async patch<T>(url: string, data: unknown) {
    const response = await AxiosInstance.patch<T>(url, data);

    return response.data;
  }

  static async delete<T>(url: string) {
    const response = await AxiosInstance.delete<T>(url);

    return response.data;
  }
}

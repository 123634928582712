import type { FC } from 'react';
import React from 'react';
import {
  BankTransferSEPA,
  PayByBank,
  PayByCard,
  BankTransferSWIFT,
} from 'assets';

import { Button, Input } from 'components';

const FeeManagement: FC = () => {
  return (
    <div>
      <p className="font-medium text-base">Payment Methods</p>

      <div className="flex gap-5 flex-wrap mt-5">
        <div className="flex justify-between py-3 px-5 rounded-xl bg-white dark:bg-bg-dark max-w-[500px] w-full ">
          <div className="flex gap-2 items-center">
            <PayByCard />

            <p>Pay By Card</p>
          </div>

          <div className="flex gap-2 items-center">
            <p className="whitespace-nowrap">Fee %:</p>
            <Input type="text" className="max-w-[76px]" />
          </div>
        </div>

        <div className="flex justify-between py-3 px-5  rounded-xl bg-white dark:bg-bg-dark max-w-[500px] w-full">
          <div className="flex gap-2 items-center">
            <PayByBank />

            <p>Pay By Bank</p>
          </div>

          <div className="flex gap-2 items-center">
            <p className="whitespace-nowrap">Fee %:</p>
            <Input type="text" className="max-w-[76px]" />
          </div>
        </div>
      </div>

      <div className="flex gap-5 flex-wrap mt-5">
        <div className="flex justify-between py-3 px-5 p-2 rounded-xl bg-white dark:bg-bg-dark max-w-[500px] w-full ">
          <div className="flex gap-2 items-center">
            <BankTransferSEPA />

            <p>Bank Transfer (SEPA)</p>
          </div>

          <div className="flex gap-2 items-center">
            <p className="whitespace-nowrap">Fee %:</p>
            <Input type="text" className="max-w-[76px]" />
          </div>
        </div>

        <div className="flex justify-between py-3 px-5  rounded-xl bg-white dark:bg-bg-dark max-w-[500px] w-full">
          <div className="flex gap-2 items-center">
            <BankTransferSWIFT />
            <p>Bank Transfer (SWIFT)</p>
          </div>

          <div className="flex gap-2 items-center">
            <p className="whitespace-nowrap">Fee %:</p>
            <Input type="text" className="max-w-[76px]" />
          </div>
        </div>
      </div>

      <Button name="Apply Fees" className="mt-5" variant="primary" />
    </div>
  );
};

export default FeeManagement;

import { useMutation, useQuery } from '@tanstack/react-query';
import type { AxiosError } from 'axios';
import { API_ENDPOINTS } from '../client/api-endpoints';
import client from '../client';
import type {
  GeneralError,
  GenerateOnrampLinkData,
  GenerateOnrampLinkResponse,
  GetProductsResponse,
} from '../types';

export function useGetProductsList() {
  const query = useQuery<GetProductsResponse, AxiosError<GeneralError>>({
    queryKey: [API_ENDPOINTS.GET_PRODUCTS_LIST],
    queryFn: client.product.getProductsList,
  });

  return query;
}

export function useGenerateOnrampLink() {
  const { mutate: createLink, isPending: isCreatingOnrampLink } = useMutation<
    GenerateOnrampLinkResponse,
    AxiosError<GeneralError>,
    GenerateOnrampLinkData
  >({
    mutationFn: client.product.generateOnrampLink,
  });

  return {
    createLink,
    isCreatingOnrampLink,
  };
}

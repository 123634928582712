import * as React from 'react';
import type { SVGProps } from 'react';
const File05IconSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={20}
    viewBox="0 0 16 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M9.66536 1.8913V5.33341C9.66536 5.80012 9.66536 6.03348 9.75619 6.21174C9.83609 6.36854 9.96357 6.49602 10.1204 6.57592C10.2986 6.66675 10.532 6.66675 10.9987 6.66675H14.4408M9.66536 14.1667H4.66536M11.332 10.8334H4.66536M14.6654 8.32354V14.3334C14.6654 15.7335 14.6654 16.4336 14.3929 16.9683C14.1532 17.4387 13.7707 17.8212 13.3003 18.0609C12.7656 18.3334 12.0655 18.3334 10.6654 18.3334H5.33203C3.9319 18.3334 3.23183 18.3334 2.69705 18.0609C2.22665 17.8212 1.8442 17.4387 1.60451 16.9683C1.33203 16.4336 1.33203 15.7335 1.33203 14.3334V5.66669C1.33203 4.26656 1.33203 3.56649 1.60451 3.03171C1.8442 2.56131 2.22665 2.17885 2.69705 1.93917C3.23183 1.66669 3.9319 1.66669 5.33203 1.66669H8.00851C8.61999 1.66669 8.92572 1.66669 9.21344 1.73576C9.46853 1.797 9.7124 1.89801 9.93608 2.03509C10.1884 2.18969 10.4046 2.40588 10.8369 2.83826L13.4938 5.49511C13.9262 5.92749 14.1424 6.14368 14.297 6.39597C14.434 6.61966 14.535 6.86352 14.5963 7.11861C14.6654 7.40633 14.6654 7.71206 14.6654 8.32354Z"
      stroke="#1DA069"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const File05Icon = React.memo(File05IconSVG);
export default File05Icon;

import dayjs from 'dayjs';
import type { CustomFlowbiteTheme } from 'flowbite-react';
import { Datepicker } from 'flowbite-react';
import type { FormikErrors } from 'formik';
import type { FC } from 'react';
import React from 'react';
import { Skeleton } from '../../Skeleton';

type DateInputProps = {
  onChange?: {
    (e: React.ChangeEvent<HTMLInputElement>): void;
    <T = string | React.ChangeEvent<unknown>>(
      field: T,
    ): T extends React.ChangeEvent<unknown>
      ? void
      : (e: string | React.ChangeEvent<unknown>) => void;
  };
  handleBlur?: {
    (e: React.FocusEvent<unknown, Element>): void;
    <T = unknown>(
      fieldOrEvent: T,
    ): T extends string ? (e: unknown) => void : void;
  };
  value?: string | number | readonly string[] | undefined;
  setFieldValue: (
    field: string,
    value: string,
    shouldValidate?: boolean | undefined,
  ) => Promise<void | FormikErrors<unknown>>;
  isLoading?: boolean;
};

const customTheme: CustomFlowbiteTheme['datepicker'] = {
  root: {
    input: {
      base: 'w-full',
      field: {
        input: {
          base: 'w-full h-[50px]',
        },
      },
    },
  },
  popup: {
    footer: {
      base: 'mt-2 flex space-x-2',
      button: {
        base: 'w-full rounded-lg px-5 py-2 text-center text-sm font-medium focus:ring-4 focus:ring-cyan-300',
        today: 'bg-primary text-white dark:primary',
        clear:
          'border border-gray-300 bg-white text-gray-900 hover:bg-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:hover:bg-gray-600',
      },
    },
    header: {
      selectors: {
        button: {
          // base: 'focus:ring-2 focus:ring-primary',
        },
      },
    },
  },

  views: {
    days: {
      items: {
        item: {
          disabled: 'bg-red-600',
          selected: 'bg-primary',
        },
      },
    },

    months: {
      items: {
        item: {
          disabled: 'bg-red-600',
          selected: 'bg-primary',
        },
      },
    },
    decades: {
      items: {
        item: {
          disabled: 'bg-red-600',
          selected: 'bg-primary',
        },
      },
    },
    years: {
      items: {
        item: {
          disabled: 'bg-red-600',
          selected: 'bg-primary',
        },
      },
    },
  },
};

const DateInput: FC<DateInputProps> = ({
  handleBlur,
  value,
  setFieldValue,
  isLoading,
}) => {
  const handleChange = (date: Date) => {
    const newDate = dayjs(date);
    if (date) {
      setFieldValue('dob', newDate.format('YYYY-MM-DD'));
    } else {
      setFieldValue('dob', ''); // Handle case when date is cleared
    }
  };

  return (
    <div>
      {isLoading ? (
        <div className="w-full">
          <Skeleton className="h-[50px] w-full rounded-lg" />
        </div>
      ) : (
        <Datepicker
          type="text"
          name="dob"
          onBlur={handleBlur}
          theme={customTheme}
          onSelectedDateChanged={handleChange}
          value={value ? dayjs(value.toString()).format('MM-DD-YYYY') : ''}
          placeholder="1990-02-20"
          maxDate={dayjs().subtract(18, 'year').toDate()}
        />
      )}
    </div>
  );
};

export default DateInput;

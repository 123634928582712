import type { FC } from 'react';
import React from 'react';
import { Button } from 'components';

type NeedHelpProps = {
  setNeedHelp: React.Dispatch<React.SetStateAction<boolean>>;
};

const NeedHelp: FC<NeedHelpProps> = ({ setNeedHelp }) => {
  return (
    <div className="text-center mt-20">
      <h1 className="text-2xl text-center text-primary">Let us help you!</h1>

      <p className="mt-10">
        "Open your mobile phone camera and scan the QR code.",
        <br />
        "Log in and approve access for Yapily Connect.",
        <br />
        "You will then continue back to this screen."
      </p>

      <Button
        className="w-[80%] mx-auto mt-20"
        name="Close"
        variant="secondary"
        onClick={() => setNeedHelp(false)}
      />
    </div>
  );
};

export default NeedHelp;

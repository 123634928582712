import { LogOutBannerLogo, NizaLogo } from 'assets';
import { Button, Modal } from 'components';
import type { FC, ReactElement } from 'react';
import { useLoginWithNiza } from 'services';
import { getEncryptedTokenFromStorage } from 'services/storage';

type LogOutModalProps = {
  title?: string;
  description?: ReactElement;
};

const LogOutModal: FC<LogOutModalProps> = ({ title, description }) => {
  const accessToken = getEncryptedTokenFromStorage();

  const { data } = useLoginWithNiza(accessToken);

  return (
    <Modal showModal={!accessToken}>
      <div className="max-w-[514px] w-full p-5">
        <div className="flex flex-col items-center gap-3 md:gap-5">
          <LogOutBannerLogo />
          <h1 className="font-semibold text-[32px] text-center ">
            {title || 'You’ve been logged out'}
          </h1>

          {description ?? (
            <p className="text-base text-center">
              For security reasons, you need to{' '}
              <span
                className="text-primary font-semibold cursor-pointer"
                onClick={() => {
                  if (data?.redirect_link) {
                    window.location.replace(data?.redirect_link);
                  }
                }}>
                Sign in
              </span>{' '}
              again to continue using the application.
            </p>
          )}
        </div>

        <div className="border-b  w-full my-8" />

        <div className="flex justify-center mx-0">
          <Button
            name="Sign in with NIZA"
            variant="default"
            iconBefore={<NizaLogo />}
            className="max-w-[360px] w-full"
            onClick={() => {
              if (data?.redirect_link) {
                window.location.replace(data?.redirect_link);
              }
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default LogOutModal;

import type { FC, ReactElement } from 'react';
import React from 'react';
import { Skeleton } from 'components';

type OrderDetailsItemProps = {
  title: string | null | undefined | ReactElement;
  label: string;
  isLoading?: boolean;
};

const OrderDetailsItem: FC<OrderDetailsItemProps> = ({
  label,
  title,
  isLoading,
}) => {
  return (
    <div className="flex flex-row justify-between py-2 text-lg  border-b px-5 border-borderLight dark:border-borderDark ">
      <p className="whitespace-nowrap text-gray-500 dark:text-gray-300">
        {label}
      </p>

      <div>{isLoading ? <Skeleton className="h-6 w-28 ml-auto" /> : title}</div>
    </div>
  );
};

export default OrderDetailsItem;

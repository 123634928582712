import type { FC, ReactElement } from 'react';
import React from 'react';
import TableLoading from './TableLoading';
import { cn } from 'utils';

type TableProps = {
  tableHead: {
    id: number;
    label: string;
  }[];
  tableBody: ReactElement[] | ReactElement | undefined;
  isLoading?: boolean;
  noDataText?: string;
  containerClassName?: string;
};

const Table: FC<TableProps> = ({
  tableHead,
  tableBody,
  isLoading,
  noDataText,
  containerClassName,
}) => {
  return (
    <div
      className={cn(
        'border overflow-x-auto overflow-y-hidden  rounded-t-xl dark:border-bg-dark mt-12',
        containerClassName,
      )}>
      <table className="table w-full">
        <thead className="bg-white dark:bg-bg-dark">
          <tr>
            {tableHead?.map((headItem) => (
              <th
                key={headItem.id}
                className="px-5 py-4 text-gray-500 dark:text-gray-200 text-sm text-start whitespace-nowrap">
                {headItem.label}
              </th>
            ))}
          </tr>
        </thead>
        {isLoading ? null : <tbody>{tableBody}</tbody>}
      </table>
      {isLoading ? (
        <div>
          <TableLoading />
        </div>
      ) : null}

      {!tableBody && !isLoading ? (
        <div className="text-center my-5">
          <p>{noDataText || 'No data'}</p>
        </div>
      ) : null}
    </div>
  );
};

export default Table;

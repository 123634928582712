import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from 'utils';
import type {
  BankCallbackParams,
  PaginationParams,
  YapilyCallbackParams,
} from '../types';

export const API_ENDPOINTS = {
  //Authentication
  LOGIN_WITH_NIZA: 'login/niza',
  AUTHORIZE_USER: 'sso/callback',
  LOGOUT: 'v1/user/logout',

  //Customers
  GET_CUSTOMERS_LIST: 'v1/customers/get',
  GET_PAGINATED_CUSTOMERS: ({
    pageSize,
    page,
  }: Partial<PaginationParams> = {}) =>
    `v1/customers/get?page-size=${pageSize ?? DEFAULT_PAGE_SIZE}&page=${page ?? DEFAULT_PAGE}`,
  STORE_NEW_CUSTOMER: 'v1/customers/store',
  GET_CUSTOMER_DETAILS: (id: string) => `v1/customers/${id}/show`,
  CHANGE_CUSTOMER_DETAILS: (id: string) => `v1/customers/${id}/update`,

  //Developer
  GET_DEVELOPER_CONFIGURATIONS: 'v1/developers/get-configurations',
  CREATE_WEBHOOK_CONNECTION: 'v1/developers/create/webhook-connection',

  //Invoice
  GET_INVOICES_LIST: 'v1/invoices/get',
  GET_PAGINATED_INVOICES: ({
    pageSize,
    page,
  }: Partial<PaginationParams> = {}) =>
    `v1/invoices/get?page-size=${pageSize ?? DEFAULT_PAGE_SIZE}&page=${page ?? DEFAULT_PAGE}`,
  CREATE_NEW_INVOICE: 'v1/invoices/store',
  GET_INVOICE_DETAILS: (id: string) => `v1/invoices/${id}/show`,
  //   UPDATE_INVOICE: (id: string) => `v1/invoices/${id}/update`,

  //Product
  GET_PRODUCTS_LIST: 'v1/products/get',
  GENERATE_ONRAMP_LINK: 'v1/products/on-ramp/generate-link',

  //User
  GET_BUSINESS_BRAND_DETAILS: 'v1/user/brand-details',
  CHANGE_BRAND_DETAILS: 'v1/user/change/brand-details',
  REMOVE_BRAND_LOGO: 'v1/user/remove/brand-logo',
  SWITCH_USER_ENVIRONMENT: 'v1/user/switch-environment',

  //Country
  GET_ALL_COUNTRIES: 'v1/country/fetch',
  GET_COUNTRY: (countryId: string) => `/v1/country/${countryId}`,

  //Onramp
  GET_INITIAL_ONRAMP_DATA: 'v1/on-ramp/initial-data',
  VERIFY_ONRAMP_CUSTOMER: 'v1/on-ramp/verify',
  VERIFY_ONRAMP_CUSTOMER_WITH_NIZA: 'v1/on-ramp/verify-kyc/niza',
  RESEND_OTP_CODE: 'v1/on-ramp/resend-otp-code',
  AUTHORIZE_ONRAMP_CUSTOMER: 'v1/on-ramp/authorize',
  ONRAMP_LOGOUT: 'v1/on-ramp/logout',
  GET_ONRAMP_CUSTOMER_DETAILS: 'v1/on-ramp/customer',
  UPDATE_ONRAMP_CUSTOMER_DETAILS: 'v1/on-ramp/customer/update',
  GET_ONRAMP_CUSTOMER_WALLET_ADDRESS: 'v1/on-ramp/customer/wallet/addresses',
  ADD_ONRAMP_NEW_WALLET_ADDRESS: 'v1/on-ramp/customer/add-wallet-address',

  CREATE_SUMSUB_APPLICANT: 'v1/on-ramp/sumsub/create-applicant',
  GET_SUMSUB_APPLICANT_ACCESS_TOKEN: 'v1/on-ramp/sumsub/get-token',
  GET_SUMSUB_APPLICANT_STATUS: 'v1/on-ramp/sumsub/applicant-status',

  CREATE_DRAFT_ORDER: 'v1/on-ramp/create-draft-order',
  GET_CRYPTO_PURCHASES: ({ pageSize, page }: Partial<PaginationParams> = {}) =>
    `v1/on-ramp/crypto-purchases?page-size=${pageSize ?? DEFAULT_PAGE_SIZE}&page=${page ?? DEFAULT_PAGE}`,
  GET_CRYPTO_PURCHASE_DETAILS: (orderId: string | undefined) =>
    `v1/on-ramp/crypto-purchase-details/${orderId}`,

  UPDATE_ONRAMP_PROGRESS: 'v1/on-ramp/update-progress',
  GET_ONRAMP_PROGRESS: 'v1/on-ramp/progress',

  INITIATE_ONRAMP_PAY_BY_BANK_AUTHORIZATION: 'v1/on-ramp/payment/authorization',
  GET_ONRAMP_PAYMENT_METHODS: 'v1/on-ramp/payment/methods',
  GET_ONRAMP_BANK_DETAILS: 'v1/on-ramp/payment/bank',
  ONRAMP_PAY_BY_CARD: 'v1/on-ramp/payment/card',
  GET_ONRAMP_PAYMENT_INSTITUTIONS: 'v1/on-ramp/payment/institutions',
  FINISH_PAYMENT_FOR_MANUAL_TRANSFER: 'v1/on-ramp/payment/finish',
  GET_ONRAMP_YAPILY_DB_STATUS: 'v1/on-ramp/payment/status/db',
  GET_ONRAMP_YAPILY_API_STATUS: 'v1/on-ramp/payment/status/api',
  GET_ONRAMP_BANK_CALLBACK: (data: BankCallbackParams, queryParams?: string) =>
    `v1/bank/callback/${data.orderId}/${data.id}${queryParams}`,

  GET_ONRAMP_ALL_ASSETS_OF_TYPE: 'v1/on-ramp/assets/fetch', //Onramp assets
  CONVERT_ONRAMP_ASSET: 'v1/on-ramp/assets/convert',
  GET_ONRAMP_SINGLE_ASSET: (asset_id: string) =>
    `v1/on-ramp/assets/${asset_id}`,

  ONRAMP_SANDBOX_PAYMENT: 'v1/on-ramp/payment/sandbox',

  //Assets
  GET_COIN_NETWORKS: (coin: string) => `/v1/assets/${coin}/networks`,
  GET_ASSETS: 'v1/assets/fetch',
  CONVERT_ASSET: 'v1/assets/convert',
  FIND_ASSET: (asset_id: string) => `v1/assets/${asset_id}`,

  //Payment methods
  INITIATE_PAY_BY_BANK: 'payment/authorization',
  GET_PAYMENT_METHODS: 'payment/methods',
  YAPILY_CALLBACK: ({ environment, id }: YapilyCallbackParams) =>
    `yapily/callback/${environment}/${id}`,

  //Order
  GET_ORDERS_LIST: 'v1/orders/get',
  GET_ORDER_DETAILS: (order: string) => `v1/orders/${order}/show`,
  GET_PAGINATED_ORDERS: ({ pageSize, page }: Partial<PaginationParams> = {}) =>
    `v1/orders/get?page-size=${pageSize ?? DEFAULT_PAGE_SIZE}&page=${page ?? DEFAULT_PAGE}`,

  CONFIRM_ORDER_BY_CUSTOMER: (token?: string | null) =>
    `/v1/order/confirm/${token}`,
  REJECT_ORDER_BY_CUSTOMER: (token?: string | null) =>
    `v1/order/reject/${token}`,
};

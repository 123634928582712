import { useState, type FC } from 'react';
import SingedInLayout from 'components/layout/SingedInLayout';
import NotFound from './components/NotFound.component';
import useDarkSide from 'hooks/useDarkSide';

const PageNotFound: FC = () => {
  const [isAuthenticated] = useState(true);
  useDarkSide();

  if (isAuthenticated) {
    return (
      <SingedInLayout>
        <NotFound />
      </SingedInLayout>
    );
  }

  return (
    <div className="bg-white dark:bg-bg-dark h-screen">
      <NotFound />
    </div>
  );
};

export default PageNotFound;

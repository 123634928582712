import { Button } from 'components';
import { useOnrampInitialData } from 'providers/OnrampDataProvider';
import type { FC } from 'react';
import { useConvertOnrampAsset } from 'services';
import type { SimulateOnrampSandboxPaymentData } from 'services/types';

type PaymentSandboxSummaryProps = {
  handleSimulateSandboxOnrampPayment: (
    status: SimulateOnrampSandboxPaymentData['status'],
  ) => void;
  isSimulatingPayment: boolean;
  simulationType: SimulateOnrampSandboxPaymentData['status'] | undefined;
};

const PaymentSandboxSummary: FC<PaymentSandboxSummaryProps> = ({
  handleSimulateSandboxOnrampPayment,
  isSimulatingPayment,
  simulationType,
}) => {
  const { onrampData, isLoading } = useOnrampInitialData();

  const { data: convertData, isLoading: isLoadingConvert } =
    useConvertOnrampAsset({
      amount: onrampData?.data?.source_amount || '',
      from: onrampData?.data?.source_currency || '',
      to: onrampData?.data?.destination_currency || '',
    });

  const paymentSum =
    (convertData?.fees?.[0]?.converted_amount || 0) +
    (convertData?.fees?.[1]?.converted_amount || 0) +
    parseFloat(onrampData?.data?.source_amount || '0');

  return (
    <div className="flex flex-col justify-between">
      <div className="text-center border-b border-borderLight dark:border-borderDark pb-2">
        <h1 className="text-xl">Sandbox - Payment Summary</h1>
      </div>

      <div>
        <h2 className="text-center mt-8 mb-2 text-2xl">
          Payment total is{' '}
          <span className="text-primary">
            {isLoadingConvert || isLoading ? (
              <span className="animate-pulse"> ...</span>
            ) : (
              paymentSum.toFixed(2) + ' ' + onrampData?.data?.source_currency
            )}
          </span>{' '}
        </h2>
        <h2 className="text-center mb-8 text-2xl">
          Receive{' '}
          <span className="text-primary">
            {isLoadingConvert || isLoading ? (
              <span className="animate-pulse"> ...</span>
            ) : (
              parseFloat(convertData?.converted_amount || '0')?.toFixed(8) +
              ' ' +
              onrampData?.data?.destination_currency
            )}
          </span>
        </h2>
      </div>

      <div className="w-full">
        <h2 className="text-center mb-8 text-xl">
          Simulate one of the following
        </h2>

        <div className="grid grid-cols-2 gap-2">
          <Button
            name="Success"
            variant="primary"
            className="w-full h-16 text-xl"
            onClick={() => handleSimulateSandboxOnrampPayment('success')}
            isLoading={isSimulatingPayment && simulationType === 'success'}
          />
          <Button
            name="Pending"
            variant="primary"
            className="w-full bg-yellow-400 h-16 text-xl hover:bg-yellow-500"
            onClick={() => handleSimulateSandboxOnrampPayment('pending')}
            isLoading={isSimulatingPayment && simulationType === 'pending'}
          />
          <Button
            name="Failed"
            variant="primary"
            className="w-full bg-red-700 h-16 text-xl hover:bg-red-800"
            onClick={() => handleSimulateSandboxOnrampPayment('failed')}
            isLoading={isSimulatingPayment && simulationType === 'failed'}
          />
          <Button
            name="Rejected"
            variant="primary"
            className="w-full bg-red-500 h-16 text-xl hover:bg-red-600"
            onClick={() => handleSimulateSandboxOnrampPayment('rejected')}
            isLoading={isSimulatingPayment && simulationType === 'rejected'}
          />
        </div>
      </div>
    </div>
  );
};

export default PaymentSandboxSummary;

import * as React from 'react';
import type { SVGProps } from 'react';
const BankIconSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    {...props}>
    <path
      d="M10 17.5V10.625M13.125 17.5V10.625M6.875 17.5V10.625M2.5 7.5L10 2.5L17.5 7.5M16.25 17.5V8.61C14.1824 8.2863 12.0928 8.12414 10 8.125C7.87417 8.125 5.78667 8.29167 3.75 8.61V17.5M2.5 17.5H17.5M10 5.625H10.0067V5.63167H10V5.625Z"
      stroke="#D0D5DD"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const BankIcon = React.memo(BankIconSvg);
export default BankIcon;

import type { FC } from 'react';
import {
  AtMailIcon,
  BankTransferSWIFT,
  Building06Icon,
  DownloadPaymentIcon,
  File02Icon,
  File05Icon,
  LocationIcon,
} from 'assets';
import BankInfoItem from './BankInfoItem';
import { Button, Input } from 'components';

const BankTransferSwiftPayment: FC = () => {
  return (
    <div className="flex flex-col px-8  justify-between h-full ">
      <div>
        <div className="flex flex-col items-center  w-full mt-6 gap-2">
          <BankTransferSWIFT />
          <h1 className="font-medium text-2xl">You're paying with Bank</h1>
        </div>

        <div>
          <BankInfoItem
            icon={<Building06Icon />}
            title="Beneficiary:"
            description="Hello world"
          />
          <BankInfoItem
            icon={<File05Icon />}
            title="IBAN:"
            className="bg-gray-100 dark:bg-gray-800"
          />
          <BankInfoItem
            icon={<Building06Icon />}
            title="Beneficiary address:"
          />
          <BankInfoItem
            icon={<File02Icon />}
            title="BIC/SWIFT:"
            className="bg-gray-100 dark:bg-gray-800"
          />
          <BankInfoItem icon={<LocationIcon />} title="Bank Address:" />
          <BankInfoItem
            icon={<File02Icon />}
            title="Reference:"
            className="bg-gray-100 dark:bg-gray-800"
          />
        </div>

        <div className="border mt-5 border-gray-300  dark:border-gray-600 mb-3" />

        <div>
          <p className="mb-3">Payer email</p>

          <Input type="email" />
        </div>
      </div>

      <div className="mb-5">
        <p className="text-center mt-6 text-lg font-medium">
          You're Paying:{' '}
          <span className="text-primary text-2xl font-semibold">
            361.36 EUR
          </span>
        </p>
        <div className="flex flex-col md:flex-row flex-wrap lg:flex-nowrap  gap-5 mt-5 ">
          <Button
            name="Download PDF"
            variant="secondary"
            className="w-full"
            iconBefore={<DownloadPaymentIcon />}
          />
          <Button
            name="Send by e-mail"
            variant="secondary"
            className="w-full"
            iconBefore={<AtMailIcon />}
          />
        </div>

        <Button
          name="Confirm The Transfer"
          variant="primary"
          className="mt-5 w-full"
        />
      </div>
    </div>
  );
};

export default BankTransferSwiftPayment;

import { NotVerifiedIcon, PlusIcon, VerifiedIcon } from 'assets';
import { Button, PageHeader, Table, TablePagination, Toast } from 'components';
import { AddCustomerForm } from 'forms';
import type { FC } from 'react';
import { useState } from 'react';
import { useGetPaginatedCustomers } from 'services';
import { useDateFormatter } from 'hooks';
import type { Customer, ToastStateProps } from 'services/types';

const tableHead = [
  {
    id: 2,
    label: 'Customer',
  },
  {
    id: 3,
    label: 'Email',
  },
  {
    id: 4,
    label: 'Created',
  },
  {
    id: 5,
    label: 'Verified',
  },
];

const pageSize = 10;

const Customers: FC = () => {
  const [showModal, setShowModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [initialValues, setInitialValues] = useState<Customer>();
  const [toastState, setToastState] = useState<ToastStateProps>({
    active: false,
    toastMessage: '',
  });

  const { dateFormatter } = useDateFormatter();
  const { data: customerList, isLoading: isLoadingCustomers } =
    useGetPaginatedCustomers({ page: currentPage, pageSize: pageSize });

  const handleRowClick = (item: Customer) => {
    setInitialValues(item);
    setShowModal(true);
  };

  return (
    <div>
      <PageHeader
        title="Customers"
        rightContent={
          <Button
            name="Add customer"
            size="large"
            iconBefore={<PlusIcon />}
            variant="primary"
            className="rounded-xl w-40 h-12 md:h-14 md:w-full"
            onClick={() => {
              setInitialValues(undefined);
              setShowModal(true);
            }}
          />
        }
      />
      <Table
        tableHead={tableHead}
        isLoading={isLoadingCustomers}
        noDataText="No customers available"
        tableBody={customerList?.data?.map((item, index) => (
          <tr
            key={item.id}
            onClick={() => handleRowClick(item)}
            className={`${index % 2 === 0 ? 'bg-[#F9FAFB]  dark:bg-slate-800' : 'bg-gray-25 dark:bg-[#233045]'} border-t dark:border-bg-dark h-[72px] cursor-pointer hover:bg-white hover:dark:bg-bg-dark`}>
            <td className="px-6 py-4 whitespace-nowrap">
              <div>
                {item.first_name} {item.last_name}
              </div>
            </td>
            <td className="px-6 py-4 whitespace-nowrap">{item.email}</td>
            <td className="px-6 py-4 whitespace-nowrap">
              {dateFormatter({ date: item.created_at })}
            </td>
            <td className="px-6 py-4">
              {item?.kyc_status === 'completed' &&
              item?.kyc_data?.review_answer === 'GREEN' ? (
                <VerifiedIcon className="fill-black dark:fill-white" />
              ) : (
                <NotVerifiedIcon className="fill-black dark:fill-white" />
              )}
            </td>
          </tr>
        ))}
      />
      <TablePagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={customerList?.meta?.last_page || 1}
      />
      {showModal && (
        <AddCustomerForm
          setShowModal={setShowModal}
          showModal={showModal}
          initialValues={initialValues}
          setToastState={setToastState}
          infoMode={true}
        />
      )}

      {toastState.active && (
        <Toast
          text={toastState?.toastMessage || 'Item copied successfully'}
          onClose={() => setToastState({ active: false, toastMessage: '' })}
          variant="default"
        />
      )}
    </div>
  );
};

export default Customers;

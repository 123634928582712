import type { FC } from 'react';
import React from 'react';
import type {
  SimulateOnrampSandboxPaymentData,
  SimulateOnrampSandboxPaymentResponse,
} from 'services/types';
import { Button } from '../../../Button';
import { GearIcon, PaymentRejectedIcon } from 'assets';

type PaymentSandboxStatusResponseProps = {
  simulatePaymentResponse: SimulateOnrampSandboxPaymentResponse | undefined;
  setSimulatePaymentResponse: (
    value: React.SetStateAction<
      SimulateOnrampSandboxPaymentResponse | undefined
    >,
  ) => void;
};

const PaymentSandboxStatusResponse: FC<PaymentSandboxStatusResponseProps> = ({
  simulatePaymentResponse,
  setSimulatePaymentResponse,
}) => {
  const responseText: Record<
    SimulateOnrampSandboxPaymentData['status'],
    string
  > = {
    failed: 'payment failed to process, please contact support.',
    pending:
      'is currently pending. This means that we are in the process of verifying the transaction.',
    rejected: 'has unfortunately been rejected.',
    success: 'payment has been successfully processed.',
  };

  return (
    <div className="flex flex-col  items-center min-h-[600px] justify-between">
      <div className="mx-2 md:mx-12">
        <p className="text-xl text-center">
          Your{' '}
          <span className="text-primary">
            {simulatePaymentResponse?.amount}{' '}
            {simulatePaymentResponse?.currency}{' '}
          </span>
          {simulatePaymentResponse?.status
            ? responseText[simulatePaymentResponse?.status]
            : ''}
        </p>
      </div>

      {simulatePaymentResponse?.status === 'success' ||
      simulatePaymentResponse?.status === 'pending' ? (
        <GearIcon />
      ) : (
        <PaymentRejectedIcon />
      )}

      <Button
        name="Done"
        variant="primary"
        className="w-full"
        onClick={() => setSimulatePaymentResponse(undefined)}
      />
    </div>
  );
};

export default PaymentSandboxStatusResponse;

import type { FC, ReactElement } from 'react';
import React from 'react';

type InputProps = {
  placeholder?: string;
  iconStart?: ReactElement;
  iconEnd?: ReactElement;
  required?: boolean;
  disabled?: boolean;
  className?: string;
  id?: string;
  name?: string;
  onChange?: {
    (e: React.ChangeEvent<unknown>): void;
    <T = string | React.ChangeEvent<unknown>>(
      field: T,
    ): T extends React.ChangeEvent<unknown>
      ? void
      : (e: string | React.ChangeEvent<unknown>) => void;
  };
  handleBlur?: {
    (e: React.FocusEvent<unknown, Element>): void;
    <T = unknown>(
      fieldOrEvent: T,
    ): T extends string ? (e: unknown) => void : void;
  };
  value?: string | number | readonly string[] | undefined;
};

const TextArea: FC<InputProps> = ({
  placeholder,
  iconEnd,
  iconStart,
  required = false,
  disabled,
  className,
  id,
  name,
  handleBlur,
  onChange,
  value,
}) => {
  return (
    <div className={className}>
      <div className="relative">
        {iconStart && (
          <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none ">
            {iconStart}
          </div>
        )}
        <textarea
          className={`block  w-full p-3 text-base ${iconStart && 'ps-10'}  text-gray-900 border border-gray-300 rounded-lg bg-white dark:bg-bg-dark focus:outline-none focus:ring-2 focus:ring-primary focus:border-primary dark:border-gray-600 dark:placeholder-gray-400 dark:text-white `}
          placeholder={placeholder}
          required={required}
          disabled={disabled}
          id={id}
          name={name}
          onChange={onChange}
          onBlur={handleBlur}
          value={value}
        />

        {iconEnd && (
          <div className="absolute end-2 bottom-1.5 font-medium rounded-lg text-sm px-4 py-2">
            {iconEnd}
          </div>
        )}
      </div>
    </div>
  );
};

export default TextArea;

import * as React from 'react';
import type { SVGProps } from 'react';
const NewTabIconSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="200px"
    height="200px"
    viewBox="0 0 48 48"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path d="M0 0h48v48H0z" fill="none" />
    <g id="Shopicon">
      <polygon points="44,30 40,30 40,38 8,38 8,10 20,10 20,6 4,6 4,42 44,42  " />
      <polygon points="26,26.828 40,12.828 40,24 44,24 44,6 26,6 26,10 37.172,10 23.172,24  " />
    </g>
  </svg>
);

const NewTabIcon = React.memo(NewTabIconSvg);

export default NewTabIcon;

import {
  USER_SESSION,
  ENCRYPTED_ONRAMP_SESSION_TOKEN,
  ONRAMP_USER_SESSION,
  ENCRYPTED_MERCHANT_SESSION_TOKEN,
} from 'utils';
import type { MerchantAuthStorage, OnrampSessionType } from '../types';
import secureLocalStorage from 'react-secure-storage';

const getAuthDataFromStorage = () => {
  try {
    const userSession = localStorage.getItem(USER_SESSION);
    if (userSession) {
      return JSON.parse(userSession) as MerchantAuthStorage;
    }
  } catch (error) {
    console.error('getTokenFromStorage error:', error);
  }

  return undefined;
};

const getEncryptedTokenFromStorage = () => {
  try {
    const accessToken = localStorage.getItem(ENCRYPTED_MERCHANT_SESSION_TOKEN);
    if (accessToken) {
      return JSON.parse(accessToken?.toString()) as string;
    }
  } catch (error) {
    console.error('getTokenFromStorage error:', error);
  }

  return undefined;

  //   try {
  //     const accessToken = secureLocalStorage.getItem(
  //       ENCRYPTED_MERCHANT_SESSION_TOKEN,
  //     );
  //     if (accessToken) {
  //       return JSON.parse(accessToken?.toString()) as string;
  //     }
  //   } catch (error) {
  //     console.error('getTokenFromStorage error:', error);
  //   }

  // return undefined;
};

const setEncryptedTokenToStorage = (token: string) => {
  localStorage.setItem(ENCRYPTED_MERCHANT_SESSION_TOKEN, JSON.stringify(token));
  // secureLocalStorage.setItem(
  //   ENCRYPTED_MERCHANT_SESSION_TOKEN,
  //   JSON.stringify(token),
  // );
};

const removeEncryptedTokenFromStorage = () => {
  localStorage.removeItem(ENCRYPTED_MERCHANT_SESSION_TOKEN);
  // secureLocalStorage.removeItem(ENCRYPTED_MERCHANT_SESSION_TOKEN);
};

const removeOnrampSessionFromStorage = () => {
  try {
    localStorage.removeItem(ONRAMP_USER_SESSION);
  } catch (error) {
    console.error('removeOnrampSessionFromStorage error:', error);
  }
};

const getOnrampSessionFromStorage = () => {
  try {
    const onrampSession = localStorage.getItem(ONRAMP_USER_SESSION);
    if (onrampSession) {
      return JSON.parse(onrampSession) as OnrampSessionType;
    }
  } catch (error) {
    console.error('getOnrampSessionFromStorage error:', error);
  }

  return undefined;
};

const getEncryptedOnrampTokenFromStorage = () => {
  try {
    const encryptedOnrampSession = secureLocalStorage.getItem(
      ENCRYPTED_ONRAMP_SESSION_TOKEN,
    );

    if (encryptedOnrampSession) {
      return JSON.parse(encryptedOnrampSession?.toString()) as string;
    }
  } catch (error) {
    console.error('🚀 ~ getEncryptedOnrampSessionFromStorage ~ error:', error);
  }
};
const removeEncryptedOnrampTokenFromStorage = () => {
  try {
    secureLocalStorage.removeItem(ENCRYPTED_ONRAMP_SESSION_TOKEN);
  } catch (error) {
    console.error(
      '🚀 ~ removeEncryptedOnrampSessionFromStorage ~ error:',
      error,
    );
  }
};

export {
  getAuthDataFromStorage,
  getEncryptedTokenFromStorage,
  getOnrampSessionFromStorage,
  removeOnrampSessionFromStorage,
  getEncryptedOnrampTokenFromStorage,
  removeEncryptedOnrampTokenFromStorage,
  setEncryptedTokenToStorage,
  removeEncryptedTokenFromStorage,
};

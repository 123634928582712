import * as React from 'react';
import type { SVGProps } from 'react';
const ChevronUpIconSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={14}
    height={8}
    viewBox="0 0 14 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M13.25 7.125L7 0.875L0.75 7.125"
      stroke="#4E4E4E"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ChevronUpIcon = React.memo(ChevronUpIconSvg);
export default ChevronUpIcon;

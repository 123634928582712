import * as React from 'react';
import type { SVGProps } from 'react';
const DevelopersIconSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <g clipPath="url(#clip0_81_65)">
      <path
        d="M11.9993 3L19.7935 7.5V15.3453C19.7935 16.0598 19.4123 16.7201 18.7935 17.0774L12.9993 20.4226C12.3805 20.7799 11.6181 20.7799 10.9993 20.4226L5.20508 17.0774C4.58628 16.7201 4.20508 16.0598 4.20508 15.3453V7.5L11.9993 3Z"
        stroke="fill"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.0001 7V12L7.66993 14.5"
        stroke="#1DA069"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 12L16.3301 14.5"
        stroke="#1DA069"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_81_65">
        <rect width={24} height={24} fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const DevelopersIcon = React.memo(DevelopersIconSvg);

export default DevelopersIcon;

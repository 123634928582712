import * as React from 'react';
import type { SVGProps } from 'react';
const FolderImageIconSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <g clipPath="url(#clip0_203_19485)">
      <path
        d="M4.16732 15.8333L10.0007 10L15.834 15.8333"
        stroke="#1DA069"
        strokeWidth={1.66667}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x={6.25}
        y={7.91675}
        width={0.00833333}
        height={0.00833333}
        stroke="#1DA069"
        strokeWidth={2.5}
        strokeLinejoin="round"
      />
      <path
        d="M2.5 14.1667V4.16675H8.33333L10 5.83341H17.5V14.1667C17.5 15.0872 16.7538 15.8334 15.8333 15.8334H4.16667C3.24619 15.8334 2.5 15.0872 2.5 14.1667Z"
        stroke="fill"
        strokeWidth={1.66667}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_203_19485">
        <rect width={20} height={20} fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const FolderImageIcon = React.memo(FolderImageIconSvg);

export default FolderImageIcon;

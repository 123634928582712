import { useEffect, useState } from 'react';

function useLocalStorageState<T>(
  key: string,
  defaultValue?: T,
): [T, React.Dispatch<React.SetStateAction<T>>] {
  const initialState = (): T => {
    const storedData = localStorage.getItem(key);
    try {
      return storedData !== null && storedData !== 'undefined'
        ? JSON.parse(storedData)
        : defaultValue;
    } catch {
      return (storedData || defaultValue) as unknown as T;
    }
  };

  const [state, setState] = useState<T>(initialState);

  useEffect(() => {
    if (state) {
      localStorage.setItem(key, JSON.stringify(state));
    }
  }, [key, state]);

  return [state, setState];
}

export default useLocalStorageState;

import type { ReactElement } from 'react';
import { Button } from '../Button';
import { twMerge } from 'tailwind-merge';

interface Pagination {
  totalPages: number;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
}

function GeneratePagination({
  totalPages,
  currentPage,
  setCurrentPage,
}: Pagination): ReactElement {
  const maxPagesToShow = 3;
  const pages: number[] = [];

  if (totalPages <= maxPagesToShow) {
    for (let i = 1; i <= totalPages; i++) {
      pages.push(i);
    }
  } else {
    const startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    const endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

    if (startPage > 1) {
      pages.push(1);
      if (startPage > 2) {
        pages.push(-1);
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        pages.push(-1);
      }
      pages.push(totalPages);
    }
  }

  return (
    <div className="flex">
      {pages.map((page, index) => {
        if (page === -1) {
          return (
            <Button
              name="..."
              variant="default"
              className="border-0 pointer-events-none"
              size="small"
              key={index}
            />
          );
        }
        return (
          <Button
            name={`${page}`}
            variant="default"
            className={twMerge(
              'border-0 hover:bg-gray-300 hover:dark:bg-gray-600 text-gray-600 dark:text-gray-300 ',
              page === currentPage &&
                'text-gray-800 dark:text-gray-100 bg-gray-50 dark:bg-bg-secondaryDark',
            )}
            size="small"
            onClick={() => setCurrentPage(page)}
            key={index}
          />
        );
      })}
    </div>
  );
}

export default GeneratePagination;

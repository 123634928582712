import { MenuDrawerIcon, MerchantLogoDark, MerchantLogoLight } from 'assets';
import React, { useState } from 'react';
import MenuDrawer from './MenuDrawer';
import MenuItems from './components/MenuItems.component';

const TopHeader = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="mb-10 mt-5 flex justify-between gap-2">
      <div className="flex gap-2 items-center">
        <div>
          <MerchantLogoDark className="hidden dark:inline" />
          <MerchantLogoLight className="inline dark:hidden" />
        </div>

        <div className="font-poppins font-semibold text-xl">
          <h3>NIZA</h3>
          <p className="text-[#25F49D]">Merchant</p>
        </div>
      </div>
      <div>
        <button onClick={() => setIsOpen(true)}>
          <MenuDrawerIcon height={'50px'} width={'50px'} />
        </button>
      </div>

      <MenuDrawer isOpen={isOpen} setIsOpen={setIsOpen}>
        <MenuItems />
      </MenuDrawer>
    </div>
  );
};

export default TopHeader;

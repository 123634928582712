import { CopyIcon, NewTabIcon } from 'assets';
import {
  Button,
  Input,
  InputError,
  Label,
  Modal,
  ReactSelectComponent,
  Toast,
} from 'components';
import { Formik } from 'formik';
import WAValidator from 'multicoin-address-validator';
import type { FC } from 'react';
import React, { useMemo, useState } from 'react';
import {
  useGenerateOnrampLink,
  useGetAssetsList,
  useGetCoinNetworks,
} from 'services';
import type { ToastStateProps } from 'services/types';
import { GenerateOnrampLinkSchema } from '../utils';

type GenerateOnrampLinkProps = {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const GenerateOnrampLink: FC<GenerateOnrampLinkProps> = ({
  setShowModal,
  showModal,
}) => {
  const [selectedDestinationCurrency, setSelectedDestinationCurrency] =
    useState('');
  const [onRampLink, setOnrampLink] = useState('');
  const [toastState, setToastState] = useState<ToastStateProps>({
    active: false,
    toastMessage: '',
  });

  const { createLink, isCreatingOnrampLink } = useGenerateOnrampLink();
  const { data: fiatAssets, isLoading: isLoadingFiatAssets } = useGetAssetsList(
    {
      type: 'fiat',
    },
  );

  const { data: cryptoAssets, isLoading: isLoadingCryptoAssets } =
    useGetAssetsList({
      type: 'crypto',
    });

  const {
    data: coinNetworks,
    isLoading: isLoadingNetworks,
    refetch,
  } = useGetCoinNetworks({
    coin: selectedDestinationCurrency,
  });

  const transformFiatList = useMemo(
    () =>
      fiatAssets?.data?.map((asset) => ({
        label: asset.asset_name,
        value: asset.asset_id,
        id: asset.asset_id,
        icon: asset.asset_id,
      })),
    [fiatAssets],
  );

  const transformCryptoList = useMemo(
    () =>
      cryptoAssets?.data?.map((asset) => ({
        label: asset.asset_name,
        value: asset.asset_id,
        id: asset.asset_id,
        icon: asset.asset_id,
      })),
    [cryptoAssets],
  );

  const transformNetworkOptionsList = useMemo(
    () =>
      coinNetworks?.map((network) => ({
        label: network.name,
        value: network.network_id,
        id: network.network_id,
        icon: network.network_id,
      })),
    [coinNetworks],
  );

  const modalHeader = (
    <div className="flex flex-col items-center pb-5 border-b border-solid dark:border-gray-600 border-gray-300 rounded-t ">
      <div className="border p-3 rounded-xl w-fit">
        {/* <DocumentIcon className="dark:stroke-white stroke-black" /> */}
      </div>

      <h3 className="text-lg font-semibold mt-4">Generate link</h3>
      <p className="text-gray-600 dark:text-gray-300 mx-5">
        Generate onramp link
      </p>
    </div>
  );

  return (
    <div>
      <Formik
        initialValues={{
          source_amount: '',
          source_currency: {
            id: '',
            label: '',
            value: '',
          },
          destination_currency: {
            id: '',
            label: '',
            value: '',
          },
          network: {
            id: '',
            label: '',
            value: '',
          },
          customer_wallet_address: '',
        }}
        onSubmit={(values, { setSubmitting }) => {
          createLink(
            {
              customer_wallet_address: values.customer_wallet_address,
              destination_currency: values.destination_currency.value,
              source_amount: values.source_amount,
              source_currency: values.source_currency.value,
              destination_network: values.network.value,
            },
            {
              onSuccess: (response) => {
                setToastState({
                  active: true,
                  variant: 'success',
                  toastMessage: response.message,
                });
                setOnrampLink(response.onRamp);
                setSubmitting(false);
              },
              onError: (error) => {
                setToastState({
                  active: true,
                  variant: 'error',
                  toastMessage: error?.response?.data?.message,
                });
                setSubmitting(false);
              },
            },
          );
          setSubmitting(false);
        }}
        validationSchema={GenerateOnrampLinkSchema}
        validate={(values) => {
          const errors: any = {};
          try {
            const validWA = WAValidator?.validate(
              values.customer_wallet_address,
              values.destination_currency.value,
            );

            if (!validWA && values.customer_wallet_address.length >= 24) {
              errors.customer_wallet_address = 'Wallet address is not valid';
            }

            return errors;
          } catch (error) {
            return errors;
          }
        }}>
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          resetForm,
        }) => (
          <form onSubmit={handleSubmit} id="addInvoiceForm">
            <Modal
              onClose={() => {
                setOnrampLink('');
                resetForm();
                setShowModal(false);
                setSelectedDestinationCurrency('');
                refetch();
              }}
              showModal={showModal}
              header={modalHeader}
              footer={
                <div className="flex items-center gap-4 justify-center p-4 border-t border-solid dark:border-gray-600 border-gray-300 rounded-b">
                  {!onRampLink && (
                    <Button
                      name="Generate link"
                      type="submit"
                      isLoading={isSubmitting || isCreatingOnrampLink}
                      variant="primary"
                    />
                  )}

                  <Button
                    name="Cancel"
                    variant="secondary"
                    onClick={() => {
                      resetForm();
                      setOnrampLink('');
                      setShowModal(false);
                    }}
                  />
                </div>
              }
              className="inset-0 h-[100vh]">
              <div className=" min-w-[300px] md:min-w-[560px] min-h-[320px]">
                {onRampLink ? (
                  <div className="flex flex-col md:flex-row  min-h-[320px] justify-center items-center gap-4">
                    <Button
                      name="Copy Link"
                      variant="secondary"
                      iconBefore={
                        <CopyIcon
                          className="dark:stroke-white w-6 h-6"
                          stroke="#292929"
                        />
                      }
                      onClick={() => {
                        navigator.clipboard.writeText(onRampLink ?? '');
                        setToastState({
                          active: true,
                          toastMessage: 'OnRamp link copied successfully',
                          variant: 'success',
                        });
                      }}
                      className="w-full"
                    />
                    <Button
                      name="Open Link"
                      variant="secondary"
                      className="w-full"
                      iconBefore={
                        <NewTabIcon className="h-5 w-5 fill-black dark:fill-white" />
                      }
                      onClick={() => {
                        window.open(onRampLink, '_blank');
                      }}
                    />
                  </div>
                ) : (
                  <div className="flex flex-col ">
                    <div className="flex md:flex-row flex-col gap-2 items-start md:items-center">
                      <Label text="Source amount*" className="w-60" />
                      <div className="w-full">
                        <Input
                          type="text"
                          onChange={handleChange}
                          handleBlur={handleBlur}
                          value={values.source_amount}
                          name="source_amount"
                          placeholder="e.g. 500"
                        />
                      </div>
                    </div>
                    {errors.source_amount && touched.source_amount ? (
                      <InputError
                        errorMessage={errors.source_amount}
                        className="ml-2 md:ml-44 pt-2 "
                      />
                    ) : null}
                    <div className="flex mt-4 md:flex-row flex-col gap-2 items-start md:items-center">
                      <Label text="Source currency*" className="w-60 " />

                      <ReactSelectComponent
                        name="source_currency"
                        onClick={(currency) => {
                          setFieldValue('source_currency', currency);
                        }}
                        options={transformFiatList}
                        // initialOption={selectedCountry}
                        value={values?.source_currency}
                        isLoading={isLoadingFiatAssets}
                        placeholder="e.g. Dollar"
                        renderIcon={true}
                      />
                    </div>
                    {errors.source_currency && touched.source_currency ? (
                      <InputError
                        errorMessage={errors.source_currency.value}
                        className="ml-2 md:ml-44 pt-2 "
                      />
                    ) : null}

                    <div className="flex mt-4 md:flex-row flex-col gap-2 items-start md:items-center">
                      <Label text="Destination currency*" className="w-60" />

                      <ReactSelectComponent
                        name="destination_currency"
                        onClick={(currency) => {
                          setFieldValue('destination_currency', currency);
                          setFieldValue('network', undefined);

                          if (currency?.value) {
                            setSelectedDestinationCurrency(
                              currency?.value as string,
                            );
                          }
                        }}
                        options={transformCryptoList}
                        // initialOption={selectedCountry}
                        value={values?.destination_currency}
                        isLoading={isLoadingCryptoAssets}
                        placeholder="e.g. Bitcoin"
                        renderIcon={true}
                      />
                    </div>
                    {errors.destination_currency &&
                    touched.destination_currency ? (
                      <InputError
                        errorMessage={errors.destination_currency.value}
                        className="ml-2 md:ml-44 pt-2 "
                      />
                    ) : null}

                    <div className="flex mt-4 md:flex-row flex-col gap-2 items-start md:items-center">
                      <Label text="Network*" className="w-60" />

                      <ReactSelectComponent
                        name="network"
                        onClick={(currency) => {
                          setFieldValue('network', currency);

                          if (currency?.value) {
                            setSelectedDestinationCurrency(
                              currency?.value as string,
                            );
                          }
                        }}
                        options={
                          values.destination_currency.value
                            ? transformNetworkOptionsList
                            : []
                        }
                        // initialOption={selectedCountry}
                        value={values?.network}
                        isLoading={isLoadingNetworks}
                        placeholder="e.g. Bitcoin"
                        renderIcon={false}
                      />
                    </div>
                    {errors.network && touched.network ? (
                      <InputError
                        errorMessage={errors.network.value}
                        className="ml-2 md:ml-44 pt-2 "
                      />
                    ) : null}

                    <div className="flex mt-4 pb-2 md:flex-row flex-col gap-2 items-start md:items-center">
                      <Label
                        text="Customer wallet address*"
                        className="w-60 "
                      />

                      <Input
                        type="text"
                        className="w-full"
                        onChange={handleChange}
                        handleBlur={handleBlur}
                        value={values.customer_wallet_address}
                        name="customer_wallet_address"
                        placeholder="e.g. 0x0000000000000000000000000000000000000000"
                      />
                    </div>

                    {errors.customer_wallet_address &&
                    touched.customer_wallet_address ? (
                      <InputError
                        errorMessage={errors.customer_wallet_address}
                        className="ml-2 md:ml-44 pt-2 "
                      />
                    ) : null}
                  </div>
                )}
              </div>
            </Modal>
          </form>
        )}
      </Formik>

      {toastState.active && (
        <Toast
          text={toastState?.toastMessage || 'Item copied successfully'}
          onClose={() => setToastState({ active: false, toastMessage: '' })}
          variant={toastState.variant || 'default'}
        />
      )}
    </div>
  );
};

export default GenerateOnrampLink;

import * as React from 'react';
import type { SVGProps } from 'react';
const LearMoreLinesSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={164}
    height={40}
    viewBox="0 0 164 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M162.5 19.3061C162.5 20.567 161.908 21.8457 160.702 23.1351C159.494 24.4261 157.699 25.6948 155.377 26.9188C150.732 29.3659 144.074 31.5837 136.064 33.452C120.051 37.1867 98.7448 39.5 77.6184 39.5C56.4918 39.5 37.3831 37.1866 23.5714 33.4562C16.6619 31.59 11.1065 29.3767 7.29015 26.9397C3.45034 24.4877 1.5 21.8962 1.5 19.3061C1.5 13.9339 5.66078 9.4732 15.3476 6.343C25.0132 3.21966 39.9809 1.5 61.1241 1.5C82.2601 1.5 107.703 3.21791 127.853 6.36124C137.932 7.93349 146.663 9.85877 152.863 12.0946C155.966 13.2135 158.405 14.3997 160.058 15.6384C161.722 16.8848 162.5 18.1126 162.5 19.3061Z"
      stroke="#1DA069"
    />
    <path
      d="M91.0002 0.5C52.5243 1.88409 1 9 1 23.5C1 33.1706 36.3403 37 79.9348 37C123.529 37 163 29.1604 163 19.4898C163 11 135 5.7559 91.0002 5"
      stroke="fill"
    />
  </svg>
);

const LearMoreLines = React.memo(LearMoreLinesSvg);

export default LearMoreLines;

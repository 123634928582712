import { LoadingIcon } from 'assets';
import type { ButtonHTMLAttributes, FC, ReactElement } from 'react';
import { twMerge } from 'tailwind-merge';

interface ButtonProps {
  name: string;
  size?: 'medium' | 'small' | 'large';
  iconBefore?: ReactElement;
  iconAfter?: ReactElement;
  variant: 'primary' | 'secondary' | 'default' | 'danger';
  isLoading?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  className?: string;
  type?: 'submit' | 'reset' | 'button' | undefined;
}

type AllButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & ButtonProps;

const variantStyles: Record<ButtonProps['variant'], string> = {
  primary: 'bg-primary text-white hover:bg-lightPrimary',
  secondary: 'bg-transparent text-primary border hover:border-primary',
  danger:
    'bg-transparent text-error-700 border hover:border-error-700 dark:text-error-700',
  default:
    'bg-transparent border-[#D0D5DD] text-gray-700 dark:text-gray-300 border hover:border-primary',
};

const buttonSize: Record<'medium' | 'small' | 'large', string> = {
  small: 'h-8 px-3',
  large: 'h-14 px-6',
  medium: 'h-12 px-4',
};

const Button: FC<AllButtonProps> = ({
  name,
  size = 'medium',
  iconBefore = null,
  iconAfter = null,
  variant = 'primary',
  isLoading = false,
  disabled = false,
  onClick,
  className,
  type = 'button',
  ...props
}) => {
  return (
    <button
      className={twMerge(
        `rounded-lg text-black dark:text-white font-medium whitespace-nowrap flex items-center justify-center focus:outline-none  ${buttonSize[size]} ${variantStyles[variant]} ${
          disabled || isLoading
            ? 'opacity-50 cursor-not-allowed'
            : 'hover:opacity-80'
        } ${className}`,
      )}
      onClick={onClick}
      disabled={disabled || isLoading}
      type={type}
      {...props}>
      {isLoading && <LoadingIcon />}
      {iconBefore && !isLoading && <span className="mr-2">{iconBefore}</span>}
      {name}
      {iconAfter && <span className="ml-2">{iconAfter}</span>}
    </button>
  );
};

export default Button;

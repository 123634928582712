import client from '../client';
import type { AxiosError } from 'axios';
import { useMutation, useQuery } from '@tanstack/react-query';
import { API_ENDPOINTS } from '../client/api-endpoints';
import type {
  CustomerDetailsResponse,
  GeneralError,
  GetCustomersListResponse,
  PaginatedCustomersResponse,
  PaginationParams,
  StoreCustomerData,
  SuccessResponse,
} from '../types';

type CustomerDetailsParams = {
  id: string;
};

export function useGetCustomersList() {
  const query = useQuery<GetCustomersListResponse, AxiosError<GeneralError>>({
    queryKey: [API_ENDPOINTS.GET_CUSTOMERS_LIST],
    queryFn: client.customer.getCustomersList,
  });

  return query;
}

export function useGetPaginatedCustomers(data: PaginationParams) {
  const query = useQuery<PaginatedCustomersResponse, AxiosError<GeneralError>>({
    queryKey: [API_ENDPOINTS.GET_PAGINATED_CUSTOMERS(data)],
    queryFn: () => client.customer.getPaginatedCustomers(data),
  });

  return query;
}

export function useGetCustomerDetails({ id }: CustomerDetailsParams) {
  const query = useQuery<CustomerDetailsResponse, AxiosError<GeneralError>>({
    queryKey: [API_ENDPOINTS.GET_CUSTOMER_DETAILS(id), id],
    queryFn: () => client.customer.getCustomerDetails(id),
  });

  return query;
}

export function useStoreNewCustomer() {
  const { mutate, isPending } = useMutation<
    SuccessResponse,
    AxiosError<GeneralError>,
    StoreCustomerData
  >({
    mutationFn: client.customer.storeNewCustomer,
  });

  return {
    storeNewCustomer: mutate,
    isStoringNewCustomer: isPending,
  };
}

export function useChangeCustomerDetails({ id }: CustomerDetailsParams) {
  const { mutate, isPending } = useMutation<
    SuccessResponse,
    AxiosError<GeneralError>,
    StoreCustomerData
  >({
    mutationFn: (data) => client.customer.changeCustomerDetails(id, data),
  });

  return {
    changeCustomerDetails: mutate,
    isChangingCustomerDetails: isPending,
  };
}

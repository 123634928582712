import type { FC, ReactElement } from 'react';
import React from 'react';

type PageHeaderProps = {
  title: string;
  description?: string;
  rightContent?: ReactElement;
};

const PageHeader: FC<PageHeaderProps> = ({
  title,
  description,
  rightContent,
}) => {
  return (
    <div
      className={`${description ? 'flex-row md:flex space-y-4' : 'flex items-center'} self-center align-middle justify-between  gap-2`}>
      <div>
        <h1 className="text-xl md:text-2xl font-medium">{title}</h1>
        {description && (
          <p className="text-[#4E4E4E] dark:text-gray-300 mt-4">
            {description}
          </p>
        )}
      </div>

      {rightContent && (
        <div className="flex md:mt-0 flex-row-reverse">{rightContent}</div>
      )}
    </div>
  );
};

export default PageHeader;

import React, { useMemo } from 'react';
import { PaymentRejectedIcon, PaymentSuccessIcon } from 'assets';
import { useParams } from 'react-router-dom';
import { useGetOnrampBankCallback } from '../../services';

const OnrampResponse = () => {
  const { orderId, id } = useParams();

  const currentUrl = window.location.href;
  const queryParams = '?' + currentUrl?.split('?')[1];

  const { data: bankStatus } = useGetOnrampBankCallback(
    {
      id,
      orderId,
    },

    queryParams,
  );

  const renderStatus = useMemo(() => {
    if (bankStatus?.status === 'REJECTED') {
      return {
        icon: <PaymentRejectedIcon />,
        message: 'Payment Canceled',
      };
    }
    if (bankStatus?.status === 'PENDING') {
      return {
        icon: <PaymentSuccessIcon />,
        message: 'Payment done',
      };
    }
  }, [bankStatus?.status]);

  return (
    <div className="pt-10 flex flex-col items-center gap-5">
      <div>{renderStatus?.icon}</div>

      <p className="text-2xl ">{renderStatus?.message}</p>
    </div>
  );
};

export default OnrampResponse;

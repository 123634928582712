import type { FC } from 'react';
import { MerchantLogoDark, MerchantLogoLight } from 'assets';
import MenuItems from './components/MenuItems.component';

const RightMenu: FC = () => {
  return (
    <div className="relative hidden h-full pr-2 md:inline w-full max-w-52">
      <div className="mb-12 flex gap-2  items-center">
        <div>
          <MerchantLogoDark className="hidden dark:inline" />
          <MerchantLogoLight className="inline dark:hidden" />
        </div>

        <div className="font-poppins font-semibold text-2xl">
          <h3>NIZA</h3>
          <p className="text-[#25F49D]">Merchant</p>
        </div>
      </div>

      <MenuItems />
    </div>
  );
};

export default RightMenu;

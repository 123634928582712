import type { FC } from 'react';
import { LoadingIcon, PaymentRejectedIcon, PaymentSuccessIcon } from 'assets';

{
  /* <iframe
src={'http://localhost:3000/payment/card/fail/asda'}
height={500}
width={500}
/> */
}

type PaymentStatusProps = {
  status: 'success' | 'fail' | 'cancel' | 'pending';
};

const PaymentStatus: FC<PaymentStatusProps> = ({ status }) => {
  if (status === 'success') {
    return (
      <div className="flex flex-col justify-center items-center h-screen text-center gap-2 bg-white text-black ">
        <PaymentSuccessIcon />
        <p className="text-2xl">Payment done</p>
        <p>
          Your account balance will be updated shortly. <br />
          If you encounter any issues, please contact our{' '}
          <a className="text-blue-600" href="mailto:support@niza.io">
            support team.
          </a>
        </p>
      </div>
    );
  }
  if (status === 'cancel' || status === 'fail') {
    return (
      <div className="flex flex-col justify-center items-center h-screen text-center gap-2 bg-white text-black ">
        <PaymentRejectedIcon />
        <p className="text-2xl">Payment Canceled</p>
        <p>Process Failed</p>
      </div>
    );
  }

  return (
    <div className="flex flex-col justify-center items-center h-screen text-center gap-2 bg-white text-black ">
      <LoadingIcon className="h-32 w-32" />
    </div>
  );
};

export default PaymentStatus;

import * as React from 'react';
import type { SVGProps } from 'react';
const MerchantLogoLight = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={46}
    height={49}
    viewBox="0 0 46 49"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M10.5461 16.1327V32.4422C10.5461 33.1759 10.9479 33.8507 11.5929 34.2004L14.6102 35.8364C15.3037 36.2123 16.1534 36.1456 16.7796 35.6659L18.65 34.2333C19.1441 33.8549 19.4338 33.268 19.4338 32.6456V29.1199C19.4338 27.5696 21.1213 26.6088 22.4545 27.4L24.599 28.6726C25.2395 29.0527 26.0382 29.0454 26.6716 28.6537L30.0715 26.5511C30.7377 26.1391 31.5975 26.6183 31.5975 27.4016V32.5474C31.5975 33.2244 31.94 33.8555 32.5077 34.2244L34.755 35.6847C35.465 36.1461 36.3886 36.1103 37.0609 35.5954L38.8392 34.2333C39.3333 33.8549 39.623 33.268 39.623 32.6456V16.1711C39.623 15.3176 39.0814 14.5581 38.2744 14.2802L34.8955 13.1163C34.3173 12.9172 33.6798 12.9935 33.1649 13.3235L26.1374 17.828C25.8311 18.0243 25.4424 18.0388 25.1224 17.8658L16.5496 13.233C16.0417 12.9585 15.4399 12.9169 14.899 13.119L11.8464 14.2591C11.0645 14.5511 10.5461 15.298 10.5461 16.1327Z"
      fill="url(#paint0_radial_755_57)"
    />
    <path
      d="M18.6694 33.2631V24.4283C18.6694 24.4174 18.6638 24.4073 18.6547 24.4015L14.8713 21.9817C14.8501 21.9681 14.8223 21.9834 14.8223 22.0086V36.161C14.8223 36.1874 14.8526 36.2024 14.8735 36.1863L18.6569 33.2884C18.6648 33.2824 18.6694 33.273 18.6694 33.2631Z"
      fill="#1DA069"
      stroke="#1DA069"
      strokeWidth={0.0607354}
    />
    <path
      d="M1.00171 15.8912C1.48945 11.3421 5.66808 8.82914 7.69643 8.1413L5.08185 10.3338L3.9094 13.7339C5.59353 15.457 7.44249 17.0118 9.44744 18.348C13.4828 21.0376 18.5814 24.3312 19.4327 24.4853C20.5208 24.6823 21.4884 28.4834 21.8362 30.3593L21.2266 41.9197L22.5866 45.7537L25.4826 46.8675L29.6331 45.7537C28.5818 46.6174 25.8109 48.3706 23.1377 48.4738C20.4645 48.577 17.2011 46.7034 15.9036 45.7537C13.5548 44.2881 8.1021 40.9372 5.08185 39.2583C2.06161 37.5793 1.10332 33.5328 1.00171 31.7194V15.8912Z"
      fill="black"
      stroke="black"
      strokeWidth={0.187593}
    />
    <path
      d="M6.59294 9.2201L19.7596 1.51707C21.9721 0.222674 25.7274 0.80186 27.193 1.51707C30.5931 3.51806 38.1273 8.00309 41.0631 9.9353C43.999 11.8675 44.858 15.1566 44.9205 16.5597C44.9791 20.1435 45.0612 28.1881 44.9205 31.6961C44.7798 35.2041 41.876 38.168 40.4417 39.2115L27.7206 46.469C24.5901 48.0284 21.7411 45.8359 21.4597 43.6903C21.4909 39.9893 21.5347 31.9071 21.4597 29.187C21.3846 26.4669 18.8021 23.9969 17.5202 23.102L5.9242 16.2083C5.71444 16.0836 5.5198 15.932 5.3601 15.7475C2.69901 12.673 5.05535 10.2034 6.59294 9.2201Z"
      stroke="black"
      strokeWidth={1.31315}
    />
    <defs>
      <radialGradient
        id="paint0_radial_755_57"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(46 35.9999) rotate(-148.241) scale(24.6982 27.4922)">
        <stop stopColor="#1DA069" />
        <stop offset={1} stopColor="#25F49D" />
      </radialGradient>
    </defs>
  </svg>
);
export default MerchantLogoLight;

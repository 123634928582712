import * as React from 'react';
import type { SVGProps } from 'react';
const ReverseLeftSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={10}
    height={11}
    viewBox="0 0 10 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M1 3.5H6C7.65685 3.5 9 4.84315 9 6.5C9 8.15685 7.65685 9.5 6 9.5H1M1 3.5L3 1.5M1 3.5L3 5.5"
      stroke="#667085"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const ReverseLeft = React.memo(ReverseLeftSvg);

export default ReverseLeft;

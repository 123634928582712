import * as React from 'react';
import type { SVGProps } from 'react';
const PaymentSuccessIconSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={117}
    height={116}
    viewBox="0 0 117 116"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      opacity={0.1}
      d="M7.20556 73.0749L2.72284 73.9617C2.50482 73.1982 2.30205 72.4283 2.11488 71.6523C1.92909 70.8819 1.75867 70.1056 1.60397 69.3236C0.87972 65.6627 0.5 61.8778 0.5 58.0043C0.5 54.1308 0.87972 50.346 1.60397 46.685C1.75867 45.903 1.92909 45.1267 2.11488 44.3563C2.30205 43.5803 2.50482 42.8104 2.72285 42.0469L7.20556 42.9337L6.28042 47.6101C5.61613 50.968 5.26709 54.4431 5.26709 58.0043C5.26709 61.5656 5.61612 65.0406 6.28042 68.3985L7.20556 73.0749Z"
      fill="#12B76A"
    />
    <path
      opacity={0.17}
      d="M32.8847 104.922L30.3418 108.721C29.6453 108.333 28.9577 107.932 28.2792 107.517C27.6006 107.102 26.9312 106.673 26.2714 106.232C19.9464 101.997 14.5008 96.5515 10.2664 90.2266C9.82465 89.5667 9.39607 88.8973 8.98105 88.2187C8.56614 87.5403 8.16478 86.8527 7.77734 86.1562L11.5757 83.6133L14.2277 87.5746C18.1155 93.3817 23.1162 98.3825 28.9234 102.27L32.8847 104.922Z"
      fill="#12B76A"
    />
    <path
      opacity={0.3}
      d="M73.573 109.297L74.4598 113.78C73.6963 113.998 72.9263 114.2 72.1503 114.388C71.38 114.573 70.6036 114.744 69.8217 114.898C66.1607 115.623 62.3759 116.002 58.5024 116.002C54.6288 116.002 50.844 115.623 47.183 114.898C46.4011 114.744 45.6247 114.573 44.8544 114.388C44.0784 114.2 43.3084 113.998 42.5449 113.78L43.4317 109.297L48.1082 110.222C51.4661 110.886 54.9411 111.235 58.5024 111.235C62.0636 111.235 65.5386 110.886 68.8965 110.222L73.573 109.297Z"
      fill="#12B76A"
    />
    <path
      opacity={0.4}
      d="M105.422 83.6133L109.221 86.1562C108.833 86.8527 108.432 87.5403 108.017 88.2187C107.602 88.8973 107.173 89.5668 106.732 90.2266C102.497 96.5515 97.0515 101.997 90.7266 106.232C90.0668 106.673 89.3973 107.102 88.7187 107.517C88.0403 107.932 87.3527 108.333 86.6562 108.721L84.1133 104.922L88.0746 102.27C93.8817 98.3825 98.8825 93.3817 102.77 87.5746L105.422 83.6133Z"
      fill="#12B76A"
    />
    <path
      opacity={0.6}
      d="M114.884 71.6523C115.069 70.8819 115.24 70.1056 115.395 69.3236C116.119 65.6627 116.499 61.8778 116.499 58.0043C116.499 54.1308 116.119 50.3459 115.395 46.685C115.24 45.903 115.069 45.1267 114.884 44.3563C114.696 43.5803 114.494 42.8104 114.276 42.0469L109.793 42.9337L110.718 47.6101C111.382 50.968 111.731 54.4431 111.731 58.0043C111.731 61.5656 111.382 65.0406 110.718 68.3985L109.793 73.0749L114.276 73.9617C114.494 73.1982 114.696 72.4283 114.884 71.6523Z"
      fill="#12B76A"
    />
    <path
      opacity={0.8}
      d="M84.1133 11.0757L86.6562 7.27734C87.3527 7.66478 88.0403 8.06614 88.7187 8.48105C89.3973 8.89607 90.0667 9.32465 90.7266 9.7664C97.0515 14.0008 102.497 19.4464 106.732 25.7714C107.173 26.4312 107.602 27.1006 108.017 27.7792C108.432 28.4577 108.833 29.1453 109.221 29.8418L105.422 32.3847L102.77 28.4234C98.8825 22.6162 93.8817 17.6155 88.0746 13.7277L84.1133 11.0757Z"
      fill="#12B76A"
    />
    <path
      opacity={0.8}
      d="M43.4317 6.70556L42.5449 2.22284C43.3084 2.00482 44.0784 1.80205 44.8544 1.61488C45.6247 1.42909 46.4011 1.25867 47.183 1.10397C50.844 0.37972 54.6288 0 58.5024 0C62.3759 0 66.1607 0.37972 69.8217 1.10397C70.6037 1.25867 71.38 1.42909 72.1503 1.61488C72.9263 1.80205 73.6963 2.00482 74.4598 2.22285L73.573 6.70556L68.8965 5.78042C65.5386 5.11613 62.0636 4.76709 58.5024 4.76709C54.9411 4.76709 51.4661 5.11612 48.1082 5.78042L43.4317 6.70556Z"
      fill="#12B76A"
      fillOpacity={0.8}
    />
    <path
      opacity={0.4}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.77734 29.8418L11.5757 32.3847L14.2277 28.4234C18.1155 22.6163 23.1163 17.6155 28.9234 13.7277L32.8847 11.0757L30.3418 7.27734C29.6453 7.66478 28.9577 8.06614 28.2792 8.48106C27.6006 8.89607 26.9312 9.32465 26.2714 9.7664C19.9464 14.0008 14.5008 19.4464 10.2664 25.7714C9.82465 26.4312 9.39607 27.1006 8.98106 27.7792C8.56614 28.4577 8.16478 29.1453 7.77734 29.8418Z"
      fill="#12B76A"
    />
    <path
      d="M81.0334 46.5959L52.0334 75.5959C51.865 75.7644 51.6651 75.8981 51.4451 75.9893C51.2251 76.0805 50.9892 76.1274 50.751 76.1274C50.5128 76.1274 50.277 76.0805 50.0569 75.9893C49.8369 75.8981 49.637 75.7644 49.4687 75.5959L36.7812 62.9084C36.4411 62.5683 36.25 62.107 36.25 61.626C36.25 61.145 36.4411 60.6838 36.7812 60.3437C37.1213 60.0036 37.5825 59.8125 38.0635 59.8125C38.5445 59.8125 39.0058 60.0036 39.3459 60.3437L50.751 71.7511L78.4687 44.0312C78.8088 43.6911 79.27 43.5 79.751 43.5C80.232 43.5 80.6933 43.6911 81.0334 44.0312C81.3735 44.3713 81.5645 44.8325 81.5645 45.3135C81.5645 45.7945 81.3735 46.2558 81.0334 46.5959Z"
      fill="#12B76A"
    />
  </svg>
);

const PaymentSuccessIcon = React.memo(PaymentSuccessIconSvg);
export default PaymentSuccessIcon;

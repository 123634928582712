import type { FC } from 'react';
import React from 'react';
import type { ToastStateProps } from 'services/types';
import { CopyIcon, GearIcon } from 'assets';
import { Button } from 'components';
import { useUpdateOnrampProgress } from 'services';
import { getUrlParams } from 'utils';
import { useOnrampInitialData } from 'providers/OnrampDataProvider';

type PendingStatusResponseProps = {
  paymentSum: number;
  sourceCurrency: string | undefined;
  beneficiary: string | undefined;
  reference: string | null | undefined;
  setToastState: (value: React.SetStateAction<ToastStateProps>) => void;
  setUrlStep: (
    s: React.SetStateAction<
      Partial<{
        step?: any;
      }>
    >,
  ) => void;
};

const PendingStatusResponse: FC<PendingStatusResponseProps> = ({
  beneficiary,
  paymentSum,
  reference,
  setToastState,
  sourceCurrency,
  setUrlStep,
}) => {
  const initialData = getUrlParams('data');
  const { updateOnrampProgress } = useUpdateOnrampProgress();
  const { onrampProgress, setOnrampProgress } = useOnrampInitialData();

  return (
    <div className="flex flex-col  items-center min-h-[600px] justify-between">
      <div className="mx-2 md:mx-12">
        <p className="text-xl text-center">
          Your{' '}
          <span className="text-primary">
            {paymentSum.toFixed(2)} {sourceCurrency}{' '}
          </span>
          transfer to <br />
          <span className="text-primary">{beneficiary}</span> was successfully
          initiated
          <br />
          Your bank is still processing the <br /> transaction
        </p>
      </div>

      <GearIcon />

      <div>
        <p className="mb-2 text-center text-gray-500 dark:text-gray-300">
          Reference number
        </p>
        <div className="text-center flex border-b pb-1 gap-2">
          <div className="min-w-32">
            <p className="text-center text-gray-400">
              {reference ?? 'Reference'}
            </p>
          </div>
          <CopyIcon
            className="dark:stroke-white w-6 h-6 cursor-pointer"
            stroke="#292929"
            onClick={() => {
              navigator.clipboard.writeText(reference ?? '');
              setToastState({
                active: true,
                toastMessage: 'Reference copied successfully',
                variant: 'success',
              });
            }}
          />
        </div>
      </div>

      <Button
        name="Done"
        variant="primary"
        className="w-full"
        onClick={() => {
          if (
            initialData &&
            onrampProgress?.data?.order_id &&
            setOnrampProgress
          ) {
            updateOnrampProgress(
              {
                on_ramp_data: initialData,
                order_id: onrampProgress?.data.order_id,
                payment_method: null,
              },
              {
                onSuccess: () => {
                  setOnrampProgress({
                    ...onrampProgress,
                    data: {
                      ...onrampProgress?.data,
                      payment_method: null,
                    },
                  });
                  setUrlStep({
                    step: undefined,
                  });
                },
                onError: () => {
                  setUrlStep({
                    step: undefined,
                  });
                },
              },
            );
          }
        }}
      />
    </div>
  );
};

export default PendingStatusResponse;

import type { FC, ReactElement } from 'react';
import React from 'react';
import type { PaymentMethod } from 'services/types';
import { PayByBank, PayByBankTransfer, PayByCard } from './paymentProcessors';
import { useGetOnrampBankDetails } from 'services';

type OnrampPaymentProcessorProps = {
  setSelectedPayment: React.Dispatch<
    React.SetStateAction<PaymentMethod | undefined>
  >;
  selectedPayment: PaymentMethod | undefined;
};

const OnrampPaymentProcessor: FC<OnrampPaymentProcessorProps> = ({
  setSelectedPayment,
  selectedPayment,
}) => {
  const { data: bankDetails } = useGetOnrampBankDetails(
    selectedPayment?.default_bank?.toString() ?? '',
  );

  const payByProcessor: Record<
    'emp-card' | 'yapily' | 'other' | string,
    ReactElement
  > = {
    'emp-card': <PayByCard selectedPayment={selectedPayment} />,
    yapily: (
      <PayByBank selectedPayment={selectedPayment} bankDetails={bankDetails} />
    ),
    other: <PayByBankTransfer selectedPayment={selectedPayment} />,
  };

  return (
    <div>
      <div>
        {selectedPayment?.provider
          ? payByProcessor[selectedPayment?.provider]
          : null}
      </div>
    </div>
  );
};

export default OnrampPaymentProcessor;

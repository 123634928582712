import type { FC } from 'react';
import React from 'react';
import { FindIcon } from 'assets';

const NotFound: FC = () => {
  return (
    <div className="flex justify-center h-full items-center">
      <div className="max-w-lg w-full border p-2 md:p-5 rounded-3xl bg-bg-dark dark:bg-white">
        <h1 className="text-6xl sm:text-7xl md:text-9xl text-center text-primary">
          404
        </h1>
        <div className="flex flex-row gap-2 md:gap-5">
          <div className="bg-white flex flex-1 items-center dark:bg-bg-dark text-black dark:text-white p-5 md:p-5 lg:p-10  rounded-3xl ">
            <p className="text-lg sm:text-xl md:text-2xl font-bold">
              We're sorry, but the page you're looking for does not exists.
            </p>
          </div>
          <FindIcon className="stroke-primary h-28 w-28 md:h-52 md:w-52" />
        </div>
      </div>
    </div>
  );
};

export default NotFound;

import * as React from 'react';
import type { SVGProps } from 'react';
const MailIconSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <g clipPath="url(#clip0_203_19463)">
      <path
        d="M3 5L12 14L21 5"
        stroke="#1DA069"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 5H21V17C21 18.1046 20.1046 19 19 19H5C3.89543 19 3 18.1046 3 17V5Z"
        stroke="fill"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_203_19463">
        <rect width={24} height={24} fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const MailIcon = React.memo(MailIconSvg);

export default MailIcon;

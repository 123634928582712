import environment from './config';

type ImageSize = '60x60' | '150x150' | '180x180';

type AssetImageUriType = {
  asset_id?: string;
  size?: ImageSize;
  extraSlash?: boolean;
  paymentImages?: boolean;
};

export const getAssetImageUri = ({
  asset_id = 'NIZA',
  size = '60x60',
  extraSlash = true,
  paymentImages,
}: AssetImageUriType) => {
  if (paymentImages) {
    return `${environment.nizaAppUrl}/${asset_id}`;
  }
  return `${environment.nizaAppUrl}/storage/icons/assets/${size}${extraSlash ? '/' : ''}${asset_id}.png`;
};

import { FilesAttachment, MailIcon, QuestionMarkIcon } from 'assets';
import { Button, Input, InputError, Skeleton, Switch, Toast } from 'components';
import { Formik } from 'formik';
import type { FC } from 'react';
import { useRef, useState } from 'react';
import {
  API_ENDPOINTS,
  queryClient,
  useChangeBrandDetails,
  useGetBusinessBrandDetails,
} from 'services';
import type { ToastStateProps } from 'services/types';
import BrandLogoUpload from './BrandLogoUpload';

const BrandDetails: FC = () => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { data: brandDetails, isLoading } = useGetBusinessBrandDetails();
  const { changeBrandDetails, isChangingBrandDetails } =
    useChangeBrandDetails();
  const [toastState, setToastState] = useState<ToastStateProps>({
    active: false,
    toastMessage: '',
  });

  return (
    <div className="space-y-5">
      <div className="bg-white dark:bg-bg-dark p-5 rounded-2xl">
        <h1 className="text-xl">Registered Details</h1>

        <div className="mt-4 flex gap-10 justify-between flex-wrap">
          <div className="flex gap-2">
            <MailIcon className="stroke-black dark:stroke-white mt-1" />
            <div>
              <h3>Email Address</h3>
              <p className="text-gray-500">{brandDetails?.data?.email}</p>
            </div>
          </div>

          <div className="flex gap-2">
            <FilesAttachment className="stroke-black dark:stroke-white mt-1" />
            <div>
              <h3>Website</h3>
              <p className="text-gray-500">{brandDetails?.data?.website}</p>
            </div>
          </div>

          <div className="flex gap-2">
            <FilesAttachment className="stroke-black dark:stroke-white mt-1" />
            <div>
              <h3>Company Name</h3>
              <p className="text-gray-500">
                {brandDetails?.data?.first_name} {brandDetails?.data?.last_name}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white dark:bg-bg-dark p-5 rounded-2xl">
        <p className="text-xl">Brand Details</p>

        <Formik
          initialValues={{
            brand_name: brandDetails?.data?.brand_name || '',
            website: brandDetails?.data?.website || '',
            send_emails_to_customers:
              !!brandDetails?.data?.send_emails_to_customers,
            brand_logo: null,
          }}
          enableReinitialize={true}
          onSubmit={(values, { setSubmitting }) => {
            const formData = new FormData();
            formData.append('brand_name', values.brand_name);
            formData.append('website', values.website);
            formData.append(
              'send_emails_to_customers',
              values?.send_emails_to_customers ? '1' : '0',
            );
            if (values.brand_logo) {
              formData.append('brand_logo', values.brand_logo);
            }
            const data: any = formData;

            changeBrandDetails(data, {
              onSuccess: (response) => {
                queryClient.invalidateQueries({
                  queryKey: [API_ENDPOINTS.GET_BUSINESS_BRAND_DETAILS],
                });
                setToastState({
                  active: true,
                  toastMessage: response.message,
                });
                setTimeout(() => {
                  setSubmitting(false);
                }, 400);
              },
              onError: (error) => {
                setToastState({
                  active: true,
                  toastMessage: error.response?.data.message,
                  variant: 'error',
                });
                setSubmitting(false);
              },
            });
          }}>
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit} id="addInvoiceForm">
              <div className="mt-4 max-w-[400px] h-max">
                <BrandLogoUpload
                  fileInputRef={fileInputRef}
                  setFieldValue={setFieldValue}
                  brandSrc={brandDetails?.data?.brand_logo}
                  setToastState={setToastState}
                />
              </div>
              <div className="max-w-[400px]">
                <p className="mt-4 text-gray-700 dark:text-gray-400 font-medium text-sm mb-2">
                  Brand Name
                </p>

                {isLoading ? (
                  <Skeleton className="w-full h-12 rounded-lg" />
                ) : (
                  <Input
                    type="text"
                    name="brand_name"
                    placeholder="Client business"
                    value={values.brand_name}
                    onChange={handleChange}
                    handleBlur={handleBlur}
                    iconEnd={
                      <QuestionMarkIcon className="stroke-black dark:stroke-white mb-1" />
                    }
                  />
                )}

                {errors.brand_name && touched.brand_name ? (
                  <InputError
                    errorMessage={errors.brand_name}
                    className="ml-2 md:ml-32 pt-2 "
                  />
                ) : null}

                <div className="max-w-[400px]">
                  <p className="mt-4 text-gray-700 dark:text-gray-400 font-medium text-sm mb-2">
                    Website
                  </p>

                  {isLoading ? (
                    <Skeleton className="w-full h-12 rounded-lg" />
                  ) : (
                    <Input
                      type="text"
                      name="website"
                      placeholder="Client business"
                      value={values.website}
                      onChange={handleChange}
                      handleBlur={handleBlur}
                      iconEnd={
                        <QuestionMarkIcon className="stroke-black dark:stroke-white mb-1" />
                      }
                    />
                  )}
                </div>

                <div className="mt-4 flex justify-between">
                  <p className="text-gray-700 font-medium dark:text-gray-300">
                    Send Emails to your customers
                  </p>

                  {isLoading ? (
                    <div>
                      <Skeleton className="w-11 h-6 rounded-full" />
                    </div>
                  ) : (
                    <Switch
                      name="send_emails_to_customers"
                      checked={values.send_emails_to_customers}
                      handleBlur={handleBlur}
                      onChange={handleChange}
                      setFieldValue={setFieldValue}
                    />
                  )}
                </div>

                <Button
                  name="Change"
                  className="mt-5"
                  variant="primary"
                  type="submit"
                  isLoading={isSubmitting || isChangingBrandDetails}
                />
              </div>
            </form>
          )}
        </Formik>
      </div>
      {toastState.active && (
        <Toast
          text={toastState?.toastMessage || 'Error'}
          onClose={() => setToastState({ active: false, toastMessage: '' })}
          variant={toastState?.variant || 'default'}
        />
      )}
    </div>
  );
};

export default BrandDetails;

import * as React from 'react';
import type { SVGProps } from 'react';
const HelpIconSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <g clipPath="url(#clip0_81_117)">
      <circle
        cx={12}
        cy={11.9999}
        r={9}
        stroke="fill"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x={12}
        y={8}
        width={0.01}
        height={0.01}
        stroke="#1DA069"
        strokeWidth={3}
        strokeLinejoin="round"
      />
      <path
        d="M12 12V16"
        stroke="#1DA069"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_81_117">
        <rect width={24} height={24} fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const HelpIcon = React.memo(HelpIconSvg);

export default HelpIcon;

import * as React from 'react';
import type { SVGProps } from 'react';
const NizaLogoSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={29}
    height={27}
    viewBox="0 0 29 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M21.6442 1.42219L21.516 1.16589H7.51602L0.5 13.5L7.48398 25.8341H21.484L28.5 13.5L21.6442 1.42219ZM9.14989 5.58695L1.65332 12.7632L7.61213 2.25514L9.14989 5.58695ZM21.1316 2.2231L23.1819 13.6602L10.3032 5.74713L21.1316 2.2231ZM9.85469 6.22768L22.9897 14.3009L9.85469 21.7654V6.22768ZM23.1499 14.9416L20.8433 24.8089L10.3993 22.214L23.1499 14.9416ZM9.21396 21.5092L1.65332 13.6602L9.21396 6.38786V21.5092ZM9.11785 22.3421L7.64416 24.8089L2.1659 15.1339L9.11785 22.3421ZM9.69451 22.6625L19.754 25.1934H8.15675L9.69451 22.6625ZM23.8867 14.5572L27.603 13.7563L21.5801 24.3925L23.8867 14.5572ZM27.5389 13.1476L23.8867 13.9485L21.9966 3.34438L27.5389 13.1476ZM9.69451 5.26658L8.09268 1.80663H20.3307L9.69451 5.26658Z"
      fill="url(#paint0_linear_213_21669)"
      stroke="url(#paint1_linear_213_21669)"
      strokeWidth={0.782585}
    />
    <defs>
      <linearGradient
        id="paint0_linear_213_21669"
        x1={28.6582}
        y1={6.25987}
        x2={0.376369}
        y2={4.52179}
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#F2F230" />
        <stop offset={1} stopColor="#888827" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_213_21669"
        x1={28.6582}
        y1={6.25987}
        x2={0.376369}
        y2={4.52179}
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#F2F230" />
        <stop offset={1} stopColor="#888827" />
      </linearGradient>
    </defs>
  </svg>
);

const NizaLogo = React.memo(NizaLogoSvg);

export default NizaLogo;

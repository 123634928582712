import { XIcon } from 'assets';
import type { FC, ReactElement } from 'react';
import React from 'react';

interface ModalProps {
  onClose?: () => void;
  showModal: boolean;
  children?: ReactElement;
  header?: ReactElement;
  footer?: ReactElement;
  className?: string;
}

const Modal: FC<ModalProps> = ({
  onClose,
  showModal,
  children,
  header,
  footer,
  className,
}) => {
  return (
    <>
      {showModal ? (
        <>
          <div
            onClick={() => onClose && onClose()}
            className={`flex justify-center items-center  overflow-x-hidden overflow-y-auto fixed inset-0 z-40 outline-none focus:outline-none mx-2 ${className}`}>
            <div
              className="relative my-6 mx-auto max-w-fit w-full"
              onClick={(e) => e.stopPropagation()}>
              {onClose && (
                <button
                  className="absolute z-50 top-5 right-5"
                  onClick={() => onClose()}>
                  <XIcon className="stroke-black dark:stroke-white" />
                </button>
              )}

              <div className="border-0 rounded-[20px] shadow-lg relative flex flex-col w-full bg-white dark:bg-bg-dark outline-none focus:outline-none  pt-4 max-w-[642px]">
                <div>{header && header}</div>
                <div className="relative p-6 flex-auto overflow-auto">
                  {children}
                </div>

                <div>{footer && footer}</div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-35 bg-black dark:bg-gray-700"></div>
        </>
      ) : null}
    </>
  );
};

export default Modal;

import type { FC } from 'react';
import type {
  ActionMeta,
  DropdownIndicatorProps,
  StylesConfig,
  OptionProps,
  GroupBase,
  SingleValueProps,
} from 'react-select';
import ReactSelect, { components, GroupProps } from 'react-select';
import { DownArrow } from 'assets';
import { Skeleton } from '../../Skeleton';
import type { FilterOptionOption } from 'react-select/dist/declarations/src/filters';
import { getAssetImageUri } from '../../../utils';

interface OptionType {
  id: string | number | undefined;
  label: string | undefined;
  value: string | undefined;
  icon?: string;
}

interface ReactSelectComponentProps {
  onClick: (val: any) => void;
  name: string;
  className?: string;
  options?: OptionType[];
  initialOption?: OptionType;
  value?: OptionType;
  filterOption?:
    | ((option: FilterOptionOption<unknown>, inputValue: string) => boolean)
    | null
    | undefined;
  isLoading?: boolean;
  placeholder?: string;
  renderIcon?: boolean;
}

const ReactSelectComponent: FC<ReactSelectComponentProps> = ({
  name,
  onClick,
  className,
  options,
  initialOption,
  value,
  filterOption,
  isLoading,
  placeholder,
  renderIcon = false,
}) => {
  const handleChange = (
    selectedOption: unknown,
    actionMeta: ActionMeta<unknown>,
  ) => {
    const { action } = actionMeta;

    if (action === 'select-option') {
      onClick(selectedOption);
    }
  };

  const { Option, SingleValue } = components;
  const IconOption: FC<
    OptionProps<OptionType, false, GroupBase<OptionType>>
  > = (props) => (
    <Option {...props}>
      <div className="flex items-center w-full justify-between">
        {props?.data?.label}

        <img
          src={getAssetImageUri({
            asset_id: props?.data?.icon,
          })} // Use props.data.icon directly
          style={{ width: 36 }}
          alt={props?.data?.label}
        />
      </div>
    </Option>
  );

  const IconSingleValue = ({ children, ...props }: SingleValueProps<any>) => (
    <components.SingleValue {...props}>
      <div className="flex items-center w-full justify-between">
        {children}
        <img
          src={getAssetImageUri({
            asset_id: props?.data?.icon,
          })} // Use props.data.icon directly
          style={{ width: 30 }}
          alt={props?.data?.label}
        />
      </div>
    </components.SingleValue>
  );

  const DropdownIndicator: React.FC<DropdownIndicatorProps> = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <DownArrow />
      </components.DropdownIndicator>
    );
  };

  const selectStyles: StylesConfig = {
    container: (styles) => ({
      ...styles,
      outline: 'none',
      color: 'white',
    }),
    control: (styles, state) => ({
      ...styles,
      height: '50px',
      borderWidth: '1px',
      borderRadius: '0.5rem',
      borderColor: '#D0D5DD',
      outline: state.isFocused ? ' #1DA069 solid 2px' : '',
      color: 'white',
    }),
    valueContainer: (styles) => ({ ...styles, color: 'white' }),
    singleValue: (styles) => ({ ...styles, color: 'black' }),
    menu: (styles) => ({ ...styles, backgroundColor: '#F9F9F9' }),
    option: (styles) => ({ ...styles, color: 'black' }),
  };

  const renderValue = () => {
    if (value?.value) {
      return value;
    } else if (initialOption?.value) {
      return initialOption;
    } else {
      return null;
    }
  };

  return (
    <div className={`w-full ${className}`}>
      {isLoading ? (
        <div className="w-full">
          <Skeleton className="h-[50px] w-full rounded-lg" />
        </div>
      ) : (
        <ReactSelect
          name={name}
          onChange={handleChange}
          value={renderValue()}
          placeholder={placeholder}
          hideSelectedOptions
          styles={selectStyles}
          components={{
            IndicatorSeparator: () => null,
            DropdownIndicator,
            Option: renderIcon ? (IconOption as any) : Option,
            SingleValue: renderIcon ? IconSingleValue : SingleValue,
          }}
          options={options}
          classNames={{
            control: () => 'dark:bg-bg-dark dark:border-gray-600',
            placeholder: () => 'dark:text-gray-400',
            input: () => 'dark:text-white',
            singleValue: () => 'dark:text-white',
            menu: () => 'dark:bg-bg-secondaryDark',
            option: ({ isFocused }) =>
              `${isFocused ? 'dark:bg-primary dark:bg-opacity-15' : 'dark:bg-bg-dark'} dark:bg-bg-secondaryDark dark:text-white`,
          }}
        />
      )}
    </div>
  );
};

export default ReactSelectComponent;

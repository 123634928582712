import type { FC } from 'react';
import React, { useState } from 'react';
import { FolderImageIcon, ImageIcon, RemoveIcon } from 'assets';
import type { FormikErrors } from 'formik';
import { Button, Modal } from 'components';
import { API_ENDPOINTS, queryClient, useRemoveBrandLogo } from 'services';
import type { ToastStateProps } from '../../../services/types';

type BrandLogoUploadProps = {
  fileInputRef: React.RefObject<HTMLInputElement>;
  setFieldValue?:
    | ((
        field: string,
        value: unknown,
        shouldValidate?: boolean | undefined,
      ) => Promise<void | FormikErrors<unknown>>)
    | undefined;
  brandSrc?: string | null;
  setToastState: React.Dispatch<React.SetStateAction<ToastStateProps>>;
};

const BrandLogoUpload: FC<BrandLogoUploadProps> = ({
  fileInputRef,
  setFieldValue,
  brandSrc,
  setToastState,
}) => {
  const [imageUrl, setImageUrl] = useState('');
  const [modalState, setModalState] = useState<boolean>(false);

  const { removeBrandLogo, isRemovingBrandLogo } = useRemoveBrandLogo();

  const handleFileInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImageUrl(reader.result as string);

        if (setFieldValue) setFieldValue('brand_logo', file);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleRemoveBrandLogo = () => {
    if (!brandSrc) {
      setImageUrl('');
      if (setFieldValue) setFieldValue('brand_logo', undefined);
      setModalState(false);
    } else {
      removeBrandLogo(undefined, {
        onSuccess: (response) => {
          setModalState(false);
          setToastState({
            active: true,
            variant: 'success',
            toastMessage: response.message,
          });
          queryClient.refetchQueries({
            queryKey: [API_ENDPOINTS.GET_BUSINESS_BRAND_DETAILS],
          });
          setImageUrl('');
        },
        onError: (error) => {
          setModalState(false);
          setToastState({
            active: true,
            variant: 'success',
            toastMessage: error.response?.data.message,
          });
        },
      });
    }
  };

  return (
    <div className="max-h-[400px]">
      {imageUrl || brandSrc ? (
        <div className="relative overflow-hidden h-[150px] border dark:border-gray-600 border-gray-300  rounded-xl ">
          <img
            src={imageUrl || brandSrc || ''}
            alt="Uploaded"
            className="w-full h-[150px] object-cover"
          />

          <div className="absolute text-black dark:text-white top-2 right-2 flex gap-2 items-center border px-2 py-1 rounded-lg bg-bg-secondaryLight bg-opacity-50 dark:bg-bg-dark dark:bg-opacity-50">
            <div
              className="flex gap-2 items-center cursor-pointer"
              onClick={() => handleButtonClick()}>
              <p>Change</p>
              <ImageIcon
                width={'20px'}
                height={'20px'}
                className="fill-black dark:fill-white"
              />
            </div>

            <div
              className="border-l border-borderDark dark:border-borderLight  cursor-pointer"
              onClick={() => setModalState(true)}>
              <RemoveIcon
                width={'20px'}
                height={'20px'}
                className="ml-1 fill-black dark:fill-white"
              />
            </div>
          </div>
        </div>
      ) : (
        <div
          className="relative flex flex-col justify-between border dark:border-gray-600 border-gray-300 rounded-xl p-5 items-center cursor-pointer h-[150px]"
          onClick={() => handleButtonClick()}>
          <div className="border p-3 rounded-xl w-fit ">
            <FolderImageIcon className="stroke-black dark:stroke-white mt-1 " />
          </div>

          <p className="text-primary font-semibold mt-2">
            Click to upload Brand Logo
          </p>

          <p className="font-normal text-xs text-gray-600 dark:text-gray-300">
            SVG, PNG, JPG or GIF (max. 400x400px)
          </p>
        </div>
      )}

      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileInputChange}
      />

      <Modal showModal={modalState} onClose={() => setModalState(false)}>
        <div className="flex flex-col gap-5">
          <p>Do you want to remove logo image?</p>

          <div className="flex justify-center gap-2">
            <Button
              name="Cancel"
              variant="secondary"
              onClick={() => setModalState(false)}
            />
            <Button
              name="Continue"
              variant="primary"
              isLoading={isRemovingBrandLogo}
              onClick={() => {
                handleRemoveBrandLogo();
              }}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default BrandLogoUpload;

import { useMutation, useQuery } from '@tanstack/react-query';
import type { AxiosError } from 'axios';
import client from '../client';
import { API_ENDPOINTS } from '../client/api-endpoints';
import type {
  AddOnrampNewWalletAddressData,
  AddOnrampWalletSuccess,
  AllCountriesResponse,
  AuthorizeOnrampCustomerData,
  AuthorizeOnrampCustomerResponse,
  BankCallbackParams,
  BankCallbackResponse,
  ConvertAssetParams,
  ConvertAssetResponse,
  Country,
  CreateDraftOrderData,
  CryptoPurchaseDetailsResponse,
  CryptoPurchaseResponse,
  DraftOrderResponse,
  FindAssetParams,
  GeneralError,
  GetCryptoPurchasesProps,
  GetInitialOnrampData,
  GetKycTokenResponse,
  GetOnrampInitialDataResponse,
  GetOnrampProgress,
  GetOnrampProgressData,
  InitiateOnrampPayment,
  InitiateOnrampPaymentResponse,
  OnrampCustomerWallets,
  OnrampPayByCard,
  OnrampPaymentInstitutionsResponse,
  OnrampUserDetailsData,
  PaginationParams,
  PayByBnkTransferResponse,
  PayByCardResponse,
  PaymentMethodsResponse,
  ResendOtpCode,
  SimulateOnrampSandboxPaymentData,
  SimulateOnrampSandboxPaymentResponse,
  SuccessResponse,
  SumsubApplicantStatus,
  UpdateOnrampDataResponse,
  UpdateOnrampProgressData,
  UpdateOnrampUserDetailsData,
  VerifyOnrampCustomerData,
  VerifyOnrampCustomerResponse,
  YapilyStatusQueryData,
  YapilyStatusResponse,
} from '../types';

// Initial onramp data
export function useGetInitialOnrampData() {
  const query = useMutation<
    GetOnrampInitialDataResponse,
    AxiosError<GeneralError>,
    GetInitialOnrampData
  >({
    mutationFn: client.onramp.getInitialOnrampData,
  });

  return query;
}

// Verify onramp customer
export function useVerifyOnrampCustomer() {
  const { mutate: verifyOnrampCustomer, isPending: isVerifyingOnrampCustomer } =
    useMutation<
      VerifyOnrampCustomerResponse,
      AxiosError<GeneralError>,
      VerifyOnrampCustomerData
    >({
      mutationFn: client.onramp.verifyOnrampCustomer,
    });

  return {
    verifyOnrampCustomer,
    isVerifyingOnrampCustomer,
  };
}

export function useVerifyOnrampCustomerWithNiza() {
  const {
    mutate: verifyOnrampCustomerWithNiza,
    isPending: isVerifyingOnrampCustomerWithNiza,
  } = useMutation<
    VerifyOnrampCustomerResponse,
    AxiosError<GeneralError>,
    VerifyOnrampCustomerData
  >({
    mutationFn: client.onramp.verifyOnrampCustomerWithNiza,
  });

  return {
    verifyOnrampCustomerWithNiza,
    isVerifyingOnrampCustomerWithNiza,
  };
}

//Resend otp code
export function useResendOtpCode() {
  const { mutate: resendOtpCode, isPending: isResendingOtpCode } = useMutation<
    SuccessResponse,
    AxiosError<GeneralError>,
    ResendOtpCode
  >({
    mutationFn: client.onramp.resendOtpCode,
  });

  return {
    resendOtpCode,
    isResendingOtpCode,
  };
}

// Login onramp customer OTP code
export function useAuthorizeOnrampCustomer() {
  const {
    mutate: authorizeOnrampCustomer,
    isPending: isAuthorizingOnrampCustomer,
  } = useMutation<
    AuthorizeOnrampCustomerResponse,
    AxiosError<GeneralError>,
    AuthorizeOnrampCustomerData
  >({
    mutationFn: client.onramp.authorizeOnrampCustomer,
  });

  return {
    authorizeOnrampCustomer,
    isAuthorizingOnrampCustomer,
  };
}

// Logout onramp customer
export function useLogoutOnrampCustomer() {
  const { mutate: logoutOnrampCustomer, isPending: isLoginOutOnrampCustomer } =
    useMutation<SuccessResponse, AxiosError<GeneralError>, unknown>({
      mutationFn: client.onramp.logoutOnrampCustomer,
    });

  return {
    logoutOnrampCustomer,
    isLoginOutOnrampCustomer,
  };
}

// Onramp customer data
export function useGetOnrampCustomerDetails() {
  const query = useQuery<OnrampUserDetailsData, AxiosError<GeneralError>>({
    queryKey: [API_ENDPOINTS.GET_ONRAMP_CUSTOMER_DETAILS],
    queryFn: client.onramp.getOnrampCustomerDetails,
  });

  return query;
}

// Onramp customer update data
export function useUpdateOnrampCustomerDetails() {
  const {
    mutate: updateCustomerDetails,
    isPending: isUpdatingCustomerDetails,
  } = useMutation<
    SuccessResponse,
    AxiosError<GeneralError>,
    UpdateOnrampUserDetailsData
  >({
    mutationFn: client.onramp.updateOnrampCustomerDetails,
  });

  return {
    updateCustomerDetails,
    isUpdatingCustomerDetails,
  };
}

export function useGetOnrampCustomerWalletAddresses() {
  const query = useQuery<OnrampCustomerWallets, AxiosError<GeneralError>>({
    queryKey: [API_ENDPOINTS.GET_ONRAMP_CUSTOMER_WALLET_ADDRESS],
    queryFn: client.onramp.getOnrampCustomerWalletAddresses,
  });

  return query;
}

// Add onramp customer wallet -> Not functional(Wallet is added by merchant)
export function useAddOnrampNewWalletAddress() {
  const { mutate: addNewWalletAddress, isPending: isAddingNewWalletAddress } =
    useMutation<
      AddOnrampWalletSuccess,
      AxiosError<GeneralError>,
      AddOnrampNewWalletAddressData
    >({
      mutationFn: client.onramp.addOnrampNewWalletAddress,
    });

  return {
    addNewWalletAddress,
    isAddingNewWalletAddress,
  };
}

// Create draft order after customer sumsub verification
export function useCreateDraftOrder() {
  const { mutate: createDraftOrder, isPending: isCreatingDraftOrder } =
    useMutation<
      DraftOrderResponse,
      AxiosError<GeneralError>,
      CreateDraftOrderData
    >({
      mutationFn: client.onramp.createDraftOrder,
    });

  return {
    createDraftOrder,
    isCreatingDraftOrder,
  };
}

// Get Crypto purchases

export function useGetCryptoPurchases(data: GetCryptoPurchasesProps) {
  const query = useQuery<CryptoPurchaseResponse, AxiosError<GeneralError>>({
    queryKey: [
      API_ENDPOINTS.GET_CRYPTO_PURCHASES({
        page: data.page,
        pageSize: data.pageSize,
      }),
    ],
    queryFn: () => client.onramp.getCryptoPurchases(data),
    enabled: data.enabled,
  });

  return query;
}

export function useGetCryptoPurchaseDetails({
  orderId,
  enabled,
}: {
  orderId: string | undefined;
  enabled?: boolean;
}) {
  const query = useQuery<
    CryptoPurchaseDetailsResponse,
    AxiosError<GeneralError>
  >({
    queryKey: [API_ENDPOINTS.GET_CRYPTO_PURCHASE_DETAILS(orderId)],
    queryFn: () => client.onramp.getCryptoPurchaseDetails(orderId),
    enabled: enabled,
  });

  return query;
}

// Sumsub ------------------------------------------------------------ //
export function useCreateSumsubApplicant() {
  const {
    mutate: createSumsubApplicant,
    isPending: isCreatingSumsubApplicant,
  } = useMutation<SuccessResponse, AxiosError<GeneralError>>({
    mutationFn: client.onramp.createSumsubApplicant,
  });

  return {
    createSumsubApplicant,
    isCreatingSumsubApplicant,
  };
}

export function useGetSumsubApplicantAccessToken() {
  const {
    mutate: getSumsubApplicantAccessToken,
    isPending: isGettingApplicantAccessToken,
  } = useMutation<GetKycTokenResponse, AxiosError<GeneralError>>({
    mutationFn: client.onramp.getSumsubApplicantAccessToken,
  });

  return {
    getSumsubApplicantAccessToken,
    isGettingApplicantAccessToken,
  };
}

export function useGetSumsubApplicantStatus() {
  const query = useQuery<SumsubApplicantStatus, AxiosError<GeneralError>>({
    queryKey: [API_ENDPOINTS.GET_SUMSUB_APPLICANT_STATUS],
    queryFn: client.onramp.getSumsubApplicantStatus,
  });

  return query;
}

// Onramp progress ------------------------------------------------------------ //

export function useUpdateOnrampProgress() {
  const { mutate: updateOnrampProgress, isPending: isUpdatingOnrampProgress } =
    useMutation<
      UpdateOnrampDataResponse,
      AxiosError<GeneralError>,
      UpdateOnrampProgressData
    >({
      mutationFn: client.onramp.updateOnrampProgress,
    });

  return { updateOnrampProgress, isUpdatingOnrampProgress };
}

export function useGetOnrampProgress() {
  const { mutate: getOnrampProgress, isPending: isGettingOnrampProgress } =
    useMutation<
      GetOnrampProgress,
      AxiosError<GeneralError>,
      GetOnrampProgressData
    >({
      mutationFn: client.onramp.getOnrampProgress,
    });
  return {
    getOnrampProgress,
    isGettingOnrampProgress,
  };
}

// Onramp payment methods ------------------------------------------- //

export function useInitiateOnrampPayment() {
  const {
    mutate: initiateOnrampPayment,
    isPending: isInitiatingOnrampPayment,
  } = useMutation<
    InitiateOnrampPaymentResponse,
    AxiosError<GeneralError>,
    InitiateOnrampPayment
  >({
    mutationFn: client.onramp.initiateOnrampPayment,
  });

  return {
    initiateOnrampPayment,
    isInitiatingOnrampPayment,
  };
}

export function useGetOnrampPaymentMethods() {
  const query = useQuery<PaymentMethodsResponse, AxiosError<GeneralError>>({
    queryKey: [API_ENDPOINTS.GET_ONRAMP_PAYMENT_METHODS],
    queryFn: client.onramp.getOnrampPaymentMethods,
  });

  return query;
}
// SEPA or SWIFT bank details
export function useGetOnrampBankDetails(bank_id: string) {
  const query = useQuery<PayByBnkTransferResponse, AxiosError<GeneralError>>({
    queryKey: [API_ENDPOINTS.GET_ONRAMP_BANK_DETAILS, bank_id],
    queryFn: () => client.onramp.getOnrampBankDetails(bank_id),
    enabled: !!bank_id,
  });

  return query;
}

export function useFinishManualTransferPayment() {
  const { mutate: finishPayment, isPaused: isFinishingPayment } = useMutation({
    mutationFn: client.onramp.finishPaymentForManualTransfer,
  });
  return {
    finishPayment,
    isFinishingPayment,
  };
}

export function useOnrampPayByCard() {
  const { mutate: payByCard, isPending: isPayingByCard } = useMutation<
    PayByCardResponse,
    AxiosError<GeneralError>,
    OnrampPayByCard
  >({
    mutationFn: client.onramp.onrampPayByCard,
  });

  return {
    payByCard,
    isPayingByCard,
  };
}

export function useGetOnrampPaymentInstitutions() {
  const query = useQuery<
    OnrampPaymentInstitutionsResponse,
    AxiosError<GeneralError>
  >({
    queryKey: [API_ENDPOINTS.GET_ONRAMP_PAYMENT_INSTITUTIONS],
    queryFn: client.onramp.getOnrampPaymentInstitutions,
  });

  return query;
}

export function useGetOnrampYapilyDbStatus(data: YapilyStatusQueryData) {
  const query = useQuery<YapilyStatusResponse, AxiosError<GeneralError>>({
    queryKey: [API_ENDPOINTS.GET_ONRAMP_YAPILY_DB_STATUS, data.consent_id],
    queryFn: () => client.onramp.getOnrampYapilyDbStatus(data),
    enabled: !!data?.consent_id,
  });

  return query;
}

export function useGetOnrampBankCallback(
  data: BankCallbackParams,
  queryParams: string,
) {
  const query = useQuery<BankCallbackResponse, AxiosError<GeneralError>>({
    queryKey: [API_ENDPOINTS.GET_ONRAMP_BANK_CALLBACK(data)],
    queryFn: () => client.onramp.getOnrampBankCallback(data, queryParams),
    enabled: !!data.id && !!data.orderId,
  });

  return query;
}

export function useSimulateOnrampSandboxPayment() {
  const { mutate: simulateOnrampPayment, isPending: isSimulatingPayment } =
    useMutation<
      SimulateOnrampSandboxPaymentResponse,
      AxiosError<GeneralError>,
      SimulateOnrampSandboxPaymentData
    >({
      mutationFn: client.onramp.simulateOnrampSandboxPayment,
    });

  return {
    simulateOnrampPayment,
    isSimulatingPayment,
  };
}

// Onramp assets ------------------------------------------- //

export function useGetOnrampAssetsOfType(data: { type: 'crypto' | 'fiat' }) {
  const query = useQuery({
    queryKey: [API_ENDPOINTS.GET_ONRAMP_ALL_ASSETS_OF_TYPE, data.type],
    queryFn: () => client.onramp.getOnrampAssetsOfType(data),
  });

  return query;
}

export function useConvertOnrampAsset(data: ConvertAssetParams) {
  const query = useQuery<ConvertAssetResponse, AxiosError<GeneralError>>({
    queryKey: [API_ENDPOINTS.CONVERT_ONRAMP_ASSET],
    queryFn: () => client.onramp.convertOnrampAsset(data),
    enabled: !!data.amount && !!data.from && !!data.to,
  });

  return query;
}

export function useGetOnrampSingleAsset(data: FindAssetParams) {
  const query = useQuery({
    queryKey: [API_ENDPOINTS.GET_ONRAMP_SINGLE_ASSET(data.asset_id)],
    queryFn: () => client.onramp.getOnrampSingleAsset(data),
  });

  return query;
}

//Countries

export function useGetAllCountries() {
  const query = useQuery<AllCountriesResponse, AxiosError<GeneralError>>({
    queryKey: [API_ENDPOINTS.GET_ALL_COUNTRIES],
    queryFn: client.country.getAllCountries,
  });

  return query;
}

export function useGetCountry({ countryCode }: { countryCode?: string }) {
  const query = useQuery<Country, AxiosError<GeneralError>>({
    queryKey: [API_ENDPOINTS.GET_COUNTRY(countryCode ?? '')],
    queryFn: () => client.country.getCountry(countryCode ?? ''),
    enabled: !!countryCode,
  });

  return query;
}

import * as React from 'react';
import type { SVGProps } from 'react';
const ArrowLeftSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={14}
    height={14}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M12.8327 6.99999H1.16602M1.16602 6.99999L6.99935 12.8333M1.16602 6.99999L6.99935 1.16666"
      stroke="fill"
      strokeWidth={1.66667}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const ArrowLeft = React.memo(ArrowLeftSvg);

export default ArrowLeft;

import { useMutation, useQuery } from '@tanstack/react-query';
import type { AxiosError } from 'axios';
import client from '../client';
import { API_ENDPOINTS } from '../client/api-endpoints';
import type {
  CreateWebhookConnectionResponse,
  GeneralError,
  GetDeveloperConfigurationsResponse,
  WebhookConnectionData,
} from '../types';

export function useGetDeveloperConfigurations() {
  const query = useQuery<
    GetDeveloperConfigurationsResponse,
    AxiosError<GeneralError>
  >({
    queryKey: [API_ENDPOINTS.GET_DEVELOPER_CONFIGURATIONS],
    queryFn: client.developer.getDevelopersConfigurations,
  });

  return query;
}

export function useCreateWebhookConnection() {
  const {
    mutate: createWebhookConnection,
    isPending: isCreatingWebhookConnection,
  } = useMutation<
    CreateWebhookConnectionResponse,
    AxiosError<GeneralError>,
    WebhookConnectionData
  >({
    mutationFn: client.developer.createWebhookConnection,
  });

  return {
    createWebhookConnection,
    isCreatingWebhookConnection,
  };
}

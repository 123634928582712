import type { FC } from 'react';
import React from 'react';
import { Label, Modal, Tag, Timeline } from 'components';
import type { Order } from 'services/types';
import { CheckMark, DraftIcon, ReverseLeft, XTagIcon } from 'assets';
import { useGetOrderDetails } from 'services';

type OrderDetailsModalProps = {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  initialValues?: Order;
};

const renderStatusTag: { [key: string]: JSX.Element } = {
  completed: <Tag variant="success" text="Paid" iconBefore={<CheckMark />} />,
  refunded: (
    <Tag variant="default" text="Refunded" iconBefore={<ReverseLeft />} />
  ),
  draft: <Tag variant="default" text="Draft" iconBefore={<DraftIcon />} />,
  pending: (
    <Tag variant="warning" text="Pending" iconBefore={<ReverseLeft />} />
  ),
  cancelled: <Tag variant="error" text="Cancelled" iconBefore={<XTagIcon />} />,
};

const OrderDetailsModal: FC<OrderDetailsModalProps> = ({
  setShowModal,
  showModal,
  initialValues,
}) => {
  const { data: orderDetails } = useGetOrderDetails(initialValues?.id || '');

  const timelineData = orderDetails?.data?.events?.map((event) => ({
    date: event.event_date,
    event: event.event,
    status: event.status,
  }));

  const modalHeader = (
    <div className="flex flex-col items-center pb-5 border-b border-solid dark:border-gray-600 border-gray-300 rounded-t ">
      <div className="border p-3 rounded-xl w-fit">
        {/* <UserLogo className="dark:stroke-white stroke-black" /> */}
      </div>

      <h3 className="text-lg font-semibold mt-4">Order details</h3>
      <p className="text-gray-600 dark:text-gray-300 mx-5">
        Explore your Order details.
      </p>
    </div>
  );

  return (
    <div>
      <Modal
        onClose={() => setShowModal(false)}
        showModal={showModal}
        footer={<div></div>}
        header={modalHeader}
        className="inset-0 h-[100vh]">
        <div className="max-h-[50vh] min-w-[300px] md:min-w-[500px]">
          <div className="flex mt-4 md:flex-row flex-col gap-2 items-start md:items-center">
            <Label text="Customer" className="w-36 " />

            <p className="w-full ml-2">
              {orderDetails?.data?.customer || initialValues?.customer}
            </p>
          </div>

          <div className="flex mt-4 md:flex-row flex-col gap-2 items-start md:items-center">
            <Label text="Fiat amount" className="w-36 " />
            <p className="w-full ml-2">
              {orderDetails?.data?.fiat_amount || initialValues?.fiat_amount}{' '}
              {orderDetails?.data?.fiat_currency ||
                initialValues?.fiat_currency}
            </p>
          </div>

          <div className="flex mt-4 md:flex-row flex-col gap-2 items-start md:items-center">
            <Label text="Crypto amount" className="w-36 " />
            <p className="w-full ml-2">
              {orderDetails?.data?.crypto_amount ||
                initialValues?.crypto_amount}{' '}
              {orderDetails?.data?.crypto_currency ||
                initialValues?.crypto_currency}
            </p>
          </div>

          <div className="flex mt-4 pb-2 md:flex-row flex-col gap-2 items-start md:items-center">
            <Label text="Status" className="w-36 " />
            <p className="w-full ml-2">
              {
                renderStatusTag[
                  orderDetails?.data?.status || initialValues?.status || 'paid'
                ]
              }
            </p>
          </div>

          <div className="text-lg my-2 pl-5">Order history</div>

          <Timeline timelines={timelineData} />
        </div>
      </Modal>
    </div>
  );
};

export default OrderDetailsModal;

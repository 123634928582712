import useUrlState from '@ahooksjs/use-url-state';
import {
  KycVerification,
  OnrampEnterEmailStep,
  OnrampStepOne,
  PayStep,
  Toast,
  UserDataStep,
} from 'components';
import { useLocalStorageState } from 'hooks';
import { useState } from 'react';
import type {
  OnrampSessionType,
  OnrampUrlState,
  ToastStateProps,
} from 'services/types';
import { ONRAMP_USER_SESSION } from 'utils';

const Onramp = () => {
  const [urlStep, setUrlStep] = useUrlState<OnrampUrlState>();
  const [toastState, setToastState] = useState<ToastStateProps>({
    active: false,
    toastMessage: '',
  });

  const [onrampSession, setOnrampSession] =
    useLocalStorageState<OnrampSessionType>(ONRAMP_USER_SESSION);

  return (
    <div className="flex justify-center min-h-[70vh] ">
      {!urlStep.step && <OnrampStepOne setUrlStep={setUrlStep} />}

      {urlStep.step === 'verify' && (
        <OnrampEnterEmailStep
          setUrlStep={setUrlStep}
          setOnrampSession={setOnrampSession}
          onrampSession={onrampSession}
          setToastState={setToastState}
        />
      )}

      {urlStep.step === 'user-details' && (
        <UserDataStep setUrlStep={setUrlStep} setToastState={setToastState} />
      )}

      {urlStep.step === 'kyc-verification' && (
        <KycVerification
          setUrlStep={setUrlStep}
          onrampSession={onrampSession}
          setOnrampSession={setOnrampSession}
        />
      )}
      {urlStep.step === 'pay' && (
        <PayStep setUrlStep={setUrlStep} setToastState={setToastState} />
      )}

      {toastState.active && (
        <Toast
          text={toastState?.toastMessage || 'Item copied successfully'}
          onClose={() => setToastState({ active: false, toastMessage: '' })}
          variant="default"
        />
      )}
    </div>
  );
};

export default Onramp;

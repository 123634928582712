import { ArrowLeft, LoadingIcon } from 'assets';
import { Button } from 'components';
import type { FC } from 'react';
import React, { useEffect, useState } from 'react';
import type {
  InitiateOnrampPaymentResponse,
  OnrampPaymentInstitution,
  OnrampUrlState,
  PayByBnkTransferResponse,
  ToastStateProps,
} from 'services/types';
import { useOnrampInitialData } from 'providers/OnrampDataProvider';
import { useConvertOnrampAsset, useGetOnrampYapilyDbStatus } from 'services';
import useUrlState from '@ahooksjs/use-url-state';
import { useCountdownTimer } from 'hooks';
import PendingStatusResponse from '../../components/PendingStatusResponse';
import RejectedStatusResponse from '../../components/RejectedStatusResponse';

type BankAuthenticateProps = {
  selectedInstitution: OnrampPaymentInstitution;
  selectedBank: InitiateOnrampPaymentResponse | undefined;
  setNeedHelp: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedBank: React.Dispatch<
    React.SetStateAction<InitiateOnrampPaymentResponse | undefined>
  >;
  bankDetails: PayByBnkTransferResponse | undefined;
  setToastState: React.Dispatch<React.SetStateAction<ToastStateProps>>;
};

const BankAuthenticate: FC<BankAuthenticateProps> = ({
  selectedBank,
  selectedInstitution,
  setNeedHelp,
  setSelectedBank,
  bankDetails,
  setToastState,
}) => {
  const [desktopLogin, setDesktopLogin] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState<string>();

  const { onrampData } = useOnrampInitialData();
  const [_, setUrlStep] = useUrlState<OnrampUrlState>();

  const { data: convertData } = useConvertOnrampAsset({
    amount: onrampData?.data?.source_amount || '',
    from: onrampData?.data?.source_currency || '',
    to: onrampData?.data?.destination_currency || '',
  });

  const { data: yapilyStatus, refetch } = useGetOnrampYapilyDbStatus({
    consent_id: selectedBank?.consent_id?.toString(),
  });

  const { clearTimer } = useCountdownTimer(3, () => {
    if (
      desktopLogin &&
      paymentStatus !== 'PENDING' &&
      paymentStatus !== 'REJECTED'
    ) {
      refetch();
    }
  });

  useEffect(() => clearTimer(), [clearTimer]);

  useEffect(() => {
    setPaymentStatus(yapilyStatus?.status);
  }, [yapilyStatus?.status]);

  const paymentSum =
    (convertData?.fees?.[0]?.converted_amount || 0) +
    (convertData?.fees?.[1]?.converted_amount || 0) +
    parseFloat(onrampData?.data?.source_amount || '0');

  const handleDesktopLogin = () => {
    window.open(selectedBank?.authorisationUrl, '_blank');
    setDesktopLogin(true);
  };

  if (desktopLogin) {
    if (paymentStatus === 'PENDING') {
      return (
        <PendingStatusResponse
          beneficiary={bankDetails?.beneficiary}
          paymentSum={paymentSum}
          reference={bankDetails?.reference}
          setToastState={setToastState}
          setUrlStep={setUrlStep}
          sourceCurrency={onrampData?.data.source_currency}
        />
      );
    }

    if (paymentStatus === 'REJECTED') {
      return (
        <RejectedStatusResponse
          beneficiary={bankDetails?.beneficiary}
          paymentSum={paymentSum}
          setUrlStep={setUrlStep}
          sourceCurrency={onrampData?.data.source_currency}
        />
      );
    }

    return (
      <div className="flex flex-col justify-between min-h-[600px]">
        <h1 className="text-xl text-center px-2 md:px-12 border-b pb-5 border-borderLight dark:border-borderDark">
          We are waiting to hear back from{' '}
          <span className="text-primary">{selectedInstitution.full_name}</span>.
          Please wait a second.
        </h1>

        <div className="self-center">
          <LoadingIcon className="min-h-16 min-w-16" />
        </div>

        <div className="flex justify-center">
          <Button
            name="Relaunch your bank login"
            variant="primary"
            onClick={handleDesktopLogin}
            className="w-full max-w-[380px] self-center"
          />
        </div>
      </div>
    );
  }

  return (
    <div>
      <h1 className="text-xl text-center px-2 md:px-12 border-b pb-5 border-borderLight dark:border-borderDark ">
        Authenticate with the{' '}
        <span className="text-primary">{selectedInstitution.full_name}</span>{' '}
        mobile app
      </h1>

      <div className=" flex justify-center items-center">
        <img
          src={selectedBank?.qrCodeUrl}
          alt=""
          className="w-[200px] h-[200px] mt-12 "
        />
      </div>

      <div className="flex justify-center">
        <Button
          name="Need Help?"
          variant="secondary"
          onClick={() => setNeedHelp(true)}
          className="mt-5 mb-5 self-center border-0 text-primary dark:text-primary group underline"
        />
      </div>

      <div className="flex flex-col justify-center">
        <Button
          name="Use desktop login"
          variant="primary"
          onClick={handleDesktopLogin}
          className="w-full max-w-[380px] self-center"
        />

        <Button
          name="Go back"
          variant="secondary"
          onClick={() => setSelectedBank(undefined)}
          className="mt-5 self-center border-0 text-black dark:text-white dark:hover:text-primary group"
          iconBefore={
            <ArrowLeft className="stroke-black dark:stroke-white group-hover:stroke-primary" />
          }
        />
      </div>
    </div>
  );
};

export default BankAuthenticate;

import { Toast } from 'components';
import type { FC } from 'react';
import { useState } from 'react';
import { useSimulateOnrampSandboxPayment } from 'services';
import type {
  OnrampSessionType,
  SimulateOnrampSandboxPaymentData,
  SimulateOnrampSandboxPaymentResponse,
  ToastStateProps,
} from 'services/types';
import PaymentSandboxStatusResponse from './components/PaymentSandboxStatusResponse';
import PaymentSandboxSummary from './components/PaymentSandboxSummary';

type OnrampSandboxPaymentProcessorProps = {
  onrampSession: OnrampSessionType;
};

const OnrampSandboxPaymentProcessor: FC<OnrampSandboxPaymentProcessorProps> = ({
  onrampSession,
}) => {
  const [toastState, setToastState] = useState<ToastStateProps>({
    active: false,
    toastMessage: '',
  });

  const [simulationType, setSimulationType] =
    useState<SimulateOnrampSandboxPaymentData['status']>();
  const [simulatePaymentResponse, setSimulatePaymentResponse] =
    useState<SimulateOnrampSandboxPaymentResponse>();

  const { simulateOnrampPayment, isSimulatingPayment } =
    useSimulateOnrampSandboxPayment();

  const handleSimulateSandboxOnrampPayment = (
    status: SimulateOnrampSandboxPaymentData['status'],
  ) => {
    if (onrampSession?.daftOrderId) {
      setSimulationType(status);
      simulateOnrampPayment(
        {
          status: status,
          order_id: onrampSession.daftOrderId,
        },
        {
          onSuccess: (response) => {
            setSimulatePaymentResponse(response);
          },
          onError: (error) => {
            setToastState({
              active: true,
              variant: 'error',
              toastMessage: error.response?.data.message,
            });
          },
        },
      );
    }
  };

  return (
    <div>
      {simulatePaymentResponse ? (
        <PaymentSandboxStatusResponse
          simulatePaymentResponse={simulatePaymentResponse}
          setSimulatePaymentResponse={setSimulatePaymentResponse}
        />
      ) : (
        <PaymentSandboxSummary
          handleSimulateSandboxOnrampPayment={
            handleSimulateSandboxOnrampPayment
          }
          isSimulatingPayment={isSimulatingPayment}
          simulationType={simulationType}
        />
      )}

      {toastState.active && (
        <Toast
          text={toastState?.toastMessage || ''}
          onClose={() => setToastState({ active: false, toastMessage: '' })}
          variant="default"
        />
      )}
    </div>
  );
};

export default OnrampSandboxPaymentProcessor;

import * as React from 'react';
import type { SVGProps } from 'react';
const DownArrowSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <g clipPath="url(#clip0_181_1161)">
      <g clipPath="url(#clip1_181_1161)">
        <path
          d="M7 10L12 15"
          stroke="#292929"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 15L17 10"
          stroke="#1DA069"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_181_1161">
        <rect width={24} height={24} fill="white" />
      </clipPath>
      <clipPath id="clip1_181_1161">
        <rect width={24} height={24} fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const DownArrow = React.memo(DownArrowSvg);

export default DownArrow;

import {
  AccountNumberIcon,
  BICIcon,
  BankAddressIcon,
  BankIcon,
  BeneficiaryIcon,
  CopyIcon,
} from 'assets';
import { useOnrampInitialData } from 'providers/OnrampDataProvider';
import { useMemo, useState, type FC } from 'react';
import {
  useConvertOnrampAsset,
  useFinishManualTransferPayment,
  useGetCountry,
  // useGetCountry,
  useGetOnrampBankDetails,
} from 'services';
import type { PaymentMethod, ToastStateProps } from 'services/types';
import { getFormattedCurrency } from 'utils';
import OnrampBankInfoItem from '../components/BankInfoItem';
import { Button, Toast } from 'components';

type PayByBankTransferProps = {
  selectedPayment: PaymentMethod | undefined;
};

const PayByBankTransfer: FC<PayByBankTransferProps> = ({ selectedPayment }) => {
  const [toastState, setToastState] = useState<ToastStateProps>({
    active: false,
    toastMessage: '',
  });

  const { onrampData, isLoading, onrampProgress } = useOnrampInitialData();

  const { data: bankDetails, isLoading: isLoadingBankDetails } =
    useGetOnrampBankDetails(selectedPayment?.default_bank?.toString() ?? '');
  const { data: bankCountry } = useGetCountry({
    countryCode: bankDetails?.address?.country,
  });
  const { finishPayment, isFinishingPayment } =
    useFinishManualTransferPayment();

  const { data: convertData, isLoading: isLoadingConvert } =
    useConvertOnrampAsset({
      amount: onrampData?.data?.source_amount || '',
      from: onrampData?.data?.source_currency || '',
      to: onrampData?.data?.destination_currency || '',
    });

  const paymentSum =
    (convertData?.fees?.[0]?.converted_amount || 0) +
    (convertData?.fees?.[1]?.converted_amount || 0) +
    parseFloat(onrampData?.data?.source_amount || '0');

  const handleFinishPayment = () => {
    if (selectedPayment?.id && onrampProgress?.data?.order_id)
      finishPayment({
        payment_method_id: selectedPayment?.id?.toString(),
        order_id: onrampProgress?.data?.order_id,
      });
  };

  const paymentDetails = useMemo(
    () => [
      {
        icon: <BeneficiaryIcon />,
        // eslint-disable-next-line quotes
        label: "Beneficiary's Name",
        value: bankDetails?.iban,
      },
      {
        icon: <AccountNumberIcon className="stroke-[#D0D5DD]" />,
        label: 'IBAN/Account Number',
        value: bankDetails?.iban,
      },
      {
        icon: <BICIcon />,
        label: 'BIC/SWIFT',
        value: bankDetails?.bic,
      },
      {
        icon: <BankIcon />,
        label: 'Bank Name',
        value: bankDetails?.name,
      },
      {
        icon: <BankAddressIcon />,
        label: 'Bank Address',
        value: `${bankDetails?.address?.street}, ${bankDetails?.address?.city}, ${bankDetails?.address?.zip_code}, ${bankCountry?.name ? bankCountry?.name : bankDetails?.address?.country}`,
      },
      {
        icon: <BeneficiaryIcon />,
        label: 'Reference (mandatory)',
        value: bankDetails?.reference,
      },
    ],
    [bankDetails, bankCountry?.name],
  );

  const handleCopyAll = () => {
    let formattedText = '';

    for (const details of paymentDetails) {
      formattedText += `${details.label}: ${details.value}\n`;
    }

    navigator.clipboard.writeText(formattedText);
  };

  return (
    <div className="flex flex-col justify-between  h-[650px]">
      <div>
        <div>
          <p className="text-2xl mb-2">Send Payment</p>

          <div>
            Please send exactly{' '}
            {isLoadingBankDetails || isLoadingConvert || isLoading ? (
              <span className="animate-pulse"> ...</span>
            ) : (
              getFormattedCurrency(
                paymentSum,
                onrampData?.data?.source_currency,
              )
            )}{' '}
            to this bank account. Once we receive the payment, the balance will
            be added to your wallet securely.{' '}
          </div>
        </div>

        <div className="mt-5">
          <div className="border-b flex justify-between gap-2 pb-1">
            <div>
              <span className="text-primary">
                {bankDetails?.type.toUpperCase() || ''}
              </span>{' '}
              BANK DETAILS
            </div>

            <div className="flex gap-2 items-center">
              <Button
                name="Copy
          all details"
                variant="secondary"
                className="border-none"
                onClick={() => {
                  handleCopyAll();
                  if (setToastState) {
                    setToastState({
                      active: true,
                      toastMessage: 'Bank details copied successfully',
                      variant: 'success',
                    });
                  }
                }}
                iconBefore={
                  <CopyIcon className="dark:stroke-white " stroke="#292929" />
                }
              />
            </div>
          </div>

          <div>
            {paymentDetails.map((payment) => (
              <OnrampBankInfoItem
                icon={payment.icon}
                label={payment.label}
                value={payment.value}
                isLoading={isLoadingBankDetails}
                setToastState={setToastState}
                key={payment.label}
              />
            ))}
          </div>
        </div>
      </div>

      <Button
        name="Done"
        variant="primary"
        className="w-full mt-2"
        onClick={handleFinishPayment}
        isLoading={isFinishingPayment}
        disabled={isLoadingBankDetails}
      />

      {toastState.active && (
        <Toast
          text={toastState?.toastMessage || 'Item copied successfully'}
          onClose={() => setToastState({ active: false, toastMessage: '' })}
          variant="default"
        />
      )}
    </div>
  );
};

export default PayByBankTransfer;

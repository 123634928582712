import { ChevronDownIcon, ChevronUpIcon, LoadingIcon } from 'assets';
import { useCountdownTimer, useEncryptedLocalStorageState } from 'hooks';
import type { FC } from 'react';
import React, { useEffect, useState } from 'react';
import {
  ENCRYPTED_ONRAMP_SESSION_TOKEN,
  getAssetImageUri,
  getFormattedCurrency,
  getUrlParams,
} from 'utils';
import { Button } from '../Button';
import { Collapse } from './components';
import OnRampCardLayout from './layout/OnRampCardLayout';
import { useOnrampInitialData } from 'providers/OnrampDataProvider';
import { useConvertOnrampAsset } from '../../services';
import { Skeleton } from '../Skeleton';

type OnrampStepOneProps = {
  setUrlStep: (
    s: React.SetStateAction<
      Partial<{
        step?: any;
      }>
    >,
  ) => void;
};

const OnrampStepOne: FC<OnrampStepOneProps> = ({ setUrlStep }) => {
  const [activeCollapse, setActiveCollapse] = useState(false);
  const [onrampSessionToken] = useEncryptedLocalStorageState<string>(
    ENCRYPTED_ONRAMP_SESSION_TOKEN,
  );
  const { onrampProgress } = useOnrampInitialData();

  const liveMode = getUrlParams('livemode');

  const { onrampData, isLoading, onrampError } = useOnrampInitialData();

  const {
    data: convertData,
    isLoading: isLoadingConvert,
    refetch,
    isRefetching,
  } = useConvertOnrampAsset({
    amount: onrampData?.data?.source_amount || '',
    from: onrampData?.data?.source_currency || '',
    to: onrampData?.data?.destination_currency || '',
  });

  const { countdown, clearTimer } = useCountdownTimer(30, () => refetch());

  useEffect(() => {
    clearTimer();
  }, [clearTimer]);

  // Redirect to pay step if payment method on onramp progress
  useEffect(() => {
    if (onrampProgress?.data.payment_method) {
      setUrlStep({
        step: 'pay',
      });
    }
  }, [onrampProgress?.data.payment_method, setUrlStep]);

  const handleContinueButton = () => {
    if (onrampSessionToken) {
      setUrlStep({ step: 'user-details' });
    } else {
      setUrlStep({ step: 'verify' });
    }
  };

  if (isLoading) {
    return (
      <OnRampCardLayout>
        <div className="min-h-[712px] w-full flex justify-center items-center">
          <LoadingIcon className="min-h-16 min-w-16" />
        </div>
      </OnRampCardLayout>
    );
  }

  if (!onrampData?.success) {
    return (
      <OnRampCardLayout>
        <div className="min-h-[712px] w-full flex items-center justify-center text-2xl">
          {onrampError}
        </div>
      </OnRampCardLayout>
    );
  }

  return (
    <OnRampCardLayout
      className="min-h-[712px] "
      childrenClassName="flex flex-col justify-between h-[689px]">
      <div className="">
        <div className="text-center border-b pb-5">
          <h1 className="text-xl md:text-2xl">
            {liveMode === 'false' ? 'Sandbox - ' : ''} Buy{' '}
            {onrampData?.data?.destination_currency}
          </h1>
          <p>Pay with credit, debit, or bank account</p>
        </div>

        <div className="flex flex-col-reverse md:flex-row justify-between mt-10 border dark:border-none dark:bg-bg-secondaryDark p-4 rounded-xl items-start md:items-center gap-5">
          <div className="flex flex-col gap-2">
            <p>Pay</p>
            <p className="text-2xl md:text-3xl">
              {getFormattedCurrency(
                parseFloat(onrampData?.data?.source_amount),
                onrampData?.data?.source_currency,
              )}
            </p>
          </div>
        </div>

        <div className="mt-1 flex flex-row-reverse ">
          <div className="flex justify-center items-center text-xs rounded-full text-primary mr-2">
            {isLoadingConvert || isRefetching ? 'Updating...' : `${countdown}s`}
          </div>
        </div>

        <div className="border dark:border-none dark:bg-bg-secondaryDark p-4 rounded-xl items-center gap-5 mt-1">
          <div className="flex justify-between items-center gap-2">
            <div>
              <p>Receive</p>

              {isLoadingConvert ? (
                <Skeleton className="w-full h-7 mt-2" />
              ) : (
                <p className="text-2xl md:text-3xl">
                  {parseFloat(convertData?.converted_amount || '0')?.toFixed(8)}
                  <span className="text-placeholder">
                    {' '}
                    {onrampData?.data?.destination_currency}
                  </span>
                </p>
              )}
            </div>
            <div className="flex items-center gap-2 bg-[#F6F6F6] dark:bg-bg-secondaryDark py-2 px-3 rounded-lg border border-primary overflow-hidden">
              <img
                src={getAssetImageUri({
                  asset_id: onrampData?.data?.destination_currency,
                })}
                alt=""
                className="h-8 w-8"
              />
              <div>{onrampData?.data?.destination_currency}</div>
            </div>
          </div>
        </div>

        <div className="bg-[#F6F6F6] dark:bg-bg-secondaryDark mt-5 p-4 rounded-xl">
          <div
            className="mt-3 flex justify-between cursor-pointer"
            onClick={() => setActiveCollapse(!activeCollapse)}>
            <div className="flex gap-2 items-center">
              <p>Fees</p>
              {activeCollapse ? <ChevronUpIcon /> : <ChevronDownIcon />}
            </div>
            <div>
              {isLoadingConvert ? (
                <Skeleton className="w-[150px] h-4" />
              ) : (
                getFormattedCurrency(
                  (convertData?.fees?.[0]?.converted_amount || 0) +
                    (convertData?.fees?.[1]?.converted_amount || 0),
                  onrampData?.data?.source_currency,
                )
              )}
            </div>
          </div>

          <Collapse activeCollapse={activeCollapse}>
            <div>
              <div className="flex justify-between">
                <div>Network fee</div>

                <div>
                  {isLoadingConvert ? (
                    <Skeleton className="w-[150px] h-4 mt-2" />
                  ) : (
                    getFormattedCurrency(
                      convertData?.fees?.[0]?.converted_amount || 0,
                      onrampData?.data?.source_currency,
                    )
                  )}
                </div>
              </div>
              <div className="flex justify-between">
                <div>Transaction fee</div>

                <div>
                  {' '}
                  {isLoadingConvert ? (
                    <Skeleton className="w-[150px] h-4" />
                  ) : (
                    getFormattedCurrency(
                      convertData?.fees?.[1]?.converted_amount || 0,
                      onrampData?.data?.source_currency,
                    )
                  )}
                </div>
              </div>
            </div>
          </Collapse>
        </div>

        <div className="flex justify-between mt-5">
          <p>Total</p>

          {isLoadingConvert ? (
            <div>
              <Skeleton className="w-[150px] h-4" />
            </div>
          ) : (
            <p>
              {getFormattedCurrency(
                (convertData?.fees?.[0]?.converted_amount || 0) +
                  (convertData?.fees?.[1]?.converted_amount || 0) +
                  (parseFloat(onrampData?.data?.source_amount) || 0),
                onrampData?.data?.source_currency,
              )}
            </p>
          )}
        </div>
      </div>

      <div>
        <Button
          name="Continue"
          variant="primary"
          className="w-full mt-16"
          onClick={() => handleContinueButton()}
        />
      </div>
    </OnRampCardLayout>
  );
};

export default OnrampStepOne;

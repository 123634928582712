import { CheckMark, DraftIcon, PlusIcon, ReverseLeft, XTagIcon } from 'assets';
import {
  Button,
  PageHeader,
  Table,
  TablePagination,
  Tag,
  Toast,
} from 'components';
import { CreateInvoiceForm } from 'forms';
import { useDateFormatter } from 'hooks';
import { useState, type FC } from 'react';
import { useGetPaginatedInvoices } from 'services';
import type {
  FormInvoiceData,
  InvoiceData,
  ToastStateProps,
} from 'services/types';

const tableHead = [
  // {
  //   id: 1,
  //   label: 'Id',
  // },
  {
    id: 2,
    label: 'Terminal',
  },
  {
    id: 3,
    label: 'Payer',
  },
  {
    id: 4,
    label: 'Amount',
  },
  // {
  //   id: 5,
  //   label: 'Description',
  // },
  {
    id: 6,
    label: 'Status',
  },
  // {
  //   id: 7,
  //   label: 'Link',
  // },
  {
    id: 8,
    label: 'Created',
  },
  // {
  //   id: 9,
  //   label: 'Payment History',
  // },
];

const renderStatusTag: { [key: string]: JSX.Element } = {
  completed: <Tag variant="success" text="Paid" iconBefore={<CheckMark />} />,
  refunded: (
    <Tag variant="default" text="Refunded" iconBefore={<ReverseLeft />} />
  ),
  draft: <Tag variant="default" text="Draft" iconBefore={<DraftIcon />} />,
  pending: (
    <Tag variant="warning" text="Pending" iconBefore={<ReverseLeft />} />
  ),
  cancelled: <Tag variant="error" text="Cancelled" iconBefore={<XTagIcon />} />,
};

const pageSize = 10;

const Invoices: FC = () => {
  const [showModal, setShowModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [initialValues, setInitialValues] = useState<FormInvoiceData>();
  const [toastState, setToastState] = useState<ToastStateProps>({
    active: false,
    toastMessage: '',
  });

  const { data: invoiceData, isLoading } = useGetPaginatedInvoices({
    page: currentPage,
    pageSize: pageSize,
  });

  const { dateFormatter } = useDateFormatter();

  const handleRowClick = (item: InvoiceData) => {
    setInitialValues({
      ...item,
      customer: {
        id: '1',
        label: item.customer,
        value: '1',
      },
    });
    setShowModal(true);
  };

  return (
    <div className="w-full">
      <PageHeader
        title="Invoices"
        // description=""
        rightContent={
          <Button
            name="Create invoice"
            size="large"
            iconBefore={<PlusIcon />}
            variant="primary"
            className="rounded-xl"
            onClick={() => {
              setInitialValues(undefined);
              setShowModal(true);
            }}
          />
        }
      />

      <Table
        tableHead={tableHead}
        isLoading={isLoading}
        noDataText="No invoices available"
        tableBody={invoiceData?.data?.map((item, index) => (
          <tr
            key={item.id}
            onClick={() => handleRowClick(item)}
            className={`${index % 2 === 0 ? 'bg-[#F9FAFB]  dark:bg-slate-800' : 'bg-gray-25 dark:bg-[#233045]'} border-t dark:border-bg-dark h-[72px] cursor-pointer hover:bg-white hover:dark:bg-bg-dark`}>
            {/* <td className="px-6 py-4 ">
              {(currentPage - 1) * pageSize + 1 + index}
            </td> */}
            <td className="px-6 py-4 whitespace-nowrap">
              <div>{item.terminal}</div>
            </td>
            <td className="px-6 py-4 whitespace-nowrap">{item.customer}</td>
            <td className="px-6 py-4 whitespace-nowrap">{item.amount}</td>
            {/* <td className="px-6 py-4 whitespace-nowrap">{item.description}</td> */}
            <td className="px-6 py-4 whitespace-nowrap">
              {renderStatusTag[item?.status]}
            </td>
            {/* <td className="px-6 py-4 whitespace-nowrap">
              <div>{item.link}</div>
            </td> */}
            <td className="px-6 py-4 whitespace-nowrap">
              {dateFormatter({ date: item.created_at })}
            </td>
            {/* <td className="px-6 py-4 whitespace-nowrap">
              {item.paymentHistory}
            </td> */}
          </tr>
        ))}
      />

      {!isLoading && (
        <TablePagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={invoiceData?.meta?.last_page || 1}
        />
      )}

      {showModal && (
        <CreateInvoiceForm
          setShowModal={setShowModal}
          showModal={showModal}
          initialValues={initialValues}
          setToastState={setToastState}
        />
      )}

      {toastState.active && (
        <Toast
          text={toastState?.toastMessage || 'Item copied successfully'}
          onClose={() => setToastState({ active: false, toastMessage: '' })}
          variant="default"
        />
      )}
    </div>
  );
};

export default Invoices;

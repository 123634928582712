import { OnrampLayout, SingedInLayout } from 'components';
import {
  Authenticate,
  ConfirmOrder,
  Customers,
  Developers,
  InvoiceProcessing,
  Invoices,
  Onramp,
  OnrampResponse,
  Orders,
  PageNotFound,
  PaymentStatus,
  RejectOrder,
  Settings,
} from 'pages';
import { createBrowserRouter } from 'react-router-dom';
import InvoiceProcessingLayout from '../components/layout/InvoiceProcessingLayout';
import { OnrampDataProvider } from '../providers';

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <SingedInLayout>
        <Customers />
      </SingedInLayout>
    ),
  },
  {
    path: '/developers',
    element: (
      <SingedInLayout>
        <Developers />
      </SingedInLayout>
    ),
  },
  {
    path: '/invoices',
    element: (
      <SingedInLayout>
        <Invoices />
      </SingedInLayout>
    ),
  },
  {
    path: '/orders',
    element: (
      <SingedInLayout>
        <Orders />
      </SingedInLayout>
    ),
  },
  {
    path: '/settings',
    element: (
      <SingedInLayout>
        <Settings />
      </SingedInLayout>
    ),
  },
  {
    path: '/onramp',
    element: (
      <OnrampDataProvider>
        <OnrampLayout>
          <Onramp />
        </OnrampLayout>
      </OnrampDataProvider>
    ),
  },
  {
    path: '/invoice-processing',
    element: (
      <InvoiceProcessingLayout>
        <InvoiceProcessing />
      </InvoiceProcessingLayout>
    ),
  },
  {
    path: '/sso/niza/callback',
    element: <Authenticate />,
  },
  {
    path: '/payment/card/success/:transaction_id',
    element: <PaymentStatus status="success" />,
  },
  {
    path: '/payment/card/fail/:transaction_id',
    element: <PaymentStatus status="fail" />,
  },
  {
    path: '/payment/card/cancel/:transaction_id',
    element: <PaymentStatus status="cancel" />,
  },
  {
    path: '/payment/card/pending/:transaction_id',
    element: <PaymentStatus status="pending" />,
  },
  {
    path: '/onramp/bank/callback/:orderId/:id',
    element: (
      <OnrampLayout>
        <OnrampResponse />
      </OnrampLayout>
    ),
  },
  {
    path: 'confirm-order',
    element: <ConfirmOrder />,
  },
  {
    path: 'reject-order',
    element: <RejectOrder />,
  },
  {
    path: '*',
    element: <PageNotFound />,
  },
]);

export default router;

import type { FC } from 'react';
import React from 'react';
import { useGetCryptoPurchaseDetails } from 'services';
import { XIcon } from 'assets';
import { cn } from 'utils';
import { Tag, Timeline } from 'components';
import OrderDetailsItem from './OrderDetailsItem';

type OrderDetailsDrawerProps = {
  orderId: string | undefined;
  setOrderId: React.Dispatch<React.SetStateAction<string | undefined>>;
};

const OrderDetailsDrawer: FC<OrderDetailsDrawerProps> = ({
  orderId,
  setOrderId,
}) => {
  const { data: orderData, isLoading } = useGetCryptoPurchaseDetails({
    orderId: orderId,
    enabled: !!orderId,
  });

  const timelineItems = orderData?.data?.events?.map((event) => ({
    date: event.event_date,
    event: event.event,
    status: event.status,
  }));

  return (
    <div
      className={cn(
        'h-full absolute top-0 w-full left-0 rounded-3xl overflow-hidden ease-linear transition-transform duration-1000 z-10 bg-white dark:bg-bg-dark',
        {
          ' opacity-0 w-0 -translate-x-full h-0 hidden': !orderId,
          'opacity-100 transform translate-x-0': !!orderId,
        },
      )}
      onClick={(e) => {
        e.stopPropagation();
      }}>
      <div className="mt-16 py-5">
        <div className="text-center text-2xl mb-2">Order details</div>

        <OrderDetailsItem
          label="Business"
          isLoading={isLoading}
          title={orderData?.data.business}
        />
        <OrderDetailsItem
          label="Crypto amount"
          isLoading={isLoading}
          title={`${parseFloat(orderData?.data.crypto_amount ?? '0').toFixed(8)} ${orderData?.data.crypto_currency}`}
        />
        <OrderDetailsItem
          label="Fiat amount"
          isLoading={isLoading}
          title={`${orderData?.data.fiat_amount} ${orderData?.data.fiat_currency}`}
        />
        <OrderDetailsItem
          label="Exchange rate"
          isLoading={isLoading}
          title={orderData?.data.exchange_rate}
        />
        <OrderDetailsItem
          label="Fee"
          isLoading={isLoading}
          title={`${orderData?.data?.fee_amount ? parseFloat(orderData?.data?.fee_amount).toFixed(2) : ''} ${orderData?.data.fee_currency}`}
        />
        <OrderDetailsItem
          label="Status"
          isLoading={isLoading}
          title={<Tag variant="default" text={orderData?.data.status} />}
        />
      </div>

      <div className="absolute px-5 py-2 top-5 left-2 flex justify-between w-full items-center">
        <div onClick={() => setOrderId(undefined)}>
          <XIcon className="stroke-black dark:stroke-white  cursor-pointer" />
        </div>
      </div>

      <div className="text-xl mb-2 pl-5">Order history</div>

      <div className="px-10 h-60 overflow-y-auto pb-5">
        <Timeline timelines={timelineItems} isLoading={isLoading} />
      </div>
    </div>
  );
};

export default OrderDetailsDrawer;

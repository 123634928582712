import type { FC } from 'react';
import React, { useState } from 'react';
import OtpInput from 'react-otp-input';
import { useAuthorizeOnrampCustomer, useResendOtpCode } from 'services';
import type { OnrampSessionType, ToastStateProps } from 'services/types';
import { Button } from '../../Button';
import OnRampCardLayout from '../layout/OnRampCardLayout';
import { ENCRYPTED_ONRAMP_SESSION_TOKEN, cn } from 'utils';
import { useDisableButton, useEncryptedLocalStorageState } from 'hooks';

type OnrampConfirmEmailStepProps = {
  setUrlStep: (
    s: React.SetStateAction<
      Partial<{
        step?: any;
      }>
    >,
  ) => void;
  setVerifyEmail: React.Dispatch<React.SetStateAction<number>>;
  setOnrampSession: React.Dispatch<React.SetStateAction<OnrampSessionType>>;
  onrampSession: OnrampSessionType;
  setToastState?: React.Dispatch<React.SetStateAction<ToastStateProps>>;
};

const OnrampConfirmEmailStep: FC<OnrampConfirmEmailStepProps> = ({
  setUrlStep,
  setVerifyEmail,
  onrampSession,
  setOnrampSession,
  setToastState,
}) => {
  const [otp, setOtp] = useState('');
  const { authorizeOnrampCustomer, isAuthorizingOnrampCustomer } =
    useAuthorizeOnrampCustomer();
  const { resendOtpCode, isResendingOtpCode } = useResendOtpCode();

  const [_, setEncryptSessionToken] = useEncryptedLocalStorageState(
    ENCRYPTED_ONRAMP_SESSION_TOKEN,
  );

  const { isDisabled, remainingSeconds, setIsDisabled } = useDisableButton();

  const onVerifyEmailClick = () => {
    if (otp.length === 6 && onrampSession?.email) {
      authorizeOnrampCustomer(
        {
          otp_code: otp,
          email: onrampSession?.email,
        },
        {
          onSuccess: (response) => {
            if (response.success) {
              setOnrampSession({
                ...onrampSession,
                email: onrampSession?.email,
                isVerified: response.isVerified,
                kycStatus: response.kycStatus,
              });
              setEncryptSessionToken(response.sessionToken);

              if (setToastState) {
                setToastState({
                  active: true,
                  toastMessage: response.message ?? 'Updated',
                  variant: 'default',
                });
              }

              setTimeout(() => {
                setUrlStep({ step: 'user-details' });
                window.location.reload();
              }, 1000);
            }
          },
          onError: (error) => {
            if (setToastState) {
              setToastState({
                active: true,
                toastMessage: error.response?.data.message ?? 'Error',
                variant: 'error',
              });
            }
          },
        },
      );
    }
  };

  const handleResendOtpCode = () => {
    if (onrampSession?.email) {
      resendOtpCode(
        {
          email: onrampSession?.email,
        },
        {
          onSuccess: (response) => {
            if (setToastState) {
              setToastState({
                active: true,
                variant: 'success',
                toastMessage: response.message,
              });
            }

            setIsDisabled(true);
          },
          onError: (error) => {
            if (setToastState) {
              setToastState({
                active: true,
                variant: 'error',
                toastMessage: error.response?.data.message,
              });
            }
          },
        },
      );
    }
  };

  return (
    <OnRampCardLayout setStep={() => setVerifyEmail((prev) => prev - 1)}>
      <div>
        <div className="text-center border-b pb-3">
          <h1 className="text-xl md:text-2xl mx-5">Enter confirmation code!</h1>
          <p>Please insert the code you received from your email </p>
        </div>

        <div className="flex flex-col items-center gap-2 justify-center min-h-[300px] ">
          <p className="text-sm">Confirmation Code </p>
          <div>
            <OtpInput
              value={otp}
              onChange={setOtp}
              numInputs={6}
              renderInput={(props) => <input {...props} />}
              containerStyle={{
                width: '100%',
                gap: '10px',
                color: 'black',
              }}
              inputStyle={{
                width: '40px',
                height: '40px',
                borderRadius: '5px',
                border: 'solid #101828 1px ',
              }}
            />
          </div>

          <button
            disabled={isDisabled}
            className={cn('underline text-primary', {
              'opacity-80 cursor-not-allowed': isDisabled,
            })}
            onClick={handleResendOtpCode}>
            Resend confirmation email{' '}
            {isResendingOtpCode ? (
              <span className="animate-pulse">...</span>
            ) : (
              ''
            )}
            {isDisabled ? `in ${remainingSeconds}s` : ''}
          </button>
        </div>

        <div className="mt-16 text-center">
          <Button
            name="Verify email"
            variant="primary"
            className="w-full mt-2"
            onClick={() => onVerifyEmailClick()}
            disabled={otp.length < 5}
            isLoading={isAuthorizingOnrampCustomer}
          />
        </div>
      </div>
    </OnRampCardLayout>
  );
};

export default OnrampConfirmEmailStep;

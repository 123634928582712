import * as React from 'react';
import type { SVGProps } from 'react';
const FilesAttachmentSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <g clipPath="url(#clip0_203_19468)">
      <path
        d="M18 9V17C18 19.2091 16.2091 21 14 21C11.7909 21 10 19.2091 10 17V9C10 7.89543 10.8954 7 12 7C13.1046 7 14 7.89543 14 9V17"
        stroke="fill"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 15L6 9C6 5.68629 8.68629 3 12 3C15.3137 3 18 5.68629 18 9"
        stroke="#1DA069"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_203_19468">
        <rect width={24} height={24} fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const FilesAttachment = React.memo(FilesAttachmentSvg);

export default FilesAttachment;

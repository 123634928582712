import * as React from 'react';
import type { SVGProps } from 'react';

const CopyIconSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={29}
    height={28}
    viewBox="0 0 29 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M18.5 5H6.5V18"
      stroke="#1DA069"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.5 9H22.5V21C22.5 22.1046 21.6046 23 20.5 23H12.5C11.3954 23 10.5 22.1046 10.5 21V9Z"
      stroke={'fill'}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const CopyIcon = React.memo(CopyIconSvg);

export default CopyIcon;
